'use strict';

ansibleLogs.$inject = [
  '$http',
  'API'
];

function ansibleLogs ( $http, API ) {
  var _getLog = function(target) {
    var url = API + '/api/ansible/logs?target=' + target;
    return $http({
      method: 'get',
      url: url
    });
  };

  return {
    getLog: function(target) {
      return _getLog(target).then(function(res) {
        return res.data;
      }, function(res){
        return res.data;
      });
    }
  };
};

module.exports = ansibleLogs;
