'use strict';

assignLicenseModalController.$inject = [
  '$uibModalInstance',
  '$scope',
  '$uibModal',
  'Orders',
  'Licenses'
];

function assignLicenseModalController($uibModalInstance, $scope, $uibModal, Orders, Licenses) {

  ////////////////////////////////////////////////////////////
  // Methods
  ////////////////////////////////////////////////////////////
  var _listOrder = function(page, search) {
    var modal = $uibModal.open({
      templateUrl: "views/modal/progress.html",
      backdrop: "static",
      keyboard: false
    });

    var searchWord = {};
    if (search) {
      searchWord.searchWord = search;
    }
    Orders.listOrder(page, searchWord).then(
      function(data){
        if (data.totalElements > 0) {
          $scope.hideTable = false;
          $scope.totalItems = data.totalElements;
          $scope.orders = data.content;
          $scope.currentPage = data.number + 1
          $scope.itemsPerPage = data.size;
          $scope.maxSize = 7;
        } else {
          $scope.hideTable = true;
        }
        modal.close();
      },
      function(data){
        $uibModalInstance.dismiss(data.message);
        modal.close();
      }
    );
  };

  ////////////////////////////////////////////////////////////
  // Functions
  ////////////////////////////////////////////////////////////
  $scope.filteredList = function() {
    $scope.selectedRow = null;
    $scope.customerName = null;
    $scope.customerId = null;
    $scope.hideTable = false;
    _listOrder(1, $scope.search);
  };

  $scope.pageChanged = function() {
    _listOrder($scope.currentPage, $scope.search);
  };

  $scope.selectRow = function(id, name) {
    if ($scope.selectedRow && $scope.selectedRow == id) {
      $scope.orderId = null;
      $scope.orderCode = null;
      $scope.selectedRow = null;
    } else {
      $scope.orderId = id;
      $scope.orderCode = name;
      $scope.selectedRow = id;
    }
  };

  $scope.choose = function() {
    var modal = $uibModal.open({
      templateUrl: "views/modal/confirm.html",
      backdrop: "static",
      keyboard: false,
      scope: $scope,
      controller: function($uibModalInstance, $scope) {
        $scope.title = "外部ライセンスコードの割当";
        $scope.messageBody = "選択された注文情報に外部ライセンスコードを割り当てます。よろしいですか？";
        $scope.okay = function() {
          $uibModalInstance.close();
        };
        $scope.cancel = function() {
          $uibModalInstance.dismiss('cancel');
        };
      }
    });
    modal.result.then(function() {
      var modal = $uibModal.open({
        templateUrl: "views/modal/progress.html",
        backdrop: "static",
        keyboard: false
      });

      Licenses.grab($scope.orderId).then(function(data) {
        modal.close();
        if (data.message == undefined) {
          $uibModalInstance.close(data);
        } else {
          $scope.messages.status = 'danger';
          $scope.messages.message = data.message;
        }
      })
    }, function() {});

  };

  $scope.cancel = function() {
    $uibModalInstance.dismiss('cancel');
  };

  ////////////////////////////////////////////////////////////
  // Initialize
  ////////////////////////////////////////////////////////////
  $scope.messages = {
    status: '',
    message: '',
  };
  $scope.search = '';

  _listOrder(1, '');
};

module.exports = assignLicenseModalController;
