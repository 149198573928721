'use strict';

CloudTypes.$inject = [
  '$http',
  'API'
];

function CloudTypes ( $http, API ) {
  var _pagedCloudTypes = function(page) {
    var url = API + '/api/cloudTypes/pages/' + page;
    return $http({
      method: 'get',
      url: url
    });
  };

  var _getOne = function(id) {
    var url = API + '/api/cloudTypes/' + id;
    return $http({
      method: 'get',
      url: url
    });
  };

  var _save = function(id, data) {
    var url = API + '/api/cloudTypes/';
    var verb = 'post';
    if (id) {
      url += id;
      verb = 'put';
    }
    return $http({
      method: verb,
      url: url,
      data: data
    });
  };

  var _delete = function(id) {
    var url = API + '/api/cloudTypes/' + id;
    return $http({
      method: 'delete',
      url: url
    });
  };

  return {
    pagedCloudTypes: function(page) {
      return _pagedCloudTypes(page).then(function(res) {
        return res.data;
      }, function(res){
        return res.data;
      });
    },
    getOne: function(id) {
      return _getOne(id).then(function(res) {
        return res.data;
      }, function(res){
        return res.data;
      })
    },
    save: function(id, data) {
      return _save(id, data).then(function(res) {
        return res.data;
      }, function(res){
        return res.data;
      })
    },
    delete: function(id) {
      return _delete(id).then(function(res) {
        return res.data;
      }, function(res){
        return res.data;
      })
    }
  };
};

module.exports = CloudTypes;
