'use strict';

orderListController.$inject = [
  '$scope',
  '$uibModal',
  '$routeParams',
  '$location',
  '$filter',
  'Orders',
  'Sellers'
];

function orderListController($scope, $uibModal, $routeParams, $location, $filter, Orders, Sellers) {

  //////////////////////////////////////////////////////////////////////
  // Loading seller list
  var _loadSellerList = function() {
    Sellers.listAvailable().then(
      function(data) {
        if (!data || data.message) {
          $scope.messages.status = 'danger';
          $scope.messages.message = data.message;
        } else {
          $scope.sellerList = data;
        }
      }
    );
  };

  //////////////////////////////////////////////////////////////////////
  // Loading order list
  var _loadList = function() {
    var modal = $uibModal.open({
      templateUrl: "views/modal/progress.html",
      backdrop: "static",
      keyboard: false
    });

    Orders.listOrder($routeParams.num, $location.search()).then(
      function(data) {
        $scope.orders = data.content;

        if (data.totalElements == 0) {
          $scope.hideTable = true;
        } else {
          $scope.hideTable = false;
        }

        // Pagination
        $scope.totalItems = data.totalElements;
        $scope.currentPage = data.number + 1
        $scope.maxSize = 7;
        $scope.itemsPerPage = data.size;

        modal.close();

        $scope.pageChanged = function() {
          $location.path('/orders/pages/' + $scope.currentPage);
        }
      }
    );
  };

  //////////////////////////////////////////////////////////////////////
  // Loading order list with filter
  var _filterOrderList = function() {
    var params = "";
    if ($scope.searchFreeWord) {
      params += "searchWord=" + encodeURIComponent($scope.searchFreeWord);
    }
    if ($scope.statusFilter.length > 0) {
      if (params.length > 0) {
        params += '&';
      }
      params += "status=" + encodeURIComponent($scope.statusFilter.join(','));
    }
    if ($scope.sellerFilter.length > 0) {
      if (params.length > 0) {
        params += '&';
      }
      params += "sellerId=" + encodeURIComponent($scope.sellerFilter.join(','));
    }
    if ($scope.limit) {
      if (params.length > 0) {
        params += '&';
      }
      params += "limit=" + $scope.limit.value;
    }
    if ($scope.dateFrom || $scope.dateTo) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'dateField=' + $scope.dateField;
      if ($scope.dateFrom) {
        var yyyy = $scope.dateFrom.getFullYear();
        var mm = ('0' + ($scope.dateFrom.getMonth() + 1)).slice(-2);
        var dd = ('0' + ($scope.dateFrom.getDate() + 1)).slice(-2)
        var dt = yyyy + '-' + mm + '-' + dd;
        params += '&dateFrom=' + dt;
      }
      if ($scope.dateTo) {
        var yyyy = $scope.dateTo.getFullYear();
        var mm = ('0' + ($scope.dateTo.getMonth() + 1)).slice(-2);
        var dd = ('0' + ($scope.dateTo.getDate() + 1)).slice(-2)
        var dt = yyyy + '-' + mm + '-' + dd;
        params += '&dateTo=' + dt;
      }
    }
    if ($scope.paymentFilter.length > 0) {
      if (params.length > 0) {
        params += '&';
      }
      params += "paymentMethod=" + encodeURIComponent($scope.paymentFilter.join(','));
    }

    // Back to 1st page
    $location.path('/orders/pages/1');
    $location.search(params);

    _loadList();
  }

  //////////////////////////////////////////////////////////////////////
  // Clear list filters
  var _resetFilter = function() {
    $scope.searchFreeWord = '';
    $scope.statusFilter = [];
    $scope.sellerFilter = [];
    $scope.dateField = null;
    $scope.dateFrom = null;
    $scope.dateTo = null;
    $location.url('/orders/pages/1');
  }

  //////////////////////////////////////////////////////////////////////
  // Toggle status filter item
  var _toggleStatusFilter = function(val) {
    var idx = $scope.statusFilter.indexOf(val + "");
    if (idx == -1) {
      $scope.statusFilter.push(val + "");
    } else {
      $scope.statusFilter.splice(idx, 1);
    }
  }

  //////////////////////////////////////////////////////////////////////
  // Toggle seller filter item
  var _toggleSellerFilter = function(val) {
    var idx = $scope.sellerFilter.indexOf(val + "");
    if (idx == -1) {
      $scope.sellerFilter.push(val + "");
    } else {
      $scope.sellerFilter.splice(idx, 1);
    }
  }

  //////////////////////////////////////////////////////////////////////
  // Toggle status filter item
  var _togglePaymentFilter = function(val) {
    var idx = $scope.paymentFilter.indexOf(val + "");
    if (idx == -1) {
      $scope.paymentFilter.push(val + "");
    } else {
      $scope.paymentFilter.splice(idx, 1);
    }
  }

  //////////////////////////////////////////////////////////////////////
  // Change limit
  var _changeLimit = function() {
    _filterOrderList();
  };

  //////////////////////////////////////////////////////////////////////
  // Open date picker
  var _openDatePicker = function(fld) {
    fld.opened = true;
  }
  var _openDateFrom = function() {
    _openDatePicker($scope.dateFromPicker);
  }
  var _openDateTo = function() {
    _openDatePicker($scope.dateToPicker);
  }

  var _togglePanel = function() {
    $scope.panel.visible = !$scope.panel.visible
  }

  ////////////////////////////////////////////////////////////
  // Function mapping
  $scope.filterOrderList = _filterOrderList;
  $scope.toggleStatusFilter = _toggleStatusFilter;
  $scope.toggleSellerFilter = _toggleSellerFilter;
  $scope.togglePaymentFilter = _togglePaymentFilter;
  $scope.resetFilter = _resetFilter;
  $scope.changeLimit = _changeLimit;
  $scope.openDateFromPicker = _openDateFrom;
  $scope.openDateToPicker = _openDateTo;
  $scope.togglePanel = _togglePanel;

  ////////////////////////////////////////////////////////////
  // Initialize
  _loadSellerList();

  $scope.messages = {
    status: '',
    message: '',
  };

  $scope.limits = [{
      label: '10件',
      value: 10
    },
    {
      label: '20件',
      value: 20
    },
    {
      label: '50件',
      value: 50
    },
    {
      label: '100件',
      value: 100
    }
  ];

  $scope.dateFields = [{
      "label": "注文受付日",
      "value": "orderDate"
    },
    {
      "label": "利用開始日",
      "value": "orderActivationDate"
    },
    {
      "label": "解約日",
      "value": "orderExpiredDate"
    }
  ];
  $scope.dateField = $scope.dateFields[0].value;

  var maxDate = new Date();
  maxDate.setFullYear( maxDate.getFullYear() + 1 );

  $scope.dateOptions = {
    datepickerMode: 'day',
    formatYear: 'yyyy',
    maxDate: maxDate,
    startingDay: 0
  };

  $scope.dateFromPicker = {
    opened: false
  };
  $scope.dateToPicker = {
    opened: false
  };

  $scope.panel = {
    visible: false
  };

  $scope.altInputFormats = ['/yyyy-MM-dd'];

  $scope.limit = $scope.limits[0];
  $scope.statusFilter = [];
  $scope.sellerFilter = [];
  $scope.paymentFilter = [];
  $scope.hideTable = true;

  // Parse QueryString
  if (!angular.isUndefined($location.search()['searchWord'])) {
    $scope.searchFreeWord = decodeURIComponent($location.search()['searchWord'])
  }
  if (!angular.isUndefined($location.search()['status'])) {
    $scope.statusFilter = decodeURIComponent($location.search()['status']).split(',');
  }
  if (!angular.isUndefined($location.search()['sellerId'])) {
    $scope.sellerFilter = decodeURIComponent($location.search()['sellerId']).split(',');
  }
  if (!angular.isUndefined($location.search()['paymentMethod'])) {
    $scope.paymentFilter = decodeURIComponent($location.search()['paymentMethod']).split(',');
  }
  if (!angular.isUndefined($location.search()['limit'])) {
    var limit = parseInt(decodeURIComponent($location.search()['limit']));
    var res = $filter('filter')($scope.limits, {
      value: limit
    }, true);
    if (res.length > 0)
      $scope.limit = res[0];
  }
  if (!angular.isUndefined($location.search()['dateField'])) {
    $scope.dateField = decodeURIComponent($location.search()['dateField']);
  }
  if (!angular.isUndefined($location.search()['dateFrom'])) {
    $scope.dateFrom = new Date(decodeURIComponent($location.search()['dateFrom']));
  }
  if (!angular.isUndefined($location.search()['dateTo'])) {
    $scope.dateTo = new Date(decodeURIComponent($location.search()['dateTo']));
  }

  _loadList();

}

module.exports = orderListController;
