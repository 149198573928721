'use strict';

CloudOptions.$inject = [
  '$http',
  'API'
];

function CloudOptions ( $http, API ) {
  var _listCloudOptions = function() {
    var url = API + '/api/cloudOptions/';
    return $http({
      method: 'get',
      url: url
    });
  };

  var _PagedAll = function(num) {
    var url = API + '/api/cloudOptions/pages/' + num;
    return $http({
      method: 'get',
      url: url
    });
  };

  var _load = function(id) {
    var url = API + '/api/cloudOptions/' + id;

    return $http({
      method: 'get',
      url: url
    });
  };

  var _save = function(id, data) {
    var url = API + '/api/cloudOptions/';
    var verb = 'post';
    if ( id ) {
      url += id;
      verb = 'put';
    }

    return $http({
      method: verb,
      url: url,
      data: data
    });
  };

  var _delete = function(id) {
    var url = API + '/api/cloudOptions/' + id;

    return $http({
      method: 'delete',
      url: url
    });
  };
  
  return {
    listCloudOptions: function( ) {
      return _listCloudOptions().then(function(res) {
        return res.data;
      }, function(res){
        return res.data;
      });
    },
    pagedAll: function(num) {
      return _PagedAll(num).then(function(res) {
        return res.data;
      }, function(res){
        return res.data;
      });
    },
    load: function(id) {
      return _load(id).then(function(res) {
        return res.data;
      }, function(res){
        return res.data;
      })
    },
    save: function(id, data) {
      return _save(id, data).then(function(res) {
        return res.data;
      }, function(res){
        return res.data;
      })
    },
    delete: function(id) {
      return _delete(id).then(function(res) {
        return res.data;
      }, function(res){
        return res.data;
      })
    }
  };
};

module.exports = CloudOptions;
