'use strict';

instanceSelectorController.$inject = [
  '$scope',
  '$uibModal',
  '$uibModalInstance',
  'Instances',
  'params'
];

function instanceSelectorController($scope, $uibModal, $uibModalInstance, Instances, params) {

  //////////////////////////////////////////////////////////////////////
  // Functions
  //////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////////
  // Select Row
  var _selectRow = function(selected) {
    var instance = $scope.instances[selected];
    var idx = $scope.selected.findIndex(function(v){
      return v.instanceId == instance.instanceId;
    });
    if (idx == -1) {
      $scope.selected.push(instance);
    } else {
      $scope.selected.splice(idx, 1);
    }
  };

  //////////////////////////////////////////////////////////////////////
  // Return true when vendor is already selected.
  var _isSelected = function(selected) {
    var instance = $scope.instances[selected];
    var idx = $scope.selected.findIndex(function(v){
      return v.instanceId == instance.instanceId;
    });
    return idx == -1 ? false : true;
  };

  //////////////////////////////////////////////////////////////////////
  // Remove selected item
  var _remove = function(selected) {
    $scope.selected.splice(selected, 1);
  };

  //////////////////////////////////////////////////////////////////////
  // OK
  var _choose = function() {
    var ids = [];
    angular.forEach($scope.selected, function(v, i){
      ids.push(v.instanceId);
    });
    $uibModalInstance.close(ids);
  };

  //////////////////////////////////////////////////////////////////////
  // Cancel
  var _cancel = function() {
    $uibModalInstance.dismiss('cancel');
  };

  //////////////////////////////////////////////////////////////////////
  // Page move
  var _pageChanged = function() {
    _loadInstances($scope.currentPage);
  };

  //////////////////////////////////////////////////////////////////////
  // Search
  $scope.filterList = function() {
    _loadInstances(1);
  };

  //////////////////////////////////////////////////////////////////////
  // Function Mapping
  //////////////////////////////////////////////////////////////////////
  $scope.selectRow = _selectRow;
  $scope.isSelected = _isSelected;
  $scope.choose = _choose;
  $scope.cancel = _cancel;
  $scope.pageChanged = _pageChanged;
  $scope.remove = _remove;

  //////////////////////////////////////////////////////////////////////
  // Utilities
  //////////////////////////////////////////////////////////////////////
  var _loadInstances = function(page) {
    var modal = $uibModal.open({
      templateUrl: "views/modal/progress.html",
      backdrop: "static",
      keyboard: false
    });

    var param = {
      searchFreeWord: $scope.searchFreeWord
    };
    if ( params.instances && params.instances.length > 0) {
      var ids = [];
      angular.forEach(params.instances, function(instance, i){
        ids.push(instance.instanceId + "");
      })
      param.excludeIds = ids.join(',')
    }

    Instances.listInstances(page, param).then(
      function(data){
        if (data.totalElements > 0) {
          $scope.hideTable = false;
          $scope.totalItems = data.totalElements;
          $scope.instances = data.content;
          $scope.currentPage = data.number + 1
          $scope.itemsPerPage = data.size;
          $scope.maxSize = 7;
        } else {
          $scope.hideTable = true;
        }
        modal.close();
      },
      function(data){
        $uibModalInstance.dismiss(data.message);
        modal.close();
      }
    );
  };
    
  //////////////////////////////////////////////////////////////////////
  // Initialize
  //////////////////////////////////////////////////////////////////////
  $scope.accountName = params.accountName;
  $scope.selected = [];
  $scope.hideTable = true;

  _loadInstances(1);

}

module.exports = instanceSelectorController;
