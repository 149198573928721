'use strict';

exportOrderBySalesController.$inject = [
  '$scope',
  '$localStorage',
  '$uibModal',
  '$location',
  'Sellers',
  'ExportService'
];

function exportOrderBySalesController($scope, $routeParams, $uibModal, $location, Sellers, ExportService) {

  //////////////////////////////////////////////////////////////////////
  // Loading seller list
  var _loadSellerList = function() {
    Sellers.listAvailable().then(
      function(data) {
        if ( !data || data.message ) {
          $scope.messages.status = 'danger';
          $scope.messages.message = data.message;
        }
        else {
          $scope.sellerList = data;
        }
      }
    );
  };

  //////////////////////////////////////////////////////////////////////
  // Making export data and download it.
  var _exportOrder = function() {

    $scope.messages = {
      status: '',
      message: '',
    };

    var params = {};
    if ($scope.filterBySeller == 1) {
      var sellerArray = new Array();
      angular.forEach( $scope.sellerList, function(seller, idx) {
        if ( seller.checked ) {
          sellerArray.push(seller.sellerId);
        }
      })

      var ids = sellerArray.join(',');
      if ( ids != '') {
        params.sellerId = ids;
      }
    }

    if ( $scope.filterByDateRange == 1) {
      params.dateField = $scope.dateField;

      if ( $scope.dateFrom ) {
        var yyyy = $scope.dateFrom.getFullYear();
        var mm = ('0' + ($scope.dateFrom.getMonth() + 1)).slice(-2);
        var dd = ('0' + ($scope.dateFrom.getDate())).slice(-2)
        params.begin = yyyy + '-' + mm + '-' + dd;
      }

      if ( $scope.dateTo ) {
        var yyyy = $scope.dateTo.getFullYear();
        var mm = ('0' + ($scope.dateTo.getMonth() + 1)).slice(-2);
        var dd = ('0' + ($scope.dateTo.getDate())).slice(-2)
        params.end = yyyy + '-' + mm + '-' + dd;
      }
    }

    if ( $scope.sortByAny == 1) {
      params.sortField = $scope.sortBy;
      params.sortOrder = $scope.sortOrder;
    }

    var date = new Date();
    var outputFilename = "c3-sales-report-"
      + date.getFullYear()
      + ('0' + (date.getMonth() + 1)).slice(-2)
      + ('0' + date.getDate()).slice(-2)
      + ('0' + date.getHours()).slice(-2)
      + ('0' + date.getMinutes()).slice(-2)
      + ('0' + date.getSeconds()).slice(-2)
      + '.csv';

    var modal = $uibModal.open({
      templateUrl: "views/modal/progress.html",
      backdrop: "static",
      keyboard: false
    });

    ExportService.exportOrderBySales(params).then(function(data){
      modal.close();

      if ( data instanceof Blob ) {
        var a = document.createElement('a');
        a.download = outputFilename;
        a.href = window.URL.createObjectURL(data);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        a.remove();

        $scope.messages.status = 'success';
        $scope.messages.message = 'CSV ファイルをダウンロードしました。';
      }
      else {
        $scope.messages.status = 'danger';
        $scope.messages.message = data.message;
      }
    })
  };

  //////////////////////////////////////////////////////////////////////
  // Ninding functions
  $scope.exportOrder = _exportOrder;

  //////////////////////////////////////////////////////////////////////
  // Initializex
  $scope.messages = {
    status: '',
    message: '',
  };

  $scope.fieldList = [
    { "label" : "注文受付日", "value" : "orderDate" },
    { "label" : "利用開始日", "value" : "orderActivationDate" },
    { "label" : "課金基準日", "value" : "orderBillingDate" },
    { "label" : "解約日", "value" : "orderExpiredDate"}
  ];
  $scope.dateField = $scope.fieldList[0].value;

  $scope.sortByList = [
    { "label" : "注文受付日", "value" : "orderDate" },
    { "label" : "利用開始日", "value" : "orderActivationDate" },
    { "label" : "課金基準日", "value" : "orderBillingDate"},
    { "label" : "解約日", "value" : "orderExpiredDate"},
    { "label" : "注文番号", "value" : "orderCode"}
  ];
  $scope.sortBy = $scope.sortByList[0].value;

  $scope.sortOrderList = [
    { "label" : "昇順", "value" : "ASC" },
    { "label" : "降順", "value" : "DESC" },

  ];
  $scope.sortOrder = $scope.sortOrderList [1].value;

  _loadSellerList();

}

module.exports = exportOrderBySalesController;
