'use strict';

Licenses.$inject = [
  '$http',
  'API'
]

function Licenses( $http, API ) {
  var _listLicenses = function( pageNum, params ) {
    var url = API + '/api/licenses/pages/' + pageNum;
    return $http({
      method: 'get',
      url: url,
      params: params
    });
  };

  var _getLicense = function(id) {
    var url = API + '/api/licenses/' + id;
    return $http({
      method: 'get',
      url: url
    });
  };

  var _grab = function(orderId) {
    var url = API + '/api/licenses/assign?orderId=' + orderId;
    return $http({
      method: 'post',
      url: url
    });
  };

  var _invalidate = function(id) {
    var url = API + '/api/licenses/' + id + '/invalidate';
    return $http({
      method: 'post',
      url: url
    });
  };

  var _addBulk = function(data) {
    var url = API + '/api/licenses/';
    return $http({
      method: 'post',
      url: url,
      data: data
    });
  };

  return {
    listLicenses: function(pageNum, params) {
      return _listLicenses(pageNum, params).then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      );
    },
    getLicense: function(id) {
      return _getLicense(id).then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      );
    },
    grab: function(orderId) {
      return _grab(orderId).then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      );
    },
    invalidate: function(id) {
      return _invalidate(id).then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      );
    },
    addBulk: function(data) {
      return _addBulk(data).then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      );
    }
  };
};

module.exports = Licenses;
