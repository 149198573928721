'use strict';

iaasAccountSelectorController.$inject = [
  '$scope',
  '$uibModal',
  '$uibModalInstance',
  'IaasAccounts',
  'params'
];

function iaasAccountSelectorController($scope, $uibModal, $uibModalInstance, IaasAccounts, params) {

  //////////////////////////////////////////////////////////////////////
  // Functions
  //////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////////
  // Select Row
  var _selectRow = function(selected) {
    var iaasAccount = $scope.iaasAccounts[selected];
    var idx = $scope.selected.indexOf(iaasAccount.iaasAccountId);
    if (idx == -1) {
      $scope.selected.push(iaasAccount.iaasAccountId);
    } else {
      $scope.selected.splice(idx, 1);
    }
  };

  //////////////////////////////////////////////////////////////////////
  // Return true when vendor is already selected.
  var _isSelected = function(selected) {
    var idx = -1;
    if ( $scope.iaasAccounts ) {
      var iaasAccount = $scope.iaasAccounts[selected];
      idx = $scope.selected.indexOf(iaasAccount.iaasAccountId);
    }
    return idx == -1 ? false : true;
  };

  //////////////////////////////////////////////////////////////////////
  // OK
  var _choose = function() {
    $uibModalInstance.close($scope.selected);
  };

  //////////////////////////////////////////////////////////////////////
  // Cancel
  var _cancel = function() {
    $uibModalInstance.dismiss('cancel');
  };

  //////////////////////////////////////////////////////////////////////
  // Function Mapping
  //////////////////////////////////////////////////////////////////////
  $scope.selectRow = _selectRow;
  $scope.isSelected = _isSelected;
  $scope.choose = _choose;
  $scope.cancel = _cancel;

  //////////////////////////////////////////////////////////////////////
  // Utilities
  //////////////////////////////////////////////////////////////////////
  var _loadIaasAccounts = function() {
    var modal = $uibModal.open({
      templateUrl: "views/modal/progress.html",
      backdrop: "static",
      keyboard: false
    });

    IaasAccounts.listAll().then(
      function(data){
        if (data && data.length > 0) {
          var values = [];
          if ( params.iaasAccounts && params.iaasAccounts.length > 0) {
            angular.forEach(data, function(iaasAccount, i){
              if ( params.iaasAccounts && params.iaasAccounts.findIndex(function(v){
                return v.iaasAccountId == iaasAccount.iaasAccountId;
              }) == -1) {
                values.push(iaasAccount);
              };
            });
          }
          else {
            values = data;
          }
          if ( values.length > 0 ) {
            $scope.hideTable = false;
            $scope.iaasAccounts = values;
          }
        } else {
          $scope.hideTable = true;
        }
        modal.close();
      },
      function(data){
        $uibModalInstance.dismiss(data.message);
        modal.close();
      }
    );
  };

  //////////////////////////////////////////////////////////////////////
  // Initialize
  //////////////////////////////////////////////////////////////////////
  $scope.vendorName = params.vendorName;
  $scope.selected = [];
  $scope.hideTable = true;

  _loadIaasAccounts();

}

module.exports = iaasAccountSelectorController;
