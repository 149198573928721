'use strict';

Vendors.$inject = [
  '$http',
  'API'
];

function Vendors( $http, API ) {
  var _listVendors = function() {
    var url = API + '/api/vendors/';
    return $http({
      method: 'get',
      url: url
    });
  };

  var _listAllPrivate = function() {
    var url = API + '/api/vendors/private';
    return $http({
      method: 'get',
      url: url
    });
  };

  var _listAllVendors = function(page, params) {
    var url = API + '/api/vendors/pages/' + page;
    return $http({
      method: 'get',
      url: url,
      params: params
    });
  };

  var _getVendor = function(id) {
    var url = API + '/api/vendors/' + id;
    return $http({
      method: 'get',
      url: url
    });
  };

  var _save = function(id, data) {
    var url = API + '/api/vendors/';
    var verb = 'post';
    if (id) {
      url += id;
      verb = 'put';
    }
    return $http({
      method: verb,
      url: url,
      data: data
    });
  };

  var _delete = function(id) {
    var url = API + '/api/vendors/' + id;
    return $http({
      method: 'delete',
      url: url
    });
  };

  var _addIaasAccount = function(id, data) {
    var url = API + '/api/vendors/' + id + '/iaasAccounts/';
    var verb = 'post';
    return $http({
      method: verb,
      url: url,
      data: data
    });
  };

  var _removeIaasAccount = function(id, iaasAccountId) {
    var url = API + '/api/vendors/' + id + '/iaasAccounts/' + iaasAccountId;
    var verb = 'delete';
    return $http({
      method: verb,
      url: url
    });
  };

  var _addProductGroup = function(id, data) {
    var url = API + '/api/vendors/' + id + '/productGroups/';
    var verb = 'post';
    return $http({
      method: verb,
      url: url,
      data: data
    });
  };

  var _removeProductGroup = function(id, productGroupId) {
    var url = API + '/api/vendors/' + id + '/productGroups/' + productGroupId;
    var verb = 'delete';
    return $http({
      method: verb,
      url: url
    });
  };

  var _listAccount = function(id) {
    var url = API + '/api/vendors/' + id + '/accounts/';
    return $http({
      method: 'get',
      url: url
    });
  };

  var _addAccount = function(id, data) {
    var url = API + '/api/vendors/' + id + '/accounts/';
    var verb = 'post';
    return $http({
      method: verb,
      url: url,
      data: data
    });
  };

  var _removeAccount = function(id, accountId) {
    var url = API + '/api/vendors/' + id + '/accounts/' + accountId;
    var verb = 'delete';
    return $http({
      method: verb,
      url: url
    });
  };

  var _getBillingData = function(id, date) {
    var url = API + '/api/vendors/' + id + '/billing/' + date;
    var verb = 'get';
    return $http({
      method: verb,
      url: url
    });
  };

  return {
    listVendors: function() {
      return _listVendors().then(function(res) {
        return res.data;
      }, function(res){
        return res.data;
      })
    },
    listAllVendors: function(page, params) {
      return _listAllVendors(page, params).then(function(res) {
        return res.data;
      }, function(res){
        return res.data;
      })
    },
    listAllPrivate: function() {
      return _listAllPrivate().then(function(res) {
        return res.data;
      }, function(res){
        return res.data;
      })
    },
    getVendor: function(id) {
      return _getVendor(id).then(function(res) {
        return res.data;
      }, function(res){
        return res.data;
      })
    },
    save: function(id, data) {
      return _save(id, data).then(function(res) {
        return res.data;
      }, function(res){
        return res.data;
      })
    },
    delete: function(id) {
      return _delete(id).then(function(res) {
        return res.data;
      }, function(res){
        return res.data;
      })
    },
    addIaasAccount: function(id, data) {
      return _addIaasAccount(id, data).then(function(res) {
        return res.data;
      }, function(res){
        return res.data;
      })
    },
    removeIaasAccount: function(id, iaasAccountId) {
      return _removeIaasAccount(id, iaasAccountId).then(function(res) {
        return res.data;
      }, function(res){
        return res.data;
      })
    },
    addProductGroup: function(id, data) {
      return _addProductGroup(id, data).then(function(res) {
        return res.data;
      }, function(res){
        return res.data;
      })
    },
    removeProductGroup: function(id, productGroupId) {
      return _removeProductGroup(id, productGroupId).then(function(res) {
        return res.data;
      }, function(res){
        return res.data;
      })
    },
    listAccount: function(id) {
      return _listAccount(id).then(function(res) {
        return res.data;
      }, function(res){
        return res.data;
      })
    },
    addAccount: function(id, data) {
      return _addAccount(id, data).then(function(res) {
        return res.data;
      }, function(res){
        return res.data;
      })
    },
    removeAccount: function(id, accountId) {
      return _removeAccount(id, accountId).then(function(res) {
        return res.data;
      }, function(res){
        return res.data;
      })
    },
    getBillingData: function(id, date) {
      return _getBillingData(id, date).then(function(res) {
        return res.data;
      }, function(res){
        return res.data;
      })
    }
  };
};

module.exports = Vendors;
