'use strict';

licenseBulkController.$inject = [
  '$scope',
  '$uibModal',
  '$location',
  'Licenses'
];

function licenseBulkController($scope, $uibModal, $location, Licenses) {

  ////////////////////////////////////////////////////////////
  // Functions
  ////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////
  // Methods
  ////////////////////////////////////////////////////////////
  var _addBulk = function() {
    var modal = $uibModal.open({
      templateUrl: "views/modal/progress.html",
      backdrop: "static",
      keyboard: false
    });

    var data = {
      code: $scope.licenseCode
    };

    $scope.requestLicenseCode = '';
    $scope.resultSuccess = '';
    $scope.resultFailure = '';

    Licenses.addBulk(data).then(function(res){
      modal.close();

      if (data.message == undefined) {
        $scope.requestLicenseCode = $scope.licenseCode;
        if ( res.success ) {
          $scope.resultSuccess = res.success.join("\n");
        }
        if ( res.failure ) {
          $scope.resultFailure = res.failure.join("\n");
        }
  
        $scope.result = true;
        $scope.licenseCode = '';

        $scope.messages.status = 'success';
        $scope.messages.message = "ライセンスコードを追加しました。";
      } else {
        $scope.messages.status = 'danger';
        $scope.messages.message = data.message;
      }
    });
  };

  var _back = function() {
    $location.path('/licenses/pages/1');
  };

  ////////////////////////////////////////////////////////////
  // Function Mapping
  ////////////////////////////////////////////////////////////
  $scope.addBulk = _addBulk;
  $scope.back = _back;

  ////////////////////////////////////////////////////////////
  // Initialize
  ////////////////////////////////////////////////////////////
  $scope.messages = {
    status: '',
    message: '',
  };

  $scope.licenseCode = '';
  $scope.requestLicenseCode = '';
  $scope.resultSuccess = '';
  $scope.resultFailure = '';
  $scope.result = false;

};

module.exports = licenseBulkController;