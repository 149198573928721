'use strict';

licenseListController.$inject = [
  '$scope',
  '$uibModal',
  '$routeParams',
  '$location',
  '$filter',
  'Licenses'
];

function licenseListController($scope, $uibModal, $routeParams, $location, $filter, Licenses) {

  ////////////////////////////////////////////////////////////
  // Methods
  ////////////////////////////////////////////////////////////
  var _loadLicenses = function() {
    var modal = $uibModal.open({
      templateUrl: "views/modal/progress.html",
      backdrop: "static",
      keyboard: false
    });

    Licenses.listLicenses($routeParams.num, $location.search()).then(function(data){
      $scope.licenses = data.content;
      if ( data.totalElements == 0 ) {
        $scope.hideTable = true;
      } else {
        $scope.hideTable = false;
      }

      // Pagination
      $scope.totalItems = data.totalElements;
      $scope.currentPage = data.number + 1
      $scope.maxSize = 7;
      $scope.itemsPerPage = data.size;

      $scope.pageChanged = function() {
        $location.path('/licenses/pages/' + $scope.currentPage);
      };

      modal.close();
    });
  };

  ////////////////////////////////////////////////////////////
  // Functions
  ////////////////////////////////////////////////////////////
  var _filterLicenseList = function() {
    var params = "";
    if ($scope.code) {
      params += "code=" + encodeURIComponent($scope.code);
    }
    if ($scope.orderId) {
      params += "orderId=" + encodeURIComponent($scope.orderId);
    }
    if ($scope.orderCode) {
      params += "orderCode=" + encodeURIComponent($scope.orderCode);
    }

    if ($scope.statusFilter.length > 0) {
      if (params.length > 0) {
        params += '&';
      }
      params += "statusFilter=" + encodeURIComponent($scope.statusFilter.join(','));
    }
    if ($scope.limit) {
      if (params.length > 0) {
        params += '&';
      }
      params += "limit=" + $scope.limit.value;
    }

    // Back to 1st page
    $location.path('/licenses/pages/1');
    $location.search(params);

    _loadLicenses();
  }

  var _resetFilter = function() {
    $scope.statusFilter = [];
    $scope.code = '';
    $scope.orderId = '';
    $scope.orderCode = '';
    $location.url('/licenses/pages/1');
  }

  var _changeLimit = function() {
    _filterLicenseList();
  };

  var _togglePanel = function() {
    $scope.panel.visible = !$scope.panel.visible
  }

  var _toggleStatusFilter = function(val) {
    var idx = $scope.statusFilter.indexOf(val + "");
    if (idx == -1) {
      $scope.statusFilter.push(val + "");
    }
    else {
      $scope.statusFilter.splice(idx, 1);
    }
  }

  var _grabLicense = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';

    // Open modal
    var modal = $uibModal.open({
      size: 'lg',
      templateUrl: "views/modal/list_order.html",
      backdrop: "static",
      scope: $scope,
      controller: 'assignLicenseModalController',
    });

    // Result
    modal.result.then(
      function(data) {
        $scope.messages.status = 'success';
        $scope.messages.message = '外部ライセンスコード (' + data.licenseCode + ')を注文情報 (' + data.order.orderCode + ') に割り当てました。';
      },
      function(result) {
        if (result != 'cancel') {
          $scope.messages.status = 'danger';
          $scope.messages.message = result;
        }
      }
    );
  };

  ////////////////////////////////////////////////////////////
  // Function Mapping
  ////////////////////////////////////////////////////////////
  $scope.filterLicenseList = _filterLicenseList;
  $scope.toggleStatusFilter = _toggleStatusFilter;
  $scope.resetFilter = _resetFilter;
  $scope.togglePanel = _togglePanel;
  $scope.changeLimit = _changeLimit;
  $scope.grab = _grabLicense;

  ////////////////////////////////////////////////////////////
  // Initialize
  ////////////////////////////////////////////////////////////
  $scope.messages = {
    status: '',
    message: '',
  };

  $scope.limits = [{
    label: '10件',
    value: 10
  },
  {
    label: '20件',
    value: 20
  },
  {
    label: '50件',
    value: 50
  },
  {
    label: '100件',
    value: 100
  }];

  $scope.panel = {
    visible: false
  };

  $scope.hideTable = true;
  $scope.limit = $scope.limits[0];
  $scope.statusFilter = [];

  // Parse QueryString
  if (!angular.isUndefined($location.search()['code'])) {
    $scope.code = decodeURIComponent($location.search()['code'])
  }
  if (!angular.isUndefined($location.search()['orderId'])) {
    $scope.orderId = decodeURIComponent($location.search()['orderId'])
  }
  if (!angular.isUndefined($location.search()['orderCode'])) {
    $scope.orderCode = decodeURIComponent($location.search()['orderCode'])
  }
  if (!angular.isUndefined($location.search()['statusFilter'])) {
    $scope.statusFilter = decodeURIComponent($location.search()['statusFilter']).split(',');
  }
  if (!angular.isUndefined($location.search()['limit'])) {
    var limit = parseInt(decodeURIComponent($location.search()['limit']));
    var res = $filter('filter')($scope.limits, {
      value: limit
    }, true);
    if (res.length > 0)
      $scope.limit = res[0];
  }

  // Load initial list
  _loadLicenses();

};

module.exports = licenseListController;