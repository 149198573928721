'use strict';

licenseController.$inject = [
  '$scope',
  '$uibModal',
  '$routeParams',
  'Licenses'
];

function licenseController($scope, $uibModal, $routeParams, Licenses) {

  ////////////////////////////////////////////////////////////
  // Functions
  ////////////////////////////////////////////////////////////
  var _loadLicense = function() {
    var modal = $uibModal.open({
      templateUrl: "views/modal/progress.html",
      backdrop: "static",
      keyboard: false
    });
  
    Licenses.getLicense($routeParams.id).then(function(data){
      $scope.license = data;
      modal.close();
    });
  };

  ////////////////////////////////////////////////////////////
  // Methods
  ////////////////////////////////////////////////////////////
  var _cancellation = function() {
    var modal = $uibModal.open({
      templateUrl: "views/modal/confirm.html",
      backdrop: "static",
      keyboard: false,
      scope: $scope,
      controller: function($uibModalInstance, $scope) {
        $scope.title = "外部ライセンスコードの無効化";
        $scope.messageBody = "この外部ライセンスコードを無効化します。よろしいですか？";
        $scope.okay = function() {
          $uibModalInstance.close();
        };
        $scope.cancel = function() {
          $uibModalInstance.dismiss('cancel');
        };
      }
    });
    modal.result.then(function() {
      var modal = $uibModal.open({
        templateUrl: "views/modal/progress.html",
        backdrop: "static",
        keyboard: false
      });

      Licenses.invalidate($scope.license.licenseId).then(function(data) {
        modal.close();
        if (data.message == undefined) {
          $scope.messages.status = 'success';
          $scope.messages.message = "外部ライセンスコードを無効化しました。";
          $scope.license = data;
        } else {
          $scope.messages.status = 'danger';
          $scope.messages.message = data.message;
        }
      })
    }, function() {});
  };

  ////////////////////////////////////////////////////////////
  // Function Mapping
  ////////////////////////////////////////////////////////////
  $scope.cancellation = _cancellation;

  ////////////////////////////////////////////////////////////
  // Initialize
  ////////////////////////////////////////////////////////////
  $scope.messages = {
    status: '',
    message: '',
  };

  _loadLicense();
};

module.exports = licenseController;