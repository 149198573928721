'use strict';

logListController.$inject = [
  '$scope',
  '$uibModal',
  '$routeParams',
  '$location',
  '$filter',
  'Logs'
];

function logListController($scope, $uibModal, $routeParams, $location, $filter, Logs) {

  ////////////////////////////////////////////////////////////
  // Methods
  ////////////////////////////////////////////////////////////
  var _format_ts = function(date, format) {
    var year = date.getFullYear();
    var month = ('0' + (date.getMonth() + 1)).slice(-2);
    var day = ('0' + (date.getDate())).slice(-2)
    var hour = ('0' + (date.getHours())).slice(-2)
    var min = ('0' + (date.getMinutes())).slice(-2)
    var sec = ('0' + (date.getSeconds())).slice(-2)

    var res;
    res = format.replace(/YYYY/g, year);
    res = res.replace(/MM/g, month);
    res = res.replace(/DD/g, day);
    res = res.replace(/HH/g, hour);
    res = res.replace(/mm/g, min);
    res = res.replace(/ss/g, sec);

    return res;
  }

  //////////////////////////////////////////////////////////////////////
  // Open date picker 
  var _openDatePicker = function(fld) {
    fld.opened = true;
  }

  ////////////////////////////////////////////////////////////
  // Functions
  ////////////////////////////////////////////////////////////
  var _load = function() {
    var modal = $uibModal.open({
      templateUrl: "views/modal/progress.html",
      backdrop: "static",
      keyboard: false
    });

    var params = angular.copy($location.search());
    if ( params.dateFrom ) {
      params.dateFrom = _format_ts( new Date(Number(params.dateFrom)), 'YYYY-MM-DD HH:mm:ss')
    }
    if ( params.dateTo ) {
      params.dateTo = _format_ts( new Date(Number(params.dateTo)), 'YYYY-MM-DD HH:mm:ss')
    }
    Logs.pagedAll($routeParams.num, params).then(function(data){
      $scope.logs = data.content;
      if ( data.totalElements == 0 ) {
        $scope.hideTable = true;
      } else {
        $scope.hideTable = false;
      }

      // Pagination
      $scope.totalItems = data.totalElements;
      $scope.currentPage = data.number + 1
      $scope.maxSize = 7;
      $scope.itemsPerPage = data.size;

      $scope.pageChanged = function() {
        $location.path('/logs/pages/' + $scope.currentPage);
      };

      modal.close();
    });
  };

  //////////////////////////////////////////////////////////////////////
  // Loading order list with filter
  var _filterLogList = function() {
    var params = "";
    if ($scope.searchFreeWord) {
      params += "searchWord=" + encodeURIComponent($scope.searchFreeWord);
    }
    if ($scope.targetFilter.length > 0) {
      if (params.length > 0) {
        params += '&';
      }
      params += "target=" + encodeURIComponent($scope.targetFilter.join(','));
    }
    if ($scope.dateFrom || $scope.dateTo) {
      if (params.length > 0) {
        params += '&';
      }
      if ($scope.dateFrom) {
        params += '&dateFrom=' + $scope.dateFrom.getTime();
      }
      if ($scope.dateTo) {
        params += '&dateTo=' + $scope.dateTo.getTime();
      }
    }

    if ($scope.limit) {
      if (params.length > 0) {
        params += '&';
      }
      params += "limit=" + $scope.limit.value;
    }

    // Back to 1st page
    $location.path('/logs/pages/1');
    $location.search(params);

    _load();
  }

  //////////////////////////////////////////////////////////////////////
  // Clear list filters
  var _resetFilter = function() {
    $scope.searchFreeWord = '';
    $scope.statusFilter = [];
    $location.url('/logs/pages/1');
  }

  //////////////////////////////////////////////////////////////////////
  // Toggle status filter item
  var _toggleTargetFilter = function(val) {
    var idx = $scope.targetFilter.indexOf(val + "");
    if (idx == -1) {
      $scope.targetFilter.push(val + "");
    } else {
      $scope.targetFilter.splice(idx, 1);
    }
  }

  //////////////////////////////////////////////////////////////////////
  // Change limit 
  var _changeLimit = function() {
    _filterLogList();
  };

  //////////////////////////////////////////////////////////////////////
  // Toggle search panel visibility
  var _togglePanel = function() {
    $scope.panel.visible = !$scope.panel.visible
  }

  //////////////////////////////////////////////////////////////////////
  // Open date picker 
  var _openDateFromPicker = function() {
    _openDatePicker($scope.dateFromPicker);
  }
  var _openDateToPicker = function() {
    _openDatePicker($scope.dateToPicker);
  }

  //////////////////////////////////////////////////////////////////////
  // Clear date and time filter 
  var _clearDateFromPicker = function() {
    $scope.dateFrom = null;
  }
  var _clearDateToPicker = function() {
    $scope.dateFrom = null;
  }

  ////////////////////////////////////////////////////////////
  // Function Mapping
  ////////////////////////////////////////////////////////////
  $scope.filterLogList = _filterLogList;
  $scope.toggleTargetFilter = _toggleTargetFilter;
  $scope.resetFilter = _resetFilter;
  $scope.changeLimit = _changeLimit;
  $scope.togglePanel = _togglePanel;
  $scope.openDateFromPicker = _openDateFromPicker;
  $scope.openDateToPicker = _openDateToPicker;
  $scope.clearDateFromPicker = _clearDateFromPicker;
  $scope.clearDateToPicker = _clearDateToPicker;

  ////////////////////////////////////////////////////////////
  // Initialize
  ////////////////////////////////////////////////////////////
  $scope.messages = {
    status: '',
    message: '',
  };

  $scope.limits = [{
    label: '10件',
    value: 10
  },
  {
    label: '20件',
    value: 20
  },
  {
    label: '50件',
    value: 50
  },
  {
    label: '100件',
    value: 100
  }];

  $scope.panel = {
    visible: false
  };

  $scope.dateFromPicker = {
    opened: false
  }
  $scope.dateToPicker = {
    opened: false
  }

  $scope.hideTable = true;
  $scope.searchFreeWord = '';
  $scope.targetFilter = [];
  $scope.limit = $scope.limits[0];
  $scope.dateFrom = null;
  $scope.dateTo = null;

  // Parse QueryString
  if (!angular.isUndefined($location.search()['searchWord'])) {
    $scope.searchFreeWord = decodeURIComponent($location.search()['searchWord'])
  }
  if (!angular.isUndefined($location.search()['target'])) {
    $scope.targetFilter = decodeURIComponent($location.search()['target']).split(',');
  }
  if (!angular.isUndefined($location.search()['dateFrom'])) {
    $scope.dateFrom = new Date(Number($location.search()['dateFrom']));
  }
  if (!angular.isUndefined($location.search()['dateTo'])) {
    $scope.dateTo = new Date(Number($location.search()['dateTo']));
  }
  if (!angular.isUndefined($location.search()['limit'])) {
    var limit = parseInt(decodeURIComponent($location.search()['limit']));
    var res = $filter('filter')($scope.limits, {
      value: limit
    }, true);
    if (res.length > 0)
      $scope.limit = res[0];
  }

  // Load initial list
  _load();

};

module.exports = logListController;