'use strict';

var angular = require('angular');

angular.module('app').factory('Instances', require('./Instances'));
angular.module('app').factory('News', require('./News'));
angular.module('app').factory('Users', require('./Users'));
angular.module('app').factory('Feedback', require('./Feedback'));
angular.module('app').factory('Products', require('./Products'));
angular.module('app').factory('Orders', require('./Orders'));
angular.module('app').factory('Customers', require('./Customers'));
angular.module('app').factory('CustomerPersons', require('./CustomerPersons'));
angular.module('app').factory('Sellers', require('./Sellers'));
angular.module('app').factory('MailLog', require('./MailLog'));
angular.module('app').factory('Licenses', require('./Licenses'));
angular.module('app').factory('Vendors', require('./Vendors'));
angular.module('app').factory('CloudOptions', require('./CloudOptions'));
angular.module('app').factory('OptionServices', require('./OptionServices'));
angular.module('app').factory('IaasAccounts', require('./IaasAccounts'));
angular.module('app').factory('ProductGroups', require('./ProductGroups'));
angular.module('app').factory('CloudTypes', require('./CloudTypes'));
angular.module('app').factory('Logs', require('./Logs'));
angular.module('app').factory('IaasTypes', require('./IaasTypes'));
angular.module('app').factory('Templates', require('./Templates'));
angular.module('app').factory('ansibleLogs', require('./ansibleLogs'));
