'use strict';

cloudTypeListController.$inject = [
  '$scope',
  '$uibModal',
  '$routeParams',
  '$location',
  'CloudTypes'
];

function cloudTypeListController($scope, $uibModal, $routeParams, $location, CloudTypes) {

  ////////////////////////////////////////////////////////////
  // Methods
  ////////////////////////////////////////////////////////////
  var _load = function() {
    var modal = $uibModal.open({
      templateUrl: "views/modal/progress.html",
      backdrop: "static",
      keyboard: false
    });

    _loadCloudTypeList()
    .then(function(){
      modal.close();
    });
  };

  ////////////////////////////////////////////////////////////
  // Load Iaas Account
  var _loadCloudTypeList = function() {
    return CloudTypes.pagedCloudTypes($routeParams.num).then(function(data){
      $scope.cloudTypes = data.content;
      if ( data.totalElements == 0 ) {
        $scope.hideTable = true;
      } else {
        $scope.hideTable = false;
      }

      // Pagination
      $scope.totalItems = data.totalElements;
      $scope.currentPage = data.number + 1
      $scope.maxSize = 7;
      $scope.itemsPerPage = data.size;

      $scope.pageChanged = function() {
        $location.path('/cloudTypes/pages/' + $scope.currentPage);
      };
    });
  };

  ////////////////////////////////////////////////////////////
  // Functions
  ////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////
  // Function Mapping
  ////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////
  // Initialize
  ////////////////////////////////////////////////////////////
  $scope.messages = {
    status: '',
    message: '',
  };

  $scope.hideTable = true;

  // Load initial list
  _load();

};

module.exports = cloudTypeListController;