'use strict';

newCustomerController.$inject = [
  '$uibModalInstance',
  '$scope',
  '$uibModal',
  'Customers',
  'params'
];

function newCustomerController($uibModalInstance, $scope, $uibModal, Customers, params) {

  // Initialize
  $scope.messages = {
    status: '',
    message: '',
  };
  $scope.target = params.target;

  $scope.save = function() {
    var param = {
      vendorId: params.vendor.vendorId,
      name: $scope.customerName,
      tel: $scope.customerTel,
      zipCode: $scope.customerZipCode,
      address: $scope.customerAddress
    };

    var modal = $uibModal.open({
      templateUrl: "views/modal/progress.html",
      backdrop: "static",
      keyboard: false
    });

    Customers.save(null, param).then(function(data){
      modal.close();
      if (data.message == undefined) {
        $uibModalInstance.close(data);
      } else {
        $uibModalInstance.dismiss(data.message);
      }
    })
  };

  $scope.cancel = function() {
    $uibModalInstance.dismiss('cancel');
  };
};

module.exports = newCustomerController;
