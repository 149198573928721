'use strict';

feedbackController.$inject = [
  '$scope',
  '$routeParams',
  '$localStorage',
  '$uibModal',
  '$location',
  'Feedback',
  'Instances',
  'Products'
];

function feedbackController($scope, $routeParams, $localStorage, $uibModal, $location, Feedback, Instances, Products) {
  $scope.contactPurposeList = [
    {value: 'Technical Support', label: '技術的なお問い合わせ'},
    {value: 'Option Service', label: 'オプションサービスのお申し込み'},
    {value: 'Change Plan', label: 'プラン変更のお申し込み'},
    {value: 'Moving Site', label: 'サイト移行サービスのお申し込み'}
  ];
  $scope.contactPurpose = null;

  $scope.serviceList = [
    {id: 'sandbox-service', value: 'サンドボックス作成サービス', label: 'サンドボックス作成サービス'}
  ];

  Instances.listInstances().then(
    function(result){
      if (angular.isArray(result)) {
        $scope.instanceList = result;
      } else {
        $scope.messages.status = 'danger';
        $scope.messages.message = result.message;
      }
    },
    function (result) {
      $scope.messages.status = 'danger';
      $scope.messages.message = result;
    }
  );


  $scope.messages = {
    status: '',
    message: ''
  };

  var _loadPlan = function(versionId) {
    Products.listProducts(versionId).then(
      function (result) {
        if (angular.isArray(result)) {
          $scope.planList = result;
        } else {
          $scope.messages.status = 'danger';
          $scope.messages.message = result.message;
        }
      },
      function (result) {
        $scope.messages.status = 'danger';
        $scope.messages.message = result;
      }
    );
  };

  var _sendFeedback = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';
    var modal = $uibModal.open({
      templateUrl: "views/modal/progress.html",
      backdrop: "static",
      keyboard: false
    });

    // Reset freetext input when user not selected freetext
    var data = {};
    data.purpose = $scope.contactPurpose;
    data.instanceId = $scope.targetInstance;
    data.body = $scope.feedbackBody;
    data.billingZipCode = $scope.billingZipCode;
    data.billingAddress = $scope.billingAddoress;
    data.billingTel = $scope.billingTel;
    data.billingCompany = $scope.billingCompany;
    data.billingDept = $scope.billingDivision;
    data.billingPerson = $scope.billingPerson;
    data.sourceUrl = $scope.transferFromUrl;

    if ( $scope.newPlanDate ) {
      var yyyy = $scope.newPlanDate.getFullYear();
      var mm = ('0' + ($scope.newPlanDate.getMonth() + 1)).slice(-2);
      var dd = ('0' + ($scope.newPlanDate.getDate() + 1)).slice(-2)
      data.desiredDate = yyyy + '年' + mm + '月' + dd + '日';
    }
    if ( $scope.newPlanTime ) {
      var hh = $scope.newPlanTime.getHours();
      var mm = $scope.newPlanTime.getMinutes();

      data.desiredTime = hh + '時' + mm + '分';
    }

    var options = '';
    $scope.serviceList.some(function(val) {
      if ( val.checked ) {
        if ( options )
          options(', ');
        options += val.value
        return true;
      }
      return false;
    });
    data.optionService = options;

    if ( $scope.newPlan ) {
      $scope.planList.some(function(val) {
        if ( val.productName == $scope.newPlan) {
          data.newProductId = val.productId;
          $scope.newPlanName = $scope.newPlan;
          return true;
        }
        return false;
      });
    }

    $scope.instanceList.some(function(val){
      if ( val.instanceId == $scope.targetInstance) {
        $scope.targetInstanceName = val.instanceHostname;
        return true;
      }
      return false;
    });
    $scope.contactPurposeList.some(function(val){
      if ( val.value == $scope.contactPurpose) {
        $scope.contactPurposeLabel = val.label;
        return true;
      }
      return false;
    });

    Feedback.sendCustomerFeedback(data).then(function(data){
      modal.close();
      if (data.message == undefined) {
        $scope.messages.status = 'success';
        $scope.messages.message = 'お問い合わせを受け付けました。';
        $scope.isSent = true;
      } else {
        $scope.messages.status = 'danger';
        $scope.messages.message = data.message;
        $scope.isSent = false;
      }
    });
  };

  var _backToDashbioard = function() {
    $location.path('/dashboard');
  };

  $scope.sendFeedback = _sendFeedback;
  $scope.loadPlan = _loadPlan;
  $scope.back = _backToDashbioard;
}

module.exports = feedbackController;
