'use strict';

iaasTypeSelectorModalController.$inject = [
  '$uibModalInstance',
  '$scope',
  '$uibModal',
  'IaasTypes',
  'params'
];

function iaasTypeSelectorModalController($uibModalInstance, $scope, $uibModal, IaasTypes, params) {

  ////////////////////////////////////////////////////////////
  // Methods
  ////////////////////////////////////////////////////////////
  var _listIaasType = function(page, search) {
    var modal = $uibModal.open({
      templateUrl: "views/modal/progress.html",
      backdrop: "static",
      keyboard: false
    });

    IaasTypes.pagedAll(page).then(
      function(data){
        if (data.totalElements > 0) {
          $scope.hideTable = false;
          $scope.totalItems = data.totalElements;
          $scope.iaasTypes = data.content;
          $scope.currentPage = data.number + 1
          $scope.itemsPerPage = data.size;
          $scope.maxSize = 7;
        } else {
          $scope.hideTable = true;
        }
        modal.close();
      },
      function(data){
        $uibModalInstance.dismiss(data.message);
        modal.close();
      }
    );
  };

  ////////////////////////////////////////////////////////////
  // Functions
  ////////////////////////////////////////////////////////////
  $scope.pageChanged = function() {
    _listIaasType($scope.currentPage);
  };

  $scope.selectRow = function(idx) {
    var iaasType = $scope.iaasTypes[idx];
    if ($scope.selectedRow && $scope.selectedRow == iaasType) {
      $scope.selectedRow = null;
    } else {
      $scope.selectedRow = iaasType;
    }
  };

  $scope.choose = function() {
    $uibModalInstance.close($scope.selectedRow);
  };

  $scope.cancel = function() {
    $uibModalInstance.dismiss('cancel');
  };

  ////////////////////////////////////////////////////////////
  // Initialize
  ////////////////////////////////////////////////////////////
  $scope.messages = {
    status: '',
    message: '',
  };

  $scope.name = params.name;

  _listIaasType(1);
};

module.exports = iaasTypeSelectorModalController;
