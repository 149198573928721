'use strict';

sellerController.$inject = [
  '$scope',
  '$uibModal',
  '$routeParams',
  '$q',
  '$location',
  'Sellers'
];

function sellerController($scope, $uibModal, $routeParams, $q, $location, Sellers) {

  //////////////////////////////////////////////////////////////////////
  // Functions
  //////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////////
  // Save
  var _save = function() {
    var data = {
      sellerName: $scope.seller.sellerName,
      sellerSendCancellationNotify: $scope.seller.sellerSendCancellationNotify
    }

    var modal = $uibModal.open({
      templateUrl: "views/modal/progress.html",
      backdrop: "static",
      keyboard: false
    });

    var sellerId = null;
    if ( !$scope.isNew ) {
      sellerId = $scope.seller.sellerId;
    }
    Sellers.save(sellerId, data).then(function(result) {
      modal.close();
      if (result.message == undefined) {
        $scope.messages.status = 'success';
        $scope.messages.message = '契約形態レコードを保存しました。';
        $scope.seller = result;
        $scope.editMode = false;
        if ( $scope.isNew ) {
          $location.path('/sellers/' + result.sellerId);
        }
      } else {
        $scope.messages.status = 'danger';
        $scope.messages.message = result.message;
      }
    });
  };

  //////////////////////////////////////////////////////////////////////
  // Disable
  var _disable = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';

    var modal = $uibModal.open({
      templateUrl: "views/modal/confirm.html",
      backdrop: "static",
      keyboard: false,
      scope: $scope,
      controller: function($uibModalInstance, $scope) {
        $scope.title = "契約形態レコードの無効化";
        $scope.messageBody = "この契約形態レコードを無効にします。よろしいですか？";
        $scope.okay = function() {
          $uibModalInstance.close();
        };
        $scope.cancel = function() {
          $uibModalInstance.dismiss('cancel');
        };
      }
    });
    modal.result.then(function() {
      // Do destroy
      var modal = $uibModal.open({
        templateUrl: "views/modal/progress.html",
        backdrop: "static",
        keyboard: false
      });

      var data = {
        sellerStatus: 2
      };

      Sellers.save($scope.seller.sellerId, data).then(function(result) {
        modal.close();
        if (result.message == undefined) {
          $scope.messages.status = 'success';
          $scope.messages.message = '契約形態レコードを無効にしました。';
          $scope.seller = result;
          $scope.editMode = false;
          _resetAction();
        } else {
          $scope.messages.status = 'danger';
          $scope.messages.message = result.message;
        }
      });
    }, function() {});
  };

  //////////////////////////////////////////////////////////////////////
  // Enable
  var _enable = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';

    var modal = $uibModal.open({
      templateUrl: "views/modal/confirm.html",
      backdrop: "static",
      keyboard: false,
      scope: $scope,
      controller: function($uibModalInstance, $scope) {
        $scope.title = "契約形態レコードの有効化";
        $scope.messageBody = "この契約形態レコードを有効にします。よろしいですか？";
        $scope.okay = function() {
          $uibModalInstance.close();
        };
        $scope.cancel = function() {
          $uibModalInstance.dismiss('cancel');
        };
      }
    });
    modal.result.then(function() {
      // Do destroy
      var modal = $uibModal.open({
        templateUrl: "views/modal/progress.html",
        backdrop: "static",
        keyboard: false
      });

      var data = {
        sellerStatus: 1
      };

      Sellers.save($scope.seller.sellerId, data).then(function(result) {
        modal.close();
        if (result.message == undefined) {
          $scope.messages.status = 'success';
          $scope.messages.message = '契約形態レコードを有効にしました。';
          $scope.seller = result;
          $scope.editMode = false;
          _resetAction();
        } else {
          $scope.messages.status = 'danger';
          $scope.messages.message = result.message;
        }
      });
    }, function() {});
  };

  //////////////////////////////////////////////////////////////////////
  // Delete
  var _delete = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';

    var modal = $uibModal.open({
      templateUrl: "views/modal/confirm.html",
      backdrop: "static",
      keyboard: false,
      scope: $scope,
      controller: function($uibModalInstance, $scope) {
        $scope.title = "契約形態レコードの削除";
        $scope.messageBody = "この契約形態レコードを削除します。よろしいですか？";
        $scope.okay = function() {
          $uibModalInstance.close();
        };
        $scope.cancel = function() {
          $uibModalInstance.dismiss('cancel');
        };
      }
    });
    modal.result.then(function() {
      // Do destroy
      var modal = $uibModal.open({
        templateUrl: "views/modal/progress.html",
        backdrop: "static",
        keyboard: false
      });

      var data = {
        status: 2
      };

      Sellers.delete($scope.seller.sellerId).then(function(result) {
        modal.close();
        if (result.message == undefined) {
          $scope.messages.status = 'success';
          $scope.messages.message = '契約形態レコードを削除しました。';
          $scope.seller = result;
          $scope.editMode = false;
          _resetAction();
        } else {
          $scope.messages.status = 'danger';
          $scope.messages.message = result.message;
        }
      });
    }, function() {});
  };

  //////////////////////////////////////////////////////////////////////
  // Change edit mode
  var _editMode = function() {
    $scope.editMode = true;
  };

  //////////////////////////////////////////////////////////////////////
  // Bind functions
  //////////////////////////////////////////////////////////////////////
  $scope.save = _save;
  $scope.edit = _editMode;
  $scope.disable = _disable;
  $scope.enable = _enable;
  $scope.delete = _delete;

  //////////////////////////////////////////////////////////////////////
  // Methods
  //////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////////
  // Loading
  var _load = function() {
    if ( $scope.isNew ) {
      _resetAction();
    }
    else {
      var modal = $uibModal.open({
        templateUrl: "views/modal/progress.html",
        backdrop: "static",
        keyboard: false
      });

      $q.all([
        _loadSeller(),
      ])
      .then(function(){
        _resetAction();
        modal.close();
      });
    }
  };

  //////////////////////////////////////////////////////////////////////
  // Loading
  var _loadSeller = function() {
    return Sellers.load($scope.sellerId).then(function(data) {
      if (data.message == undefined) {
        $scope.seller = data;
      } else {
        $scope.messages.status = 'danger';
        $scope.messages.message = result.message;
      }
    });
  };

  //////////////////////////////////////////////////////////////////////
  // Initialization
  var _initialize = function() {
    $scope.messages = {
      status: '',
      message: '',
    };
  
    $scope.action = null;
    $scope.editMode = false;
    if ($routeParams.id) {
      $scope.sellerId = $routeParams.id;
      $scope.isNew = false;
    }
    else {
      $scope.isNew = true;
      $scope.editMode = true;
    }
  };

  //////////////////////////////////////////////////////////////////////
  // Reset action bar
  var _resetAction = function() {
    $scope.action = null;
    if ( $scope.isNew ) {
      $scope.action = {};
      $scope.action.canEdit = false;
      $scope.action.canDisable = false;
      $scope.action.canDelete = false;
    }
    else {
      if ($scope.seller.sellerStatus == 1) {
        $scope.action = {};
        $scope.action.canEdit = true;
        $scope.action.canDisable = true;
        $scope.action.canDelete = true;
      } else if ($scope.seller.sellerStatus == 2) {
        $scope.action = {};
        $scope.action.canEdit = true;
        $scope.action.canEnable = true;
        $scope.action.canDelete = true;
      }
    }
  };

  _initialize();
  _load();
};

module.exports = sellerController;