'use strict';

vendorController.$inject = [
  '$scope',
  '$uibModal',
  '$routeParams',
  '$q',
  '$location',
  'Vendors',
  'Sellers'
];

function vendorController($scope, $uibModal, $routeParams, $q, $location, Vendors, Sellers) {

  //////////////////////////////////////////////////////////////////////
  // Functions
  //////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////////
  // Save
  var _save = function() {
    var data = {
      name: $scope.vendor.vendorName,
      email: $scope.vendor.vendorEmail,
      saasType: $scope.vendor.vendorSaasType,
      dnsZone: $scope.vendor.vendorDnsZone,
      discountRate: $scope.vendor.vendorDiscountRate,
      vendorIsSendInvoice: $scope.vendor.vendorIsSendInvoice,
      sellerId: $scope.seller.sellerId
    }

    var modal = $uibModal.open({
      templateUrl: "views/modal/progress.html",
      backdrop: "static",
      keyboard: false
    });

    var vendorId = null;
    if ( !$scope.isNew ) {
      vendorId = $scope.vendor.vendorId
    }
    Vendors.save(vendorId, data).then(function(result) {
      modal.close();
      if (result.message == undefined) {
        $scope.messages.status = 'success';
        $scope.messages.message = 'ベンダー情報を保存しました。';
        $scope.vendor = result;
        $scope.seller = result.seller;
        $scope.editMode = false;
        if ( $scope.isNew ) {
          $location.path('/vendors/' + result.vendorId);
        }
      } else {
        $scope.messages.status = 'danger';
        $scope.messages.message = result.message;
      }
    });
  };

  //////////////////////////////////////////////////////////////////////
  // Disable
  var _disable = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';

    var modal = $uibModal.open({
      templateUrl: "views/modal/confirm.html",
      backdrop: "static",
      keyboard: false,
      scope: $scope,
      controller: function($uibModalInstance, $scope) {
        $scope.title = "ベンダーの無効化";
        $scope.messageBody = "このベンダーを無効にします。よろしいですか？";
        $scope.okay = function() {
          $uibModalInstance.close();
        };
        $scope.cancel = function() {
          $uibModalInstance.dismiss('cancel');
        };
      }
    });
    modal.result.then(function() {
      // Do destroy
      var modal = $uibModal.open({
        templateUrl: "views/modal/progress.html",
        backdrop: "static",
        keyboard: false
      });

      var data = {
        status: 2
      };

      Vendors.save($scope.vendor.vendorId, data).then(function(result) {
        modal.close();
        if (result.message == undefined) {
          $scope.messages.status = 'success';
          $scope.messages.message = 'ベンダーを無効にしました。';
          $scope.vendor = result;
          $scope.editMode = false;
          _resetAction();
        } else {
          $scope.messages.status = 'danger';
          $scope.messages.message = result.message;
        }
      });
    }, function() {});
  };

  //////////////////////////////////////////////////////////////////////
  // Enable
  var _enable = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';

    var modal = $uibModal.open({
      templateUrl: "views/modal/confirm.html",
      backdrop: "static",
      keyboard: false,
      scope: $scope,
      controller: function($uibModalInstance, $scope) {
        $scope.title = "ベンダーの有効化";
        $scope.messageBody = "このベンダーを有効にします。よろしいですか？";
        $scope.okay = function() {
          $uibModalInstance.close();
        };
        $scope.cancel = function() {
          $uibModalInstance.dismiss('cancel');
        };
      }
    });
    modal.result.then(function() {
      // Do destroy
      var modal = $uibModal.open({
        templateUrl: "views/modal/progress.html",
        backdrop: "static",
        keyboard: false
      });

      var data = {
        status: 1
      };

      Vendors.save($scope.vendor.vendorId, data).then(function(result) {
        modal.close();
        if (result.message == undefined) {
          $scope.messages.status = 'success';
          $scope.messages.message = 'ベンダーを有効にしました。';
          $scope.vendor = result;
          $scope.editMode = false;
          _resetAction();
        } else {
          $scope.messages.status = 'danger';
          $scope.messages.message = result.message;
        }
      });
    }, function() {});
  };

  //////////////////////////////////////////////////////////////////////
  // Delete
  var _delete = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';

    var modal = $uibModal.open({
      templateUrl: "views/modal/confirm.html",
      backdrop: "static",
      keyboard: false,
      scope: $scope,
      controller: function($uibModalInstance, $scope) {
        $scope.title = "ベンダーの削除";
        $scope.messageBody = "このベンダーを削除します。よろしいですか？";
        $scope.okay = function() {
          $uibModalInstance.close();
        };
        $scope.cancel = function() {
          $uibModalInstance.dismiss('cancel');
        };
      }
    });
    modal.result.then(function() {
      // Do destroy
      var modal = $uibModal.open({
        templateUrl: "views/modal/progress.html",
        backdrop: "static",
        keyboard: false
      });

      var data = {
        status: 2
      };

      Vendors.delete($scope.vendor.vendorId).then(function(result) {
        modal.close();
        if (result.message == undefined) {
          $scope.messages.status = 'success';
          $scope.messages.message = 'ベンダーを削除しました。';
          $scope.vendor = result;
          $scope.editMode = false;
          _resetAction();
        } else {
          $scope.messages.status = 'danger';
          $scope.messages.message = result.message;
        }
      });
    }, function() {});
  };

  //////////////////////////////////////////////////////////////////////
  // Change edit mode
  var _editMode = function() {
    $scope.editMode = true;
  };

  //////////////////////////////////////////////////////////////////////
  // Make a new relation for vendor and iaas account
  var _addIaasAccount = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';

    // Open modal
    var modal = $uibModal.open({
      size: 'lg',
      templateUrl: "views/modal/list_iaasaccount.html",
      backdrop: "static",
      scope: $scope,
      controller: 'iaasAccountSelectorController',
      resolve: {
        params: function() {
          return {
            vendorName: $scope.vendor.vendorName,
            iaasAccounts: $scope.vendor.iaasAccounts
          };
        }
      }
    });

    // Result
    modal.result.then(
      function(data) {
        if ( data && data.length > 0 ) {
          var modal = $uibModal.open({
            templateUrl: "views/modal/progress.html",
            backdrop: "static",
            keyboard: false
          });

          var params = {
            iaasAccountIds: data
          };

          Vendors.addIaasAccount($scope.vendor.vendorId, params).then(function(res){
            if (res.message == undefined) {
              $scope.vendor = res;
              $scope.messages.status = 'success';
              $scope.messages.message = "Iaas アカウントを追加しました。";
            }
            else {
              $scope.messages.status = 'danger';
              $scope.messages.message = data.message;
            }
            modal.close();
          });
        }
      },
      function(result) {
        if (result != 'cancel') {
          $scope.messages.status = 'danger';
          $scope.messages.message = result;
        }
      }
    );
  };

  //////////////////////////////////////////////////////////////////////
  // Remove relation with iaas account
  var _removeIaasAccount = function(idx) {
    $scope.messages.status = '';
    $scope.messages.message = '';

    var iaasAccount = $scope.vendor.iaasAccounts[idx];
    var modal = $uibModal.open({
      templateUrl: "views/modal/confirm.html",
      backdrop: "static",
      keyboard: false,
      scope: $scope,
      controller: function($uibModalInstance, $scope) {
        $scope.title = "Iaas アカウントとの関連を削除";
        $scope.messageBody = "このベンダーと" + iaasAccount.iaasAccountName + "の関連を削除します。よろしいですか？";
        $scope.okay = function() {
          $uibModalInstance.close();
        };
        $scope.cancel = function() {
          $uibModalInstance.dismiss('cancel');
        };
      }
    });
    modal.result.then(function() {
      var modal = $uibModal.open({
        templateUrl: "views/modal/progress.html",
        backdrop: "static",
        keyboard: false
      });

      Vendors.removeIaasAccount($scope.vendorId, iaasAccount.iaasAccountId).then(function(data) {
        if (data.message == undefined) {
          $scope.messages.status = 'success';
          $scope.messages.message = iaasAccount.iaasAccountName + "との関連を削除しました。";
          $scope.vendor = data;
        } else {
          $scope.messages.status = 'danger';
          $scope.messages.message = data.message;
        }
        modal.close();
      })
    }, function() {});
  };

  //////////////////////////////////////////////////////////////////////
  // Make a new relation for vendor and iaas account
  var _addProductGroup = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';

    // Open modal
    var modal = $uibModal.open({
      size: 'lg',
      templateUrl: "views/modal/list_product_group.html",
      backdrop: "static",
      scope: $scope,
      controller: 'productGroupSelectorController',
      resolve: {
        params: function() {
          return {
            name: $scope.vendor.vendorName,
            productGroups: $scope.vendor.productGroups
          };
        }
      }
    });

    // Result
    modal.result.then(
      function(data) {
        if ( data && data.length > 0 ) {
          var modal = $uibModal.open({
            templateUrl: "views/modal/progress.html",
            backdrop: "static",
            keyboard: false
          });

          var params = {
            productGroupIds: data
          };

          Vendors.addProductGroup($scope.vendor.vendorId, params).then(function(res){
            if (res.message == undefined) {
              $scope.vendor = res;
              $scope.messages.status = 'success';
              $scope.messages.message = "商品グループを追加しました。";
            }
            else {
              $scope.messages.status = 'danger';
              $scope.messages.message = data.message;
            }
            modal.close();
          });
        }
      },
      function(result) {
        if (result != 'cancel') {
          $scope.messages.status = 'danger';
          $scope.messages.message = result;
        }
      }
    );
  };

  //////////////////////////////////////////////////////////////////////
  // Remove relation with iaas account
  var _removeProductGroup = function(idx) {
    $scope.messages.status = '';
    $scope.messages.message = '';

    var productGroup = $scope.vendor.productGroups[idx];
    var modal = $uibModal.open({
      templateUrl: "views/modal/confirm.html",
      backdrop: "static",
      keyboard: false,
      scope: $scope,
      controller: function($uibModalInstance, $scope) {
        $scope.title = "商品グループとの関連を削除";
        $scope.messageBody = "このベンダーと" + productGroup.productGroupName + "の関連を削除します。よろしいですか？";
        $scope.okay = function() {
          $uibModalInstance.close();
        };
        $scope.cancel = function() {
          $uibModalInstance.dismiss('cancel');
        };
      }
    });
    modal.result.then(function() {
      var modal = $uibModal.open({
        templateUrl: "views/modal/progress.html",
        backdrop: "static",
        keyboard: false
      });

      Vendors.removeProductGroup($scope.vendorId, productGroup.productGroupId).then(function(data) {
        if (data.message == undefined) {
          $scope.messages.status = 'success';
          $scope.messages.message = productGroup.productGroupName + "との関連を削除しました。";
          $scope.vendor = data;
        } else {
          $scope.messages.status = 'danger';
          $scope.messages.message = data.message;
        }
        modal.close();
      })
    }, function() {});
  };

  //////////////////////////////////////////////////////////////////////
  // Make a new relation for vendor and account
  var _addAccount = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';

    // Open modal
    var modal = $uibModal.open({
      size: 'lg',
      templateUrl: "views/modal/list_account.html",
      backdrop: "static",
      scope: $scope,
      controller: 'accountSelectorController',
      resolve: {
        params: function() {
          return {
            vendorName: $scope.vendor.vendorName,
            accounts: $scope.accounts
          };
        }
      }
    });

    // Result
    modal.result.then(
      function(data) {
        if ( data && data.length > 0 ) {
          var modal = $uibModal.open({
            templateUrl: "views/modal/progress.html",
            backdrop: "static",
            keyboard: false
          });

          var params = {
            accountIds: data
          };

          Vendors.addAccount($scope.vendor.vendorId, params).then(function(res){
            if (res.message == undefined) {
              $scope.accounts = res;
              $scope.messages.status = 'success';
              $scope.messages.message = "アカウントを追加しました。";
            }
            else {
              $scope.messages.status = 'danger';
              $scope.messages.message = data.message;
            }
            modal.close();
          });
        }
      },
      function(result) {
        if (result != 'cancel') {
          $scope.messages.status = 'danger';
          $scope.messages.message = result;
        }
      }
    );
  };

  //////////////////////////////////////////////////////////////////////
  // Remove relation with iaas account
  var _removeAccount = function(idx) {
    $scope.messages.status = '';
    $scope.messages.message = '';

    var account = $scope.accounts[idx];
    var modal = $uibModal.open({
      templateUrl: "views/modal/confirm.html",
      backdrop: "static",
      keyboard: false,
      scope: $scope,
      controller: function($uibModalInstance, $scope) {
        $scope.title = "アカウントとの関連を削除";
        $scope.messageBody = "このベンダーと" + account.accountDisplayName + "の関連を削除します。よろしいですか？";
        $scope.okay = function() {
          $uibModalInstance.close();
        };
        $scope.cancel = function() {
          $uibModalInstance.dismiss('cancel');
        };
      }
    });
    modal.result.then(function() {
      var modal = $uibModal.open({
        templateUrl: "views/modal/progress.html",
        backdrop: "static",
        keyboard: false
      });

      Vendors.removeAccount($scope.vendorId, account.accountId).then(function(data) {
        if (data.message == undefined) {
          $scope.messages.status = 'success';
          $scope.messages.message = account.accountName + "との関連を削除しました。";
          $scope.accounts = data;
        } else {
          $scope.messages.status = 'danger';
          $scope.messages.message = data.message;
        }
        modal.close();
      })
    }, function() {});
  };

  //////////////////////////////////////////////////////////////////////
  // Bind functions
  //////////////////////////////////////////////////////////////////////
  $scope.save = _save;
  $scope.edit = _editMode;
  $scope.disable = _disable;
  $scope.enable = _enable;
  $scope.delete = _delete;
  $scope.addIaasAccount = _addIaasAccount;
  $scope.removeIaasAccount = _removeIaasAccount;
  $scope.addProductGroup = _addProductGroup;
  $scope.removeProductGroup = _removeProductGroup;
  $scope.addAccount = _addAccount;
  $scope.removeAccount = _removeAccount;

  //////////////////////////////////////////////////////////////////////
  // Methods
  //////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////////
  // Loading
  var _load = function() {
    if ( !$scope.isNew ) {
        var modal = $uibModal.open({
        templateUrl: "views/modal/progress.html",
        backdrop: "static",
        keyboard: false
      });

      $q.all([
        _loadVendor(),
        _loadAccounts(),
        _loadSeller()
      ])
      .then(function(){
        _resetAction();
        modal.close();
      });
    }
  };

  //////////////////////////////////////////////////////////////////////
  // Loading vendor
  var _loadVendor = function() {
    return Vendors.getVendor($scope.vendorId).then(function(data) {
      if (data.message == undefined) {
        $scope.vendor = data;
        $scope.seller = data.seller;
      } else {
        $scope.messages.status = 'danger';
        $scope.messages.message = result.message;
      }
    });
  };

  //////////////////////////////////////////////////////////////////////
  // Loading seller
  var _loadSeller = function() {
    return Sellers.listAvailable().then(function(data) {
      if (data.message == undefined) {
        $scope.sellers = data;
      } else {
        $scope.messages.status = 'danger';
        $scope.messages.message = result.message;
      }
    });
  };

  //////////////////////////////////////////////////////////////////////
  // Loading accounts
  var _loadAccounts = function() {
    return Vendors.listAccount($scope.vendorId).then(function(data) {
      if (data.message == undefined) {
        $scope.accounts = data;
      } else {
        $scope.messages.status = 'danger';
        $scope.messages.message = result.message;
      }
    });
  };

  //////////////////////////////////////////////////////////////////////
  // Initialization
  var _initialize = function() {
    $scope.messages = {
      status: '',
      message: '',
    };

    $scope.action = null;
    $scope.editMode = false;

    if ($routeParams.id) {
      $scope.vendorId = $routeParams.id;
      $scope.isNew = false;
    }
    else {
      $scope.isNew = true;
      $scope.editMode = true;
      _loadSeller();
    }
  };

  //////////////////////////////////////////////////////////////////////
  // Reset action bar
  var _resetAction = function() {
    $scope.action = null;
    if (!$scope.isNew) {
      if ($scope.vendor.vendorStatus == 1) {
        $scope.action = {};
        $scope.action.canEdit = true;
        $scope.action.canDisable = true;
        $scope.action.canDelete = true;
      } else if ($scope.vendor.vendorStatus == 2) {
        $scope.action = {};
        $scope.action.canEdit = true;
        $scope.action.canEnable = true;
        $scope.action.canDelete = true;
      }
    }
  };

  _initialize();
  _load();
};

module.exports = vendorController;
