'use strict';

Logs.$inject = [
  '$http',
  'API'
];

function Logs( $http, API ) {

  var _pagedAll = function(num, params) {
    var url = API + '/api/logs/pages/' + num;

    return $http({
      method: 'get',
      url: url,
      params: params
    });
  };

  return {
    pagedAll: function(num, params) {
      return _pagedAll(num, params).then(function(res) {
        return res.data;
      }, function(res){
        return res.data;
      })
    }
  };
};

module.exports = Logs;
