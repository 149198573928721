'use strict';

Users.$inject = [
  '$rootScope',
  '$http',
  'API'
];

function Users ( $rootScope, $http, API ) {
  var _getUser = function(userId) {
    var url = API + '/api/users/' + userId;
    return $http({
      method: 'get',
      url: url
    });
  };

  var _save = function(userId, params) {
    var url = API + '/api/users/' +  userId;
    return $http({
      method: 'put',
      url: url,
      data: params
    });
  };

  var _add = function(params) {
    var url = API + '/api/users/';
    return $http({
      method: 'post',
      url: url,
      data: params
    });
  };

  var _changePassword = function(userId, params) {
    var url = API + '/api/users/' + userId + '/changePassword';
    return $http({
      method: 'put',
      url: url,
      data: params
    });
  };

  var _listUser = function(page, params) {
    var url = API + '/api/users/pages/' + page;
    return $http({
      method: 'get',
      url: url,
      params: params
    });
  };

  var _disableUser = function(userId) {
    var url = API + '/api/users/' + userId + '/disable';
    return $http({
      method: 'post',
      url: url
    });
  }

  var _enableUser = function(userId) {
    var url = API + '/api/users/' + userId + '/enable';
    return $http({
      method: 'post',
      url: url
    });
  }

  var _deleteUser = function(userId) {
    var url = API + '/api/users/' + userId;
    return $http({
      method: 'delete',
      url: url
    });
  }

  var _resetPassword = function(userId) {
    var url = API + '/api/users/' + userId + '/resetPassword';
    return $http({
      method: 'post',
      url: url
    });
  }

  var _getInstances = function(userId) {
    var url = API + '/api/users/' + userId + '/instances';
    return $http({
      method: 'get',
      url: url
    });
  }

  var _getVendors = function(userId) {
    var url = API + '/api/users/' + userId + '/vendors';
    return $http({
      method: 'get',
      url: url
    });
  };

  var _getCustomer = function(userId) {
    var url = API + '/api/users/' + userId + '/customer';
    return $http({
      method: 'get',
      url: url
    });
  }

  var _getCustomerPerson = function(userId) {
    var url = API + '/api/users/' + userId + '/customerPerson';
    return $http({
      method: 'get',
      url: url
    });
  }

  var _removeVendor = function(userId, vendorId) {
    var url = API + '/api/users/' + userId + '/vendors/' + vendorId;
    return $http({
      method: 'delete',
      url: url
    });
  };

  var _addVendors = function(userId, params) {
    var url = API + '/api/users/' + userId + '/vendors';
    return $http({
      method: 'post',
      url: url,
      data: params
    });
  };

  var _addInstances = function(userId, params) {
    var url = API + '/api/users/' + userId + '/instances';
    return $http({
      method: 'post',
      url: url,
      data: params
    });
  };

  var _removeInstance　= function(userId, instanceId) {
    var url = API + '/api/users/' + userId + '/instances/' + instanceId;
    return $http({
      method: 'delete',
      url: url
    });
  };

  var _changeCustomer = function(userId, params) {
    var url = API + '/api/users/' + userId + '/customer';
    return $http({
      method: 'post',
      url: url,
      data: params
    });
  };

  return {
    getUser: function(userId) {
      return _getUser(userId).then(function(res){
        return res.data;
      }, function(res){
        return res.data;
      });
    },
    save: function(user_id, params) {
      return _save(user_id, params);
    },
    add: function(params) {
      return _add(params);
    },
    changePassword: function(id, params) {
      return _changePassword(id, params);
    },
    listUser: function(page, params) {
      return _listUser(page, params).then(function(res) {
        return res.data;
      }, function(res){
        return res.data;
      });
    },
    disable: function(id) {
      return _disableUser(id).then(function(res) {
        return res.data;
      }, function(res){
        return res.data;
      });
    },
    enable: function(id) {
      return _enableUser(id).then(function(res) {
        return res.data;
      }, function(res){
        return res.data;
      });
    },
    delete: function(id) {
      return _deleteUser(id).then(function(res) {
        return res.data;
      }, function(res){
        return res.data;
      });
    },
    resetPassword: function(id) {
      return _resetPassword(id).then(function(res) {
        return res.data;
      }, function(res){
        return res.data;
      });
    },
    getInstances: function(id) {
      return _getInstances(id).then(function(res) {
        return res.data;
      }, function(res){
        return res.data;
      });
    },
    getVendors: function(id) {
      return _getVendors(id).then(function(res) {
        return res.data;
      }, function(res){
        return res.data;
      });
    },
    getCustomer: function(id) {
      return _getCustomer(id).then(function(res) {
        return res.data;
      }, function(res){
        return res.data;
      });
    },
    getCustomerPerson: function(id) {
      return _getCustomerPerson(id).then(function(res) {
        return res.data;
      }, function(res){
        return res.data;
      });
    },
    addVendors: function(userId, params) {
      return _addVendors(userId, params).then(function(res){
        return res.data;
      }, function(res){
        return res.data;
      });
    },
    removeVendor: function(userId, vendorId) {
      return _removeVendor(userId, vendorId).then(function(res){
        return res.data;
      }, function(res){
        return res.data;
      });
    },
    addInstances: function(userId, params) {
      return _addInstances(userId, params).then(function(res){
        return res.data;
      }, function(res){
        return res.data;
      });
    },
    removeInstance: function(userId, instanceId) {
      return _removeInstance(userId, instanceId).then(function(res){
        return res.data;
      }, function(res){
        return res.data;
      });
    },
    changeCustomer: function(userId, params) {
      return _changeCustomer(userId, params).then(function(res){
        return res.data;
      }, function(res){
        return res.data;
      });
    }
  };
};

module.exports = Users;
