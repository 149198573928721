'use strict';

iaasAccountController.$inject = [
  '$scope',
  '$uibModal',
  '$routeParams',
  '$q',
  '$location',
  'IaasAccounts'
];

function iaasAccountController($scope, $uibModal, $routeParams, $q, $location, IaasAccounts) {

  //////////////////////////////////////////////////////////////////////
  // Functions
  //////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////////
  // Save
  var _save = function() {
    var data = {
      name: $scope.iaasAccount.iaasAccountName,
      apiKey: $scope.iaasAccount.iaasAccountApiKey,
      secretKey: $scope.iaasAccount.iaasAccountSecretKey,
      zoneName: $scope.iaasAccount.iaasAccountZoneName,
      zoneId: $scope.iaasAccount.iaasAccountZoneId,
      endpoint: $scope.iaasAccount.iaasAccountEndpoint,
      limit: $scope.iaasAccount.iaasAccountLimit,
      autoDetect: $scope.iaasAccount.iaasAccountAutoDetect
    }
    if ( $scope.productGroup ) {
      data.groupId = $scope.productGroup.productGroupId;
    }
    if ( $scope.iaasType ) {
      data.iaasTypeId = $scope.iaasType.iaasTypeId;
    }

    var modal = $uibModal.open({
      templateUrl: "views/modal/progress.html",
      backdrop: "static",
      keyboard: false
    });

    var iaasAccountId = null;
    if ( !$scope.isNew ) {
      iaasAccountId = $scope.iaasAccount.iaasAccountId;
    }
    IaasAccounts.save(iaasAccountId, data).then(function(result) {
      modal.close();
      if (result.message == undefined) {
        $scope.messages.status = 'success';
        $scope.messages.message = 'IaaS アカウントレコードを保存しました。';
        $scope.iaasAccount = result;
        $scope.editMode = false;
        if ( $scope.isNew ) {
          $location.path('/iaasAccounts/' + result.iaasAccountId);
        }
      } else {
        $scope.messages.status = 'danger';
        $scope.messages.message = result.message;
      }
    });
  };

  //////////////////////////////////////////////////////////////////////
  // Disable
  var _disable = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';

    var modal = $uibModal.open({
      templateUrl: "views/modal/confirm.html",
      backdrop: "static",
      keyboard: false,
      scope: $scope,
      controller: function($uibModalInstance, $scope) {
        $scope.title = "IaaS アカウントレコードの無効化";
        $scope.messageBody = "このIaaS アカウントレコードを無効にします。よろしいですか？";
        $scope.okay = function() {
          $uibModalInstance.close();
        };
        $scope.cancel = function() {
          $uibModalInstance.dismiss('cancel');
        };
      }
    });
    modal.result.then(function() {
      // Do destroy
      var modal = $uibModal.open({
        templateUrl: "views/modal/progress.html",
        backdrop: "static",
        keyboard: false
      });

      var data = {
        status: 2
      };

      IaasAccounts.save($scope.iaasAccount.iaasAccountId, data).then(function(result) {
        modal.close();
        if (result.message == undefined) {
          $scope.messages.status = 'success';
          $scope.messages.message = 'IaaS アカウントレコードを無効にしました。';
          $scope.iaasAccount = result;
          $scope.editMode = false;
          _resetAction();
        } else {
          $scope.messages.status = 'danger';
          $scope.messages.message = result.message;
        }
      });
    }, function() {});
  };

  //////////////////////////////////////////////////////////////////////
  // Enable
  var _enable = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';

    var modal = $uibModal.open({
      templateUrl: "views/modal/confirm.html",
      backdrop: "static",
      keyboard: false,
      scope: $scope,
      controller: function($uibModalInstance, $scope) {
        $scope.title = "IaaS アカウントレコードの有効化";
        $scope.messageBody = "このIaaS アカウントレコードを有効にします。よろしいですか？";
        $scope.okay = function() {
          $uibModalInstance.close();
        };
        $scope.cancel = function() {
          $uibModalInstance.dismiss('cancel');
        };
      }
    });
    modal.result.then(function() {
      // Do destroy
      var modal = $uibModal.open({
        templateUrl: "views/modal/progress.html",
        backdrop: "static",
        keyboard: false
      });

      var data = {
        status: 1
      };

      IaasAccounts.save($scope.iaasAccount.iaasAccountId, data).then(function(result) {
        modal.close();
        if (result.message == undefined) {
          $scope.messages.status = 'success';
          $scope.messages.message = 'IaaS アカウントレコードを有効にしました。';
          $scope.iaasAccount = result;
          $scope.editMode = false;
          _resetAction();
        } else {
          $scope.messages.status = 'danger';
          $scope.messages.message = result.message;
        }
      });
    }, function() {});
  };

  //////////////////////////////////////////////////////////////////////
  // Close
  var _close = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';

    var modal = $uibModal.open({
      templateUrl: "views/modal/confirm.html",
      backdrop: "static",
      keyboard: false,
      scope: $scope,
      controller: function($uibModalInstance, $scope) {
        $scope.title = "IaaS アカウントレコードのクローズ";
        $scope.messageBody = "このIaaS アカウントレコードをクローズして新しいインスタンスを作成できなくします。よろしいですか？";
        $scope.okay = function() {
          $uibModalInstance.close();
        };
        $scope.cancel = function() {
          $uibModalInstance.dismiss('cancel');
        };
      }
    });
    modal.result.then(function() {
      // Do destroy
      var modal = $uibModal.open({
        templateUrl: "views/modal/progress.html",
        backdrop: "static",
        keyboard: false
      });

      var data = {
        status: 3
      };

      IaasAccounts.save($scope.iaasAccount.iaasAccountId, data).then(function(result) {
        modal.close();
        if (result.message == undefined) {
          $scope.messages.status = 'success';
          $scope.messages.message = 'IaaS アカウントレコードをクローズにしました。';
          $scope.iaasAccount = result;
          $scope.editMode = false;
          _resetAction();
        } else {
          $scope.messages.status = 'danger';
          $scope.messages.message = result.message;
        }
      });
    }, function() {});
  };

  //////////////////////////////////////////////////////////////////////
  // Delete
  var _delete = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';

    var modal = $uibModal.open({
      templateUrl: "views/modal/confirm.html",
      backdrop: "static",
      keyboard: false,
      scope: $scope,
      controller: function($uibModalInstance, $scope) {
        $scope.title = "IaaS アカウントレコードの削除";
        $scope.messageBody = "この IaaS アカウントレコードを削除します。よろしいですか？";
        $scope.okay = function() {
          $uibModalInstance.close();
        };
        $scope.cancel = function() {
          $uibModalInstance.dismiss('cancel');
        };
      }
    });
    modal.result.then(function() {
      // Do destroy
      var modal = $uibModal.open({
        templateUrl: "views/modal/progress.html",
        backdrop: "static",
        keyboard: false
      });

      IaasAccounts.delete($scope.iaasAccount.iaasAccountId).then(function(result) {
        modal.close();
        if (result.message == undefined) {
          $scope.messages.status = 'success';
          $scope.messages.message = 'IaaS アカウントレコードを削除しました。';
          $scope.iaasAccount = result;
          $scope.editMode = false;
          _resetAction();
        } else {
          $scope.messages.status = 'danger';
          $scope.messages.message = result.message;
        }
      });
    }, function() {});
  };

  //////////////////////////////////////////////////////////////////////
  // Change edit mode
  var _editMode = function() {
    $scope.editMode = true;
  };

  //////////////////////////////////////////////////////////////////////
  // Make a new relation for vendor and iaas account
  var _openProductGroupModal = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';

    // Open modal
    var modal = $uibModal.open({
      size: 'lg',
      templateUrl: "views/modal/list_product_group.html",
      backdrop: "static",
      scope: $scope,
      controller: 'productGroupSelectorController',
      resolve: {
        params: function() {
          return {
            name: $scope.iaasAccount ? $scope.iaasAccount.iaasAccountName : null,
            singular: true,
            object: true
          };
        }
      }
    });

    // Result
    modal.result.then(
      function(data) {
        $scope.productGroup = data[0];
      },
      function(result) {
        if (result != 'cancel') {
          $scope.messages.status = 'danger';
          $scope.messages.message = result;
        }
      }
    );
  };

  //////////////////////////////////////////////////////////////////////
  // Make a new relation for vendor and iaas account
  var _openIaasTypeModal = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';

    // Open modal
    var modal = $uibModal.open({
      size: 'lg',
      templateUrl: "views/modal/list_iaas_type.html",
      backdrop: "static",
      scope: $scope,
      controller: 'iaasTypeSelectorModalController',
      resolve: {
        params: function() {
          return {
            name: $scope.iaasAccount ? $scope.iaasAccount.iaasAccountName : null,
          };
        }
      }
    });

    // Result
    modal.result.then(
      function(data) {
        $scope.iaasType = data;
      },
      function(result) {
        if (result != 'cancel') {
          $scope.messages.status = 'danger';
          $scope.messages.message = result;
        }
      }
    );
  };

  //////////////////////////////////////////////////////////////////////
  // Bind functions
  //////////////////////////////////////////////////////////////////////
  $scope.save = _save;
  $scope.edit = _editMode;
  $scope.disable = _disable;
  $scope.enable = _enable;
  $scope.close = _close;
  $scope.delete = _delete;
  $scope.openProductGroupModal = _openProductGroupModal;
  $scope.openIaasTypeModal = _openIaasTypeModal;

  //////////////////////////////////////////////////////////////////////
  // Methods
  //////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////////
  // Loading
  var _load = function() {
    if ( $scope.isNew ) {
      _resetAction();
    }
    else {
      var modal = $uibModal.open({
        templateUrl: "views/modal/progress.html",
        backdrop: "static",
        keyboard: false
      });

      _loadIaasAccount()
      .then(function(){
        _loadInstanceCount($scope.iaasAccount.iaasAccountId);
      })
      .then(function(){
        _resetAction();
        modal.close();
      });
    }
  };

  //////////////////////////////////////////////////////////////////////
  // Loading
  var _loadIaasAccount = function() {
    return IaasAccounts.getOne($scope.iaasAccountId).then(function(data) {
      if (data.message == undefined) {
        $scope.iaasAccount = data;
        $scope.productGroup = data.productGroup;
        $scope.iaasType = data.iaasType;
      } else {
        $scope.messages.status = 'danger';
        $scope.messages.message = result.message;
      }
    });
  };

  ////////////////////////////////////////////////////////////
  // Load Iaas Account
  var _loadInstanceCount = function(iaasAccountId) {
    return IaasAccounts.getInstanceCount(iaasAccountId).then(function(data){
      $scope.instanceCount = data;
    });
  };

  //////////////////////////////////////////////////////////////////////
  // Initialization
  var _initialize = function() {
    $scope.messages = {
      status: '',
      message: '',
    };
  
    $scope.action = null;
    $scope.editMode = false;
    if ($routeParams.id) {
      $scope.iaasAccountId = $routeParams.id;
      $scope.isNew = false;
    }
    else {
      $scope.isNew = true;
      $scope.editMode = true;
    }
  };

  //////////////////////////////////////////////////////////////////////
  // Reset action bar
  var _resetAction = function() {
    $scope.action = null;
    if ( $scope.iaasAccount) {
      if ( $scope.iaasAccount.iaasAccountStatus == 1 ) {
        $scope.action = {};
        $scope.action.canEdit = true;
        $scope.action.canDisable = true;
        $scope.action.canClose = true;
        $scope.action.canDelete = true;
      }
      else if ( $scope.iaasAccount.iaasAccountStatus == 2 ) {
        $scope.action = {};
        $scope.action.canEnable = true;
        $scope.action.canClose = true;
        $scope.action.canDelete = true;
      }
      else if ( $scope.iaasAccount.iaasAccountStatus == 3 ) {
        $scope.action = {};
        $scope.action.canEnable = true;
        $scope.action.canDelete = true;
      }
    }
  };

  _initialize();
  _load();
};

module.exports = iaasAccountController;