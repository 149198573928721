'use strict';

customerAndPersonSelectorModalController.$inject = [
  '$uibModalInstance',
  '$scope',
  '$uibModal',
  'Customers',
  'CustomerPersons',
  'Vendors'
];

function customerAndPersonSelectorModalController($uibModalInstance, $scope, $uibModal, Customers, CustomerPersons, Vendors) {

  var _loadVendors = function() {
    return Vendors.listVendors().then(function(data){
      if (data.message == undefined) {
        if ( data.length > 0)
          $scope.vendors = data;
      } else {
        $scope.messages.status = 'danger';
        $scope.messages.message = data.message;
      }
    });
  };

  var _listCustomersByVendor = function(page) {
    var modal = $uibModal.open({
      templateUrl: "views/modal/progress.html",
      backdrop: "static",
      keyboard: false
    });
    var param = {
      vendorId: $scope.vendor.vendorId,
      search: $scope.searchFreeWord
    };
    Customers.listCustomers(page, param).then(
      function(data){
        if (data.totalElements > 0) {
          $scope.hideTable = false;
          $scope.totalItems = data.totalElements;
          $scope.customers = data.content;
          $scope.currentPage = data.number + 1
          $scope.itemsPerPage = data.size;
          $scope.maxSize = 7;
        } else {
          $scope.hideTable = true;
        }
        modal.close();
      },
      function(data){
        $uibModalInstance.dismiss(data.message);
        modal.close();
      }
    );
  };

  // Search
  $scope.filterCustomerList = function() {
    $scope.selectedRow = null;
    $scope.customerName = null;
    $scope.customerId = null;
    $scope.hideTable = false;
    $scope.customers = null;
    $scope.customerPersons = null;
    _listCustomersByVendor(1);
  };

  // Page move
  $scope.pageChanged = function() {
    $scope.customer = null;
    $scope.customerPerson = null;
    $scope.selectedRow = null;
    $scope.selectedCustomerPersonRow = null;
    $scope.customers = null;
    $scope.customerPersons = null;
    _listCustomersByVendor($scope.currentPage);
  };

  // Select Row
  $scope.selectRow = function(customer) {
    if ($scope.selectedRow && $scope.selectedRow == customer.customerId) {
      $scope.customerId = null;
      $scope.customerName = null;
      $scope.selectedRow = null;
      $scope.customer = null;
      $scope.customerPerson = null;
      $scope.selectedCustomerPersonRow = null;
      $scope.customerPersons = null;
    } else {
      $scope.customerId = customer.customerId;
      $scope.customerName = customer.customerName;
      $scope.selectedRow = customer.customerId;
      $scope.customer = customer;
      $scope.customerPerson = null;
      $scope.selectedCustomerPersonRow = null;
      $scope.customerPersons = null;
      CustomerPersons.listCustomerPersonList($scope.customerId).then(function(data){
        if (data.message == undefined) {
          if ( data.length > 0)
            $scope.customerPersons = data;
        } else {
          $scope.messages.status = 'danger';
          $scope.messages.message = data.message;
        }
      });
    }
  };

  // Select persn row
  $scope.selectPersonRow = function(customerPerson) {
    if ($scope.selectedCustomerPersonRow && $scope.selectedCustomerPersonRow == customerPerson.customerPersonId) {
      $scope.customerPersonId = null;
      $scope.customerPersonName = null;
      $scope.customerPerson = null;
      $scope.selectedCustomerPersonRow = null;
    } else {
      $scope.customerPersonId = customerPerson.customerPersonId;
      $scope.customerPersonName = customerPerson.customerPersonName;
      $scope.customerPerson = customerPerson;
      $scope.selectedCustomerPersonRow = customerPerson.customerPersonId;
    }
  }

  // OK
  $scope.choose = function() {
    var data = {
      customer: $scope.customer,
      customerPerson: $scope.customerPerson,
    }
    $uibModalInstance.close(data);
  };

  // Cancel
  $scope.cancel = function() {
    $uibModalInstance.dismiss('cancel');
  };

  _loadVendors();
};

module.exports = customerAndPersonSelectorModalController;
