'use strict';

orderController.$inject = [
  '$scope',
  '$uibModal',
  '$routeParams',
  '$window',
  '$filter',
  'CONSOLE_SVR',
  'Orders',
  'MailLog',
  'OptionServices'
];

function orderController($scope, $uibModal, $routeParams, $window, $filter, CONSOLE_SVR, Orders, MailLog, OptionServices) {

  //////////////////////////////////////////////////////////////////////
  // Functions
  //////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////////
  // Initialize customer data
  var _initCustomer = function() {
    $scope.customer = {
      customerId: null,
      customerName: null
    };
  };

  //////////////////////////////////////////////////////////////////////
  // Initialize delivery customer data
  var _initDeliveryCustomer = function() {
    $scope.deliveryCustomer = {
      customerId: null,
      customerName: null,
      selected: false
    };
  };

  //////////////////////////////////////////////////////////////////////
  // Initialize customer person data
  var _initCustomerPerson = function() {
    $scope.customerPerson = {
      customerId: null,
      customerPersonId: null,
      customerPersonName: null,
      customerPersonDepartmen: null,
      customerPersonEmail: null,
      customerPersonTel: null
    };
  };

  //////////////////////////////////////////////////////////////////////
  // Initialize delivery customer person data
  var _initDeliveryCustomerPerson = function() {
    $scope.deliveryCustomerPerson = {
      customerId: null,
      customerPersonId: null,
      customerPersonName: null,
      customerPersonDepartment: null,
      customerPersonEmail: null,
      customerPersonTel: null
    };
  };

  //////////////////////////////////////////////////////////////////////
  // Open Customer Search Modal Window
  var _searchCustomer = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';

    // Open modal
    var modal = $uibModal.open({
      size: 'lg',
      templateUrl: "views/modal/list_customer.html",
      backdrop: "static",
      scope: $scope,
      controller: 'customerSelectorModalController',
      resolve: {
        params: function() {
          return {
            searchFreeWord: $scope.searchCustomerName,
            vendor: $scope.vendor
          };
        }
      }
    });

    // Result
    modal.result.then(
      function(data) {
        $scope.customer.customerId = data.id;
        $scope.customer.customerName = data.name;
        if ($scope.customerPerson && $scope.customerPerson.customerId != data.id) {
          _initCustomerPerson();
        }
      },
      function(result) {
        if (result != 'cancel') {
          $scope.messages.status = 'danger';
          $scope.messages.message = result;
        }
      }
    );
  };

  //////////////////////////////////////////////////////////////////////
  // Open Customer Search Modal Window
  var _searchDeliveryCustomer = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';

    // Open modal
    var modal = $uibModal.open({
      size: 'lg',
      templateUrl: "views/modal/list_customer.html",
      backdrop: "static",
      scope: $scope,
      controller: 'customerSelectorModalController',
      resolve: {
        params: function() {
          return {
            searchFreeWord: $scope.searchDeliveryCustomerName,
            vendor: $scope.vendor
          };
        }
      }
    });

    // Result
    modal.result.then(
      function(data) {
        $scope.deliveryCustomer.customerId = data.id;
        $scope.deliveryCustomer.customerName = data.name;
        $scope.deliveryCustomer.selected = true

        if ($scope.deliveryCustomerPerson.customerId && $scope.deliveryCustomerPerson.customerId != data.id) {
          _initDeliveryCustomerPerson();
        }
      },
      function(result) {
        if (result != 'cancel') {
          $scope.messages.status = 'danger';
          $scope.messages.message = result;
        }
      }
    );
  };

  //////////////////////////////////////////////////////////////////////
  // Open Customer Person Search Modal Window
  var _searchCustomerPerson = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';

    // Open modal
    var modal = $uibModal.open({
      size: 'lg',
      templateUrl: "views/modal/list_customer_person.html",
      backdrop: "static",
      scope: $scope,
      controller: 'customerPersonModalController',
      resolve: {
        params: function() {
          return {
            customerId: $scope.customer.customerId,
            customerName: $scope.customer.customerName
          };
        }
      }
    });

    // Result
    modal.result.then(
      function(data) {
        $scope.customerPerson = data;
      },
      function(result) {
        if (result != 'cancel') {
          $scope.messages.status = 'danger';
          $scope.messages.message = result;
        }
      }
    );
  };

  //////////////////////////////////////////////////////////////////////
  // Open Customer Person Search Modal Window
  var _searchDeliveryCustomerPerson = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';

    // Open modal
    var modal = $uibModal.open({
      size: 'lg',
      templateUrl: "views/modal/list_customer_person.html",
      backdrop: "static",
      scope: $scope,
      controller: 'customerPersonModalController',
      resolve: {
        params: function() {
          return {
            customerId: $scope.deliveryCustomer.customerId,
            customerName: $scope.deliveryCustomer.customerName
          };
        }
      }
    });

    // Result
    modal.result.then(
      function(data) {
        $scope.deliveryCustomerPerson = data;
      },
      function(result) {
        if (result != 'cancel') {
          $scope.messages.status = 'danger';
          $scope.messages.message = result;
        }
      }
    );
  };

  //////////////////////////////////////////////////////////////////////
  // Open Product Search Modal Window
  var _searchProduct = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';

    // Open modal
    var modal = $uibModal.open({
      size: 'lg',
      templateUrl: "views/modal/list_product.html",
      backdrop: "static",
      scope: $scope,
      controller: 'productSearchController',
    });

    // Result
    modal.result.then(
      function(data) {
        $scope.product = data;
      },
      function(result) {
        if (result != 'cancel') {
          $scope.messages.status = 'danger';
          $scope.messages.message = result;
        }
      }
    );
  };

  //////////////////////////////////////////////////////////////////////
  // Open New Customer Modal Window
  var _openCreateNewCustomer = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';

    // Open modal
    var modal = $uibModal.open({
      size: 'lg',
      templateUrl: "views/modal/create_customer.html",
      backdrop: "static",
      scope: $scope,
      controller: 'newCustomerController',
      resolve: {
        params: function() {
          return {
            target: '顧客',
            vendor: $scope.vendor
          };
        }
      }
    });

    // Result
    modal.result.then(
      function(data) {
        $scope.customer.customerId = data.customerId;
        $scope.customer.customerName = data.customerName;
        _initCustomerPerson();
      },
      function(result) {
        if (result != 'cancel') {
          $scope.messages.status = 'danger';
          $scope.messages.message = result;
        }
      }
    );
  };

  //////////////////////////////////////////////////////////////////////
  // Open New Delivery Customer Modal Window
  var _openCreateNewDeliveryCustomer = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';

    // Open modal
    var modal = $uibModal.open({
      size: 'lg',
      templateUrl: "views/modal/create_customer.html",
      backdrop: "static",
      scope: $scope,
      controller: 'newCustomerController',
      resolve: {
        params: function() {
          return {
            target: '提供先（販売店）',
            vendor: $scope.vendor
          };
        }
      }
    });

    // Result
    modal.result.then(
      function(data) {
        $scope.deliveryCustomer.customerId = data.customerId;
        $scope.deliveryCustomer.customerName = data.customerName;
        _initCustomerPerson();
      },
      function(result) {
        if (result != 'cancel') {
          $scope.messages.status = 'danger';
          $scope.messages.message = result;
        }
      }
    );
  };

  //////////////////////////////////////////////////////////////////////
  // Open New Customer Modal Window
  var _openCreateNewCustomerPerson = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';

    // Open modal
    var modal = $uibModal.open({
      size: 'lg',
      templateUrl: "views/modal/create_customer_person.html",
      backdrop: "static",
      scope: $scope,
      controller: 'newCustomerPersonController',
      resolve: {
        params: function() {
          return {
            target: '顧客',
            customerId: $scope.customer.customerId,
            customerName: $scope.customer.customerName
          };
        }
      }
    });

    // Result
    modal.result.then(
      function(data) {
        $scope.customerPerson = data;
      },
      function(result) {
        if (result != 'cancel') {
          $scope.messages.status = 'danger';
          $scope.messages.message = result;
        }
      }
    );
  };

  //////////////////////////////////////////////////////////////////////
  // Open New Customer Modal Window
  var _openCreateNewDeliveryCustomerPerson = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';

    // Open modal
    var modal = $uibModal.open({
      size: 'lg',
      templateUrl: "views/modal/create_customer_person.html",
      backdrop: "static",
      scope: $scope,
      controller: 'newCustomerPersonController',
      resolve: {
        params: function() {
          return {
            target: '提供先（販売店）',
            customerId: $scope.deliveryCustomer.customerId,
            customerName: $scope.deliveryCustomer.customerName
          };
        }
      }
    });

    // Result
    modal.result.then(
      function(data) {
        $scope.deliveryCustomerPerson = data;
      },
      function(result) {
        if (result != 'cancel') {
          $scope.messages.status = 'danger';
          $scope.messages.message = result;
        }
      }
    );
  };

  //////////////////////////////////////////////////////////////////////
  // Save order record
  var _save = function() {
    var data = {
      productId: $scope.product.productId,
      paymentTerm: $scope.paymentTerm,
      customerId: $scope.customer.customerId,
      customerPersonId: $scope.customerPerson.customerPersonId,
      date: orderDate.value,
      orderNumber: $scope.orderNumber,
      pronetDiscount: $scope.pronetDiscount,
      orderNoteForReseller: $scope.orderNoteForReseller,
      transfer: $scope.transfer,
    }
    function _ymd (dt) {
      return $filter('date')(dt, 'yyyy-MM-dd');
    }
    if ($scope.surveyDate) {
      data.surveyDate = _ymd($scope.surveyDate);
    } else {
      data.surveyDate = '';
    }
    if ($scope.transfer && $scope.transferDate) {
      data.transferDate = _ymd($scope.transferDate);
    } else {
      data.transferDate = '';
    }
    if ($scope.orderActivationDate) {
      data.orderActivationDate = _ymd($scope.orderActivationDate);
    } else {
      data.orderActivationDate = '';
    }
    if ($scope.orderBillingDate) {
      data.orderBillingDate = _ymd($scope.orderBillingDate);
    } else {
      data.orderBillingDate = '';
    }
    if ($scope.orderExpiredDate) {
      data.orderExpiredDate = _ymd($scope.orderExpiredDate);
    } else {
      data.orderExpiredDate = '';
    }

    if ($scope.deliveryCustomer.customerId == null) {
      data['deliveryCustomerId'] = null;
      data['deliveryCustomerPersonId'] = null;
    } else {
      data['deliveryCustomerId'] = $scope.deliveryCustomer.customerId;
      data['deliveryCustomerPersonId'] = $scope.deliveryCustomerPerson.customerPersonId
    }
    if ($scope.contactCustomer == null) {
      data['contactCustomer'] = 1;
    } else {
      data['contactCustomer'] = $scope.contactCustomer;
    }

    if ($scope.canEditPaymentMethod) {
      data['paymentMethod'] = $scope.paymentMethod;
    }

    var modal = $uibModal.open({
      templateUrl: "views/modal/progress.html",
      backdrop: "static",
      keyboard: false
    });

    Orders.save($scope.orderId, data).then(function(result) {
      modal.close();
      if (result.message == undefined) {
        $scope.messages.status = 'success';
        if ($scope.editMode) {
          $scope.messages.message = '注文を保存しました。';
        } else {
          $scope.messages.message = '注文を受け付けました。(注文番号:' + result.orderCode + ')';
          _initialize();
        }
        $scope.editMode = false;
      } else {
        $scope.messages.status = 'danger';
        $scope.messages.message = result.message;
      }
    })
  };

  //////////////////////////////////////////////////////////////////////
  // Delete order
  var _delete = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';

    var modal = $uibModal.open({
      templateUrl: "views/modal/confirm.html",
      backdrop: "static",
      keyboard: false,
      scope: $scope,
      controller: function($uibModalInstance, $scope) {
        $scope.title = "注文の削除";
        $scope.messageBody = "この注文を削除します。よろしいですか？";
        $scope.okay = function() {
          $uibModalInstance.close();
        };
        $scope.cancel = function() {
          $uibModalInstance.dismiss('cancel');
        };
      }
    });
    modal.result.then(function() {
      // Do destroy
      var modal = $uibModal.open({
        templateUrl: "views/modal/progress.html",
        backdrop: "static",
        keyboard: false
      });

      Orders.deleteOrder($scope.orderId).then(function(data) {
        modal.close();
        if (data.message == undefined) {
          $scope.messages.status = 'success';
          $scope.messages.message = "注文を削除しました。";
          _expandData(data);
          _resetAction(data);
        } else {
          $scope.messages.status = 'danger';
          $scope.messages.message = data.message;
        }
      })
    }, function() {});
  };

  //////////////////////////////////////////////////////////////////////
  // Cancel order
  var _cancellation = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';

    var modal = $uibModal.open({
      templateUrl: "views/modal/confirm.html",
      backdrop: "static",
      keyboard: false,
      scope: $scope,
      controller: function($uibModalInstance, $scope) {
        $scope.title = "注文の解約";
        $scope.messageBody = "この注文を解約します。よろしいですか？";
        $scope.okay = function() {
          $uibModalInstance.close();
        };
        $scope.cancel = function() {
          $uibModalInstance.dismiss('cancel');
        };
      }
    });
    modal.result.then(function() {
      // Open modal
      var modal = $uibModal.open({
        size: 'lg',
        templateUrl: "views/modal/cancellation.html",
        backdrop: "static",
        scope: $scope,
        controller: 'cancellationController',
        resolve: {
          params: function() {
            return {
              orderId: $scope.orderId,
              orderCode: $scope.orderCode,
              orderNumber: $scope.orderNumber
            };
          }
        }
      });

      // Result
      modal.result.then(
        function(data) {
          $scope.messages.status = 'success';
          $scope.messages.message = "注文を解約しました。";
          _expandData(data);
          _resetAction(data);
        },
        function(result) {
          if (result != 'cancel') {
            $scope.messages.status = 'danger';
            $scope.messages.message = result;
          }
        }
      );

    }, function() {});
  };

  //////////////////////////////////////////////////////////////////////
  // Send start-of-use mail
  var _sendStartOfUseMail = function() {
    var modal = $uibModal.open({
      templateUrl: "views/modal/confirm.html",
      backdrop: "static",
      keyboard: false,
      scope: $scope,
      controller: function($uibModalInstance, $scope) {
        $scope.title = "利用開始メールの送信";
        $scope.messageBody = "利用開始メールを送信します。よろしいですか？";
        $scope.okay = function() {
          $uibModalInstance.close();
        };
        $scope.cancel = function() {
          $uibModalInstance.dismiss('cancel');
        };
      }
    });
    modal.result.then(function() {
      var modal = $uibModal.open({
        templateUrl: "views/modal/progress.html",
        backdrop: "static",
        keyboard: false
      });

      Orders.sendStartOfUseMail($scope.orderId).then(function(result) {
        modal.close();
        if (result.message == undefined) {
          $scope.messages.status = 'success';
          $scope.messages.message = "利用開始メールを送信しました。";
          $scope.mailLog = result;
        } else {
          $scope.messages.status = 'danger';
          $scope.messages.message = result.message;
        }
      })
    }, function() {});
  };

  //////////////////////////////////////////////////////////////////////
  // Open Maillog
  var _openStartOfUseMailLog = function() {
    var modal = $uibModal.open({
      size: 'lg',
      templateUrl: "views/modal/mail_detail.html",
      backdrop: "static",
      scope: $scope,
      controller : function($uibModalInstance ,$scope){
        $scope.mailLog = $scope.mailLog;
        $scope.close = function() {
          $uibModalInstance.close();
        };
      }
    });
  }

  //////////////////////////////////////////////////////////////////////
  // Change edit mode
  var _editMode = function() {
    $scope.editMode = true;
  };

  //////////////////////////////////////////////////////////////////////
  // Reactivate order
  var _reactivation = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';

    var modal = $uibModal.open({
      templateUrl: "views/modal/confirm.html",
      backdrop: "static",
      keyboard: false,
      scope: $scope,
      controller : function($uibModalInstance, $scope){
        $scope.title = "解約の取り消し";
        $scope.messageBody = "この注文の解約を取り消して本契約に戻します。よろしいですか？";
        $scope.okay = function() {
          $uibModalInstance.close();
        };
        $scope.cancel = function() {
          $uibModalInstance.dismiss('cancel');
        };
      }
    });

    modal.result.then(function () {
      // Do destroy
      var modal = $uibModal.open({
        templateUrl: "views/modal/progress.html",
        backdrop: "static",
        keyboard: false
      });

      Orders.reactivateOrder($scope.orderId).then(function(data){
        modal.close();
        if (data.message == undefined) {
          $scope.messages.status = 'success';
          $scope.messages.message = "解約を取り消しました。";
          _expandData(data);
          _resetAction(data);
        }
        else {
          $scope.messages.status = 'danger';
          $scope.messages.message = data.message;
        }
      })
    }, function () {
    });
  };

  //////////////////////////////////////////////////////////////////////
  // Open order list modal
  var _selectOrder = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';

    // Open modal
    var modal = $uibModal.open({
      size: 'lg',
      templateUrl: "views/modal/select_exchange_order.html",
      backdrop: "static",
      scope: $scope,
      controller: 'exchangeInstanceModalController',
      resolve: {
        params: function() {
          return {
            orderId: $routeParams.id
          };
        }
      }
    });

    // Result
    modal.result.then(
      function(data) {
        $scope.messages.status = 'success';
        $scope.messages.message = 'インスタンスを交換しました。';
        _expandData(data);
        _resetAction(data);
    },
      function(result) {
        if (result != 'cancel') {
          $scope.messages.status = 'danger';
          $scope.messages.message = result;
        }
      }
    );
  };

  //////////////////////////////////////////////////////////////////////
  // Change seller type
  var _exchangeVendor = function(type, vendor) {
    $scope.messages.status = '';
    $scope.messages.message = '';

    // Open modal
    var modal = $uibModal.open({
      size: 'lg',
      templateUrl: "views/modal/exchange_vendor.html",
      backdrop: "static",
      scope: $scope,
      controller: 'excahngeVendorModalController',
      resolve: {
        params: function() {
          return {
            orderId: $routeParams.id
          };
        }
      }
    });

    // Result
    modal.result.then(
      function(data) {
        $scope.messages.status = 'success';
        $scope.messages.message = '契約形態を変更しました。';
        _expandData(data);
        _resetAction(data);
    },
      function(result) {
        if (result != 'cancel') {
          $scope.messages.status = 'danger';
          $scope.messages.message = result;
        }
      }
    );
  };

  //////////////////////////////////////////////////////////////////////
  // Change status to production
  var _changeToProduction = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';

    var modal = $uibModal.open({
      templateUrl: "views/modal/confirm.html",
      backdrop: "static",
      keyboard: false,
      scope: $scope,
      controller : function($uibModalInstance, $scope){
        $scope.title = "本契約への切り替え";
        $scope.messageBody = "本契約に切り替えます。よろしいですか？";
        $scope.okay = function() {
          $uibModalInstance.close();
        };
        $scope.cancel = function() {
          $uibModalInstance.dismiss('cancel');
        };
      }
    });
    modal.result.then(function () {
      // Do destroy
      var modal = $uibModal.open({
        templateUrl: "views/modal/progress.html",
        backdrop: "static",
        keyboard: false
      });

      Orders.changeToProduction($scope.orderId).then(function(data){
        modal.close();
        if (data.message == undefined) {
          $scope.messages.status = 'success';
          $scope.messages.message = "本契約に切り替えました。";
          _expandData(data);
          _resetAction(data);
        }
        else {
          $scope.messages.status = 'danger';
          $scope.messages.message = data.message;
        }
      })
    }, function () {
    });
  };

  //////////////////////////////////////////////////////////////////////
  // Change cloud option status
  var _updateOptionState = function(index, val) {
    $scope.messages.status = '';
    $scope.messages.message = '';

    var modal = $uibModal.open({
      templateUrl: "views/modal/confirm.html",
      backdrop: "static",
      keyboard: false,
      scope: $scope,
      controller : function($uibModalInstance, $scope){
        $scope.title = "オプションの有効・無効";
        $scope.messageBody = "オプションの有効・無効を切り替えます。よろしいですか？";
        $scope.okay = function() {
          $uibModalInstance.close();
        };
        $scope.cancel = function() {
          $uibModalInstance.dismiss('cancel');
        };
      }
    });
    modal.result.then(function () {
      var modal = $uibModal.open({
        templateUrl: "views/modal/progress.html",
        backdrop: "static",
        keyboard: false
      });

      var optionService = $scope.optionServices[index];
      OptionServices.updateOptionState($scope.orderId, optionService.optionServiceId, val).then(function(data){
        modal.close();
        if (data.message == undefined) {
          $scope.messages.status = 'success';
          $scope.messages.message = "オプションの有効・無効を切り替えました。";
          $scope.optionServices[index] = data;
        }
        else {
          $scope.messages.status = 'danger';
          $scope.messages.message = data.message;
        }
      })
    }, function () {
    });
  };

  //////////////////////////////////////////////////////////////////////
  // Add a new cloud option
  var _addOption = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';

    // Open modal
    var modal = $uibModal.open({
      size: 'lg',
      templateUrl: "views/modal/add_option.html",
      backdrop: "static",
      controller : 'addOptionModalController',
      resolve: {
        params: function() {
          return {
            orderId: $scope.orderId
          };
        }
      }
    });

    modal.result.then(
      function (data) {
        // Saved
        if (angular.isArray($scope.optionServices)) {
          $scope.optionServices.push(data);
        }
        else {
          $scope.optionServices = [ data ];
        }
        $scope.messages.status = 'success';
        $scope.messages.message = "オプションを追加しました。";
      },
      function (result) {
        if ( result != 'cancel') {
          $scope.messages.status = 'danger';
          $scope.messages.message = result;
        }
      }
    );
  };

  //////////////////////////////////////////////////////////////////////
  // Link to create instance screen.
  var _createInstance = function() {
    var modal = $uibModal.open({
      templateUrl: "views/modal/confirm.html",
      backdrop: "static",
      keyboard: false,
      scope: $scope,
      controller : function($uibModalInstance, $scope){
        $scope.title = "新しいインスタンスの起動";
        $scope.messageBody = "新しいインスタンスを起動しますか？";
        $scope.okay = function() {
          $uibModalInstance.close();
        };
        $scope.cancel = function() {
          $uibModalInstance.dismiss('cancel');
        };
      }
    });
    modal.result.then(function () {
      $window.open(CONSOLE_SVR + '/#/instances/new?orderId=' + $scope.orderId, '_blank');
    }, function () {
    });
  };

  //////////////////////////////////////////////////////////////////////
  // Bind functions
  //////////////////////////////////////////////////////////////////////
  $scope.searchCustomer = _searchCustomer;
  $scope.searchDeliveryCustomer = _searchDeliveryCustomer;
  $scope.clearCustomer = _initCustomer;
  $scope.clearDeliveryCustomer = _initDeliveryCustomer;
  $scope.searchCustomerPerson = _searchCustomerPerson;
  $scope.clearCustomerPerson = _initCustomerPerson;
  $scope.searchDeliveryCustomerPerson = _searchDeliveryCustomerPerson;
  $scope.clearDeliveryCustomerPerson = _initDeliveryCustomerPerson;
  $scope.searchProduct = _searchProduct;
  $scope.openCreateNewCustomer = _openCreateNewCustomer;
  $scope.openCreateNewDeliveryCustomer = _openCreateNewDeliveryCustomer;
  $scope.openCreateNewCustomerPerson = _openCreateNewCustomerPerson;
  $scope.openCreateNewDeliveryCustomerPerson = _openCreateNewDeliveryCustomerPerson;
  $scope.save = _save;
  $scope.edit = _editMode;
  $scope.delete = _delete;
  $scope.cancellation = _cancellation;
  $scope.sendStartOfUseMail = _sendStartOfUseMail;
  $scope.openStartOfUseMailLog = _openStartOfUseMailLog;
  $scope.reactivation = _reactivation;
  $scope.selectOrder = _selectOrder;
  $scope.exchangeVendor = _exchangeVendor;
  $scope.production = _changeToProduction;
  $scope.updateOptionState = _updateOptionState;
  $scope.addOption = _addOption;
  $scope.createInstance = _createInstance;

  //////////////////////////////////////////////////////////////////////
  // Methods
  //////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////////
  // Loading order
  var _loadOrder = function() {
    var modal = $uibModal.open({
      templateUrl: "views/modal/progress.html",
      backdrop: "static",
      keyboard: false
    });
    $scope.orderId = $routeParams.id;
    Orders.getOrder($routeParams.id).then(function(data) {
      modal.close();
      if (data.message == undefined) {
        _expandData(data);
        _resetAction(data);
        _loadMailLog(data.orderId);
      } else {
        $scope.messages.status = 'danger';
        $scope.messages.message = result.message;
      }
    });
  };

  //////////////////////////////////////////////////////////////////////
  // Expand order data to scope
  var _expandData = function(data) {
    $scope.product = data.product;
    $scope.orderDate = new Date(data.orderDate);
    $scope.orderNumber = data.orderNumber;
    $scope.orderCode = data.orderCode;
    $scope.orderStatus = data.orderStatus;
    $scope.sellerName = data.seller && data.seller.sellerName || '';
    $scope.orderNoteForMaker = data.orderNoteForMaker;
    $scope.orderNoteForReseller = data.orderNoteForReseller;
    $scope.instances = data.instances;
    $scope.licenses = data.licenses;
    $scope.optionServices = data.optionServices;
    $scope.vendor = data.vendor;

    if (data.orderActivationDate) {
      $scope.orderActivationDate = new Date(data.orderActivationDate);
    } else {
      $scope.orderActivationDate = '';
    }

    if (data.orderBillingDate) {
      $scope.orderBillingDate = new Date(data.orderBillingDate);
    } else {
      $scope.orderBillingDate = '';
    }
    if (data.orderExpiredDate) {
      $scope.orderExpiredDate = new Date(data.orderExpiredDate);
    } else {
      $scope.orderExpiredDate = '';
    }

    $scope.customer = data.customer;
    if ($scope.customer == null)
      _initCustomer();
    else
      $scope.customer.selected = true

    $scope.deliveryCustomer = data.deliveryCustomer;
    if ($scope.deliveryCustomer == null)
      _initDeliveryCustomer();
    else
      $scope.deliveryCustomer.selected = true

    $scope.customerPerson = data.customerPerson;
    if ($scope.customerPerson == null)
      _initCustomerPerson();

    $scope.deliveryCustomerPerson = data.deliveryCustomerPerson;
    if ($scope.deliveryCustomerPerson == null)
      _initDeliveryCustomerPerson();

    $scope.pronetDiscount = data.orderPronetDiscount;
    if (data.orderPronetDiscount == 1)
      $scope.orderPronetDiscount = 'Pronet 割引を適用する';

    $scope.paymentTerm = data.orderPaymentTerm;
    if (data.orderPaymentTerm == 0)
      $scope.orderPaymentTerm = '不明';
    else if (data.orderPaymentTerm == 1)
      $scope.orderPaymentTerm = '月額';
    else if (data.orderPaymentTerm == 2)
      $scope.orderPaymentTerm = '3ヶ月';
    else if (data.orderPaymentTerm == 3)
      $scope.orderPaymentTerm = '年額';
    else if (data.orderPaymentTerm == 4)
      $scope.orderPaymentTerm = '無償';

    $scope.canEditPaymentMethod = false;
    if (data.seller && data.seller.sellerId == 1)
      $scope.canEditPaymentMethod = true;

    if ( data.orderPaymentMethod == 0)
      $scope.orderPaymentMethod = '不明';
    else if ( data.orderPaymentMethod == 1)
      $scope.orderPaymentMethod = 'SBT';
    else if ( data.orderPaymentMethod == 2)
      $scope.orderPaymentMethod = 'SBCS';
    else if ( data.orderPaymentMethod == 3)
      $scope.orderPaymentMethod = '請求書';
    else if ( data.orderPaymentMethod == 4)
      $scope.orderPaymentMethod = 'MF KESSAI';
    else if ( data.orderPaymentMethod == 5)
      $scope.orderPaymentMethod = 'STRIPE';
    else if ( data.orderPaymentMethod == 6)
      $scope.orderPaymentMethod = 'プライベート';
    else if ( data.orderPaymentMethod == 7)
      $scope.orderPaymentMethod = 'MTP トライアル';
    $scope.paymentMethod = data.orderPaymentMethod;

    $scope.contactCustomer = data.contactCustomerType + '';
    if (data.contactCustomerType == 0) {
      $scope.orderDeliveryCotactCustomer = '主たる連絡先とする';
      $scope.orderCotantCustomer = '';
    } else {
      $scope.orderCotantCustomer = '主たる連絡先とする';
      $scope.orderDeliveryCotactCustomer = '';
    }

    if (data.survey) {
      $scope.survey = 1;
      $scope.surveyDate = new Date(data.survey.surveyDate);
    }
    if (data.transfer) {
      $scope.transfer = 1;
      $scope.isTransfer = 'サイト移行サービスを利用する'

      if ( data.transfer.transferDate ) {
        $scope.transferDate = new Date(data.transfer.transferDate);
      }
    }
  };

  //////////////////////////////////////////////////////////////////////
  // Data Initialization
  var _initialize = function() {
    $scope.orderDate = new Date();
    $scope.contactCustomer = 1;
    $scope.product = null;
    $scope.orderNumber = null;
    $scope.paymentTerm = null;
    $scope.pronetDiscount = null;
    $scope.mailLog = null;

    _initCustomer();
    _initDeliveryCustomer();
    _initCustomerPerson();
    _initDeliveryCustomerPerson();

  };

  //////////////////////////////////////////////////////////////////////
  // Reset action bar
  var _resetAction = function(data) {
    $scope.action = null;
    if (data.orderStatus == 1) { // 本契約
      $scope.action = {};
      $scope.action.canEdit = true;
      if ( data.orderExpiredDate == null ) {
        $scope.action.canCancel = true;
      } else {
        $scope.action.canCancel = false;
        $scope.action.canReactivate = true;
      }
      $scope.action.canSendStartOfUseMail = false;
      $scope.action.canExchangeInstance = true;
      angular.forEach(data.instances, function(instance, idx) {
        if (instance.instanceStatus == 1)
          $scope.action.canSendStartOfUseMail = true;
      })
      if ( data.seller && ( data.seller.sellerId == 1 || data.seller.sellerId == 3 || data.seller.sellerId == 4 ) ) {
        $scope.action.canExchangeOrder = true;
      }

      $scope.isProduction = true;
    } else if (data.orderStatus == 2) { // 仮契約
      $scope.action = {};
      $scope.action.canEdit = true;
      $scope.action.canDelete = true;
      $scope.action.canDoProduction = true;
      $scope.isProduction = false;
    } else if (data.orderStatus == 3) { // 解約済み
      $scope.action = {};
      $scope.action.canReactivate = true;
    } else if (data.orderStatus == 4) { // 解約予定
      $scope.action = {};
      $scope.action.canEdit = true;
      $scope.action.canReactivate = true;
    }
  };

  //////////////////////////////////////////////////////////////////////
  // Load Maillog
  var _loadMailLog = function(id) {
    MailLog.listMailLogByOrder(id).then(function(result){
      if (result.message == undefined) {
        if (result.length > 0) {
          $scope.mailLog = result[result.length - 1];
        }
      } else {
        $scope.messages.status = 'danger';
        $scope.messages.message = result.message;
      }
    });
  };

  //////////////////////////////////////////////////////////////////////
  // Initialization
  //////////////////////////////////////////////////////////////////////
  $scope.messages = {
    status: '',
    message: '',
  };
  $scope.terms = [{
      label: '月額',
      value: 1
    },
    {
      label: '年額',
      value: 3
    }
  ];
  $scope.paymentMethods = [
    { label: "請求書", value: 3},
    { label: "MF KESSAI", value: 4},
    { label: "STRIPE", value: 5},
    { label: "無償（検証含む）", value: 8},
  ];

  $scope.action = null;

  $scope.editMode = false;
  _initialize();

  if ($routeParams.id)
    _loadOrder();
};

module.exports = orderController;