'use strict';

productController.$inject = [
  '$scope',
  '$uibModal',
  '$routeParams',
  '$q',
  '$location',
  'Products'
];

function productController($scope, $uibModal, $routeParams, $q, $location, Products) {

  //////////////////////////////////////////////////////////////////////
  // Functions
  //////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////////
  // Save
  var _save = function() {

    var modal = $uibModal.open({
      templateUrl: "views/modal/progress.html",
      backdrop: "static",
      keyboard: false
    });

    var productId = null;
    if ( !$scope.isNew ) {
      productId = $scope.product.productId;
    }

    var data = {
      name: $scope.product.productName,
      price: $scope.product.productPrice,
      tax: $scope.product.productTax,
      isFlat: 1,
      salesFlag: $scope.product.productSalesFlag,
      publicFlag: $scope.product.productPublicFlag,
      cpus: $scope.product.productMetaDataCpus,
      memory: $scope.product.productMetaDataMemory,
      disk: $scope.product.productMetaDataDisk,
      server: $scope.product.productMetaDataWebserver,
      salesStartedOn: _format_ts( $scope.productSalesStartedOn, 'YYYY-MM-DD HH:mm:00' ),
      cloudTypeId: $scope.cloudType.cloudTypeId,
      version: $scope.product.productVersion,
      productGroupId: $scope.productGroup.productGroupId
    };

    if ( $scope.productSalesEndedOn) {
      data.salesEndedOn = _format_ts( $scope.productSalesEndedOn, 'YYYY-MM-DD HH:mm:00' );
    }

    Products.save(productId, data).then(function(result) {
      modal.close();
      if (result.message == undefined) {
        if ( $scope.isNew ) {
          $location.path('/products/' + result.productId);
        }
        else {
          $scope.messages.status = 'success';
          $scope.messages.message = '商品レコードを保存しました。';
            $scope.product = result;
          _expandData();
          $scope.editMode = false;
        }
      } else {
        $scope.messages.status = 'danger';
        $scope.messages.message = result.message;
      }
    });
  };

  //////////////////////////////////////////////////////////////////////
  // Disable
  var _disable = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';

    var modal = $uibModal.open({
      templateUrl: "views/modal/confirm.html",
      backdrop: "static",
      keyboard: false,
      scope: $scope,
      controller: function($uibModalInstance, $scope) {
        $scope.title = "商品レコードの無効化";
        $scope.messageBody = "この商品レコードを無効にします。よろしいですか？";
        $scope.okay = function() {
          $uibModalInstance.close();
        };
        $scope.cancel = function() {
          $uibModalInstance.dismiss('cancel');
        };
      }
    });
    modal.result.then(function() {
      // Do destroy
      var modal = $uibModal.open({
        templateUrl: "views/modal/progress.html",
        backdrop: "static",
        keyboard: false
      });

      var data = {
        status: 2
      };

      Products.save($scope.product.productId, data).then(function(result) {
        modal.close();
        if (result.message == undefined) {
          $scope.messages.status = 'success';
          $scope.messages.message = '商品レコードを無効にしました。';
          $scope.product = result;
          _expandData();
          $scope.editMode = false;
          _resetAction();
        } else {
          $scope.messages.status = 'danger';
          $scope.messages.message = result.message;
        }
      });
    }, function() {});
  };

  //////////////////////////////////////////////////////////////////////
  // Enable
  var _enable = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';

    var modal = $uibModal.open({
      templateUrl: "views/modal/confirm.html",
      backdrop: "static",
      keyboard: false,
      scope: $scope,
      controller: function($uibModalInstance, $scope) {
        $scope.title = "商品レコードの有効化";
        $scope.messageBody = "この商品レコードを有効にします。よろしいですか？";
        $scope.okay = function() {
          $uibModalInstance.close();
        };
        $scope.cancel = function() {
          $uibModalInstance.dismiss('cancel');
        };
      }
    });
    modal.result.then(function() {
      // Do destroy
      var modal = $uibModal.open({
        templateUrl: "views/modal/progress.html",
        backdrop: "static",
        keyboard: false
      });

      var data = {
        status: 1
      };

      Products.save($scope.product.productId, data).then(function(result) {
        modal.close();
        if (result.message == undefined) {
          $scope.messages.status = 'success';
          $scope.messages.message = '商品レコードを有効にしました。';
          $scope.product = result;
          _expandData();
          $scope.editMode = false;
          _resetAction();
        } else {
          $scope.messages.status = 'danger';
          $scope.messages.message = result.message;
        }
      });
    }, function() {});
  };

  //////////////////////////////////////////////////////////////////////
  // Delete
  var _delete = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';

    var modal = $uibModal.open({
      templateUrl: "views/modal/confirm.html",
      backdrop: "static",
      keyboard: false,
      scope: $scope,
      controller: function($uibModalInstance, $scope) {
        $scope.title = "商品レコードの削除";
        $scope.messageBody = "この商品レコードを削除します。よろしいですか？";
        $scope.okay = function() {
          $uibModalInstance.close();
        };
        $scope.cancel = function() {
          $uibModalInstance.dismiss('cancel');
        };
      }
    });
    modal.result.then(function() {
      // Do destroy
      var modal = $uibModal.open({
        templateUrl: "views/modal/progress.html",
        backdrop: "static",
        keyboard: false
      });

      Products.delete($scope.product.productId).then(function(result) {
        modal.close();
        if (result.message == undefined) {
          $scope.messages.status = 'success';
          $scope.messages.message = '商品レコードを削除しました。';
          $scope.product = result;
          _expandData();
          $scope.editMode = false;
          _resetAction();
        } else {
          $scope.messages.status = 'danger';
          $scope.messages.message = result.message;
        }
      });
    }, function() {});
  };

  //////////////////////////////////////////////////////////////////////
  // Change edit mode
  var _editMode = function() {
    $scope.editMode = true;
  };

  //////////////////////////////////////////////////////////////////////
  // Open date picker 
  var _openSalesStartedOnPicker = function() {
    _openDatePicker($scope.productSalesStartedOnPicker);
  }
  var _openSalesEndedOnPicker = function() {
    _openDatePicker($scope.productSalesEndedOnPicker);
  }

  //////////////////////////////////////////////////////////////////////
  // Open modal for select cloud type 
  var _openCloudTypeModal = function() {
    // Open modal
    var modal = $uibModal.open({
      size: 'lg',
      templateUrl: "views/modal/list_cloud_type.html",
      backdrop: "static",
      scope: $scope,
      controller: 'cloudTypeSelectorModalController',
    });

    // Result
    modal.result.then(
      function(data) {
        $scope.cloudType = data;
      },
      function(result) {
        if (result != 'cancel') {
          $scope.messages.status = 'danger';
          $scope.messages.message = result;
        }
      }
    );
  }

  //////////////////////////////////////////////////////////////////////
  // Make a new relation for vendor and iaas account
  var _openProductGroupModal = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';

    // Open modal
    var modal = $uibModal.open({
      size: 'lg',
      templateUrl: "views/modal/list_product_group.html",
      backdrop: "static",
      scope: $scope,
      controller: 'productGroupSelectorController',
      resolve: {
        params: function() {
          return {
            name: $scope.product ? $scope.product.productName : null,
            singular: true,
            object: true
          };
        }
      }
    });

    // Result
    modal.result.then(
      function(data) {
        $scope.productGroup = data[0];
      },
      function(result) {
        if (result != 'cancel') {
          $scope.messages.status = 'danger';
          $scope.messages.message = result;
        }
      }
    );
  };

  //////////////////////////////////////////////////////////////////////
  // Bind functions
  //////////////////////////////////////////////////////////////////////
  $scope.save = _save;
  $scope.edit = _editMode;
  $scope.disable = _disable;
  $scope.enable = _enable;
  $scope.delete = _delete;
  $scope.openSalesStartedOnPicker = _openSalesStartedOnPicker;
  $scope.openSalesEndedOnPicker = _openSalesEndedOnPicker;
  $scope.openCloudTypeModal = _openCloudTypeModal;
  $scope.openProductGroupModal = _openProductGroupModal;

  //////////////////////////////////////////////////////////////////////
  // Methods
  //////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////////
  // Open date picker 
  var _openDatePicker = function(fld) {
    fld.opened = true;
  }

  //////////////////////////////////////////////////////////////////////
  // Date to string
  var _format_ts = function(date, format) {
    var year = date.getFullYear();
    var month = ('0' + (date.getMonth() + 1)).slice(-2);
    var day = ('0' + (date.getDate())).slice(-2)
    var hour = ('0' + (date.getHours())).slice(-2)
    var min = ('0' + (date.getMinutes())).slice(-2)
    var sec = ('0' + (date.getSeconds())).slice(-2)

    var res;
    res = format.replace(/YYYY/g, year);
    res = res.replace(/MM/g, month);
    res = res.replace(/DD/g, day);
    res = res.replace(/HH/g, hour);
    res = res.replace(/mm/g, min);
    res = res.replace(/ss/g, sec);

    return res;
  }

  //////////////////////////////////////////////////////////////////////
  // Loading
  var _load = function() {
    if ( $scope.isNew ) {
      _resetAction();
    }
    else {
      var modal = $uibModal.open({
        templateUrl: "views/modal/progress.html",
        backdrop: "static",
        keyboard: false
      });

      $q.all([
        _loadProduct(),
      ])
      .then(function(){
        _resetAction();
        modal.close();
      });
    }
  };

  //////////////////////////////////////////////////////////////////////
  // Loading
  var _loadProduct = function() {
    return Products.load($scope.productId).then(function(data) {
      if (data.message == undefined) {
        $scope.product = data;
        _expandData();
      } else {
        $scope.messages.status = 'danger';
        $scope.messages.message = data.message;
      }
    });
  };

  //////////////////////////////////////////////////////////////////////
  // StartedOn and EndedOn should be Date object
  var _expandData = function() {
    $scope.productSalesStartedOn = new Date($scope.product.productSalesStartedOn);
    if ( $scope.product.productSalesEndedOn ) {
      $scope.productSalesEndedOn = new Date($scope.product.productSalesEndedOn);
    }
    else {
      $scope.productSalesEndedOn = new Date();
    }
    $scope.cloudType = $scope.product.cloudType;
    $scope.productGroup = $scope.product.productGroup;
  };

  //////////////////////////////////////////////////////////////////////
  // Initialization
  var _initialize = function() {
    $scope.messages = {
      status: '',
      message: '',
    };
  
    $scope.action = null;
    $scope.editMode = false;
    if ($routeParams.id) {
      $scope.productId = $routeParams.id;
      $scope.isNew = false;
    }
    else {
      $scope.isNew = true;
      $scope.editMode = true;
    }

    $scope.productSalesStartedOnPicker = {
      opened: false
    }
    $scope.productSalesEndedOnPicker = {
      opened: false
    }

  };

  //////////////////////////////////////////////////////////////////////
  // Reset action bar
  var _resetAction = function() {
    if ( !$scope.isNew ) {
      if ($scope.product.productStatus == 1) {
        $scope.action = {};
        $scope.action.canAdd = true;
        $scope.action.canEdit = true;
        $scope.action.canDisable = true;
        $scope.action.canDelete = true;
      } else if ($scope.product.productStatus == 2) {
        $scope.action = {};
        $scope.action.canAdd = true;
        $scope.action.canEdit = true;
        $scope.action.canEnable = true;
        $scope.action.canDelete = true;
      }
    }
  };

  _initialize();
  _load();
};

module.exports = productController;