'use strict';

cloudOptionController.$inject = [
  '$scope',
  '$uibModal',
  '$routeParams',
  '$location',
  'CloudOptions'
];

function cloudOptionController($scope, $uibModal, $routeParams, $location, CloudOptions) {

  //////////////////////////////////////////////////////////////////////
  // Functions
  //////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////////
  // Save
  var _save = function() {
    var data = {
      cloudOptionName: $scope.cloudOption.cloudOptionName,
      cloudOptionStartDate: _format_ts( $scope.cloudOptionStartDate, 'YYYY-MM-DD HH:mm:00' ),
    }
    if ( $scope.cloudOptionEndDate) {
      data.cloudOptionEndDate = _format_ts( $scope.cloudOptionEndDate, 'YYYY-MM-DD HH:mm:00' );
    }

    var modal = $uibModal.open({
      templateUrl: "views/modal/progress.html",
      backdrop: "static",
      keyboard: false
    });

    var cloudOptionId = null;
    if ( !$scope.isNew ) {
      cloudOptionId = $scope.cloudOption.cloudOptionId;
    }
    CloudOptions.save(cloudOptionId, data).then(function(result) {
      modal.close();
      if (result.message == undefined) {
        $scope.messages.status = 'success';
        $scope.messages.message = 'クラウドオプションレコードを保存しました。';
        $scope.cloudOption = result;
        $scope.editMode = false;
        if ( $scope.isNew ) {
          $location.path('/cloudOptions/' + result.cloudOptionId);
        }
      } else {
        $scope.messages.status = 'danger';
        $scope.messages.message = result.message;
      }
    });
  };

  //////////////////////////////////////////////////////////////////////
  // Disable
  var _disable = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';

    var modal = $uibModal.open({
      templateUrl: "views/modal/confirm.html",
      backdrop: "static",
      keyboard: false,
      scope: $scope,
      controller: function($uibModalInstance, $scope) {
        $scope.title = "クラウドオプションレコードの無効化";
        $scope.messageBody = "このクラウドオプションレコードを無効にします。よろしいですか？";
        $scope.okay = function() {
          $uibModalInstance.close();
        };
        $scope.cancel = function() {
          $uibModalInstance.dismiss('cancel');
        };
      }
    });
    modal.result.then(function() {
      // Do destroy
      var modal = $uibModal.open({
        templateUrl: "views/modal/progress.html",
        backdrop: "static",
        keyboard: false
      });

      var data = {
        cloudOptionStatus: 2
      };

      CloudOptions.save($scope.cloudOption.cloudOptionId, data).then(function(result) {
        modal.close();
        if (result.message == undefined) {
          $scope.messages.status = 'success';
          $scope.messages.message = 'クラウドオプションレコードを無効にしました。';
          $scope.cloudOption = result;
          $scope.editMode = false;
          _resetAction();
        } else {
          $scope.messages.status = 'danger';
          $scope.messages.message = result.message;
        }
      });
    }, function() {});
  };

  //////////////////////////////////////////////////////////////////////
  // Enable
  var _enable = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';

    var modal = $uibModal.open({
      templateUrl: "views/modal/confirm.html",
      backdrop: "static",
      keyboard: false,
      scope: $scope,
      controller: function($uibModalInstance, $scope) {
        $scope.title = "クラウドオプションレコードの有効化";
        $scope.messageBody = "このクラウドオプションレコードを有効にします。よろしいですか？";
        $scope.okay = function() {
          $uibModalInstance.close();
        };
        $scope.cancel = function() {
          $uibModalInstance.dismiss('cancel');
        };
      }
    });
    modal.result.then(function() {
      // Do destroy
      var modal = $uibModal.open({
        templateUrl: "views/modal/progress.html",
        backdrop: "static",
        keyboard: false
      });

      var data = {
        cloudOptionStatus: 1
      };

      CloudOptions.save($scope.cloudOption.cloudOptionId, data).then(function(result) {
        modal.close();
        if (result.message == undefined) {
          $scope.messages.status = 'success';
          $scope.messages.message = 'クラウドオプションレコードを有効にしました。';
          $scope.cloudOption = result;
          $scope.editMode = false;
          _resetAction();
        } else {
          $scope.messages.status = 'danger';
          $scope.messages.message = result.message;
        }
      });
    }, function() {});
  };

  //////////////////////////////////////////////////////////////////////
  // Delete
  var _delete = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';

    var modal = $uibModal.open({
      templateUrl: "views/modal/confirm.html",
      backdrop: "static",
      keyboard: false,
      scope: $scope,
      controller: function($uibModalInstance, $scope) {
        $scope.title = "クラウドオプションレコードの削除";
        $scope.messageBody = "この クラウドオプションレコードを削除します。よろしいですか？";
        $scope.okay = function() {
          $uibModalInstance.close();
        };
        $scope.cancel = function() {
          $uibModalInstance.dismiss('cancel');
        };
      }
    });
    modal.result.then(function() {
      // Do destroy
      var modal = $uibModal.open({
        templateUrl: "views/modal/progress.html",
        backdrop: "static",
        keyboard: false
      });

      CloudOptions.delete($scope.cloudOption.cloudOptionId).then(function(result) {
        modal.close();
        if (result.message == undefined) {
          $scope.messages.status = 'success';
          $scope.messages.message = 'クラウドオプションレコードを削除しました。';
          $scope.cloudOption = result;
          $scope.editMode = false;
          _resetAction();
        } else {
          $scope.messages.status = 'danger';
          $scope.messages.message = result.message;
        }
      });
    }, function() {});
  };

  //////////////////////////////////////////////////////////////////////
  // Change edit mode
  var _editMode = function() {
    $scope.editMode = true;
  };

  //////////////////////////////////////////////////////////////////////
  // Open date picker 
  var _openStartDatePicker = function() {
    _openDatePicker($scope.cloudOptionStartDatePicker);
  }
  var _openEndDatePicker = function() {
    _openDatePicker($scope.cloudOptionEndDatePicker);
  }

  //////////////////////////////////////////////////////////////////////
  // Bind functions
  //////////////////////////////////////////////////////////////////////
  $scope.save = _save;
  $scope.edit = _editMode;
  $scope.disable = _disable;
  $scope.enable = _enable;
  $scope.delete = _delete;
  $scope.openStartDatePicker = _openStartDatePicker;
  $scope.openEndDatePicker = _openEndDatePicker;

  //////////////////////////////////////////////////////////////////////
  // Methods
  //////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////////
  // StartedOn and EndedOn should be Date object
  var _expandData = function() {
    $scope.cloudOptionStartDate = new Date($scope.cloudOption.cloudOptionStartDate);
    if ( $scope.cloudOption.cloudOptionEndDate ) {
      $scope.cloudOptionEndDate = new Date($scope.cloudOption.cloudOptionEndDate);
    }
    else {
      if ( $scope.isNew ) {
        $scope.cloudOptionEndDate = new Date();
      }
    }
  };

  //////////////////////////////////////////////////////////////////////
  // Open date picker 
  var _openDatePicker = function(fld) {
    fld.opened = true;
  }

  //////////////////////////////////////////////////////////////////////
  // Date to string
  var _format_ts = function(date, format) {
    var year = date.getFullYear();
    var month = ('0' + (date.getMonth() + 1)).slice(-2);
    var day = ('0' + (date.getDate())).slice(-2)
    var hour = ('0' + (date.getHours())).slice(-2)
    var min = ('0' + (date.getMinutes())).slice(-2)
    var sec = ('0' + (date.getSeconds())).slice(-2)

    var res;
    res = format.replace(/YYYY/g, year);
    res = res.replace(/MM/g, month);
    res = res.replace(/DD/g, day);
    res = res.replace(/HH/g, hour);
    res = res.replace(/mm/g, min);
    res = res.replace(/ss/g, sec);

    return res;
  }

  //////////////////////////////////////////////////////////////////////
  // Loading
  var _load = function() {
    if ( $scope.isNew ) {
      _resetAction();
    }
    else {
      var modal = $uibModal.open({
        templateUrl: "views/modal/progress.html",
        backdrop: "static",
        keyboard: false
      });

      _loadCloudOption()
      .then(function(){
        _resetAction();
        modal.close();
      });
    }
  };

  //////////////////////////////////////////////////////////////////////
  // Loading
  var _loadCloudOption = function() {
    return CloudOptions.load($scope.cloudOptionId).then(function(data) {
      if (data.message == undefined) {
        $scope.cloudOption = data;
        _expandData();
      } else {
        $scope.messages.status = 'danger';
        $scope.messages.message = result.message;
      }
    });
  };

  //////////////////////////////////////////////////////////////////////
  // Initialization
  var _initialize = function() {
    $scope.messages = {
      status: '',
      message: '',
    };
  
    $scope.action = null;
    $scope.editMode = false;
    if ($routeParams.id) {
      $scope.cloudOptionId = $routeParams.id;
      $scope.isNew = false;
    }
    else {
      $scope.isNew = true;
      $scope.editMode = true;
    }

    $scope.cloudOptionStartDatePicker = {
      opened: false
    }
    $scope.cloudOptionEndDatePicker = {
      opened: false
    }
  };

  //////////////////////////////////////////////////////////////////////
  // Reset action bar
  var _resetAction = function() {
    $scope.action = null;
    if ( $scope.cloudOption) {
      if ( $scope.cloudOption.cloudOptionStatus == 1 ) {
        $scope.action = {};
        $scope.action.canEdit = true;
        $scope.action.canDisable = true;
        $scope.action.canDelete = true;
      }
      else if ( $scope.cloudOption.cloudOptionStatus == 2 ) {
        $scope.action = {};
        $scope.action.canEnable = true;
        $scope.action.canDelete = true;
      }
    }
  };

  _initialize();
  _load();
};

module.exports = cloudOptionController;