'use strict';

OptionServices.$inject = [
  '$http',
  'API'
];

function OptionServices ( $http, API ) {
  var _save = function(id, params) {
    var url = API + '/api/orders/' + id + '/optionServices/';
    return $http({
      method: 'post',
      url: url,
      data: params
    });
  };

  var _updateOptionState = function(id, optionId, val) {
    var url = API + '/api/orders/' + id + '/optionServices/' + optionId + '/changeStatus';
    return $http({
      method: 'post',
      url: url,
      data: {
        newStatus: val
      }
    });
  };

  return {
    save: function(id, params) {
      return _save(id, params).then(function(res){
        return res.data;
      }, function(res){
        return res.data;
      });
    },
    updateOptionState: function(id, optionId, val) {
      return _updateOptionState(id, optionId, val).then(function(res){
        return res.data;
      }, function(res){
        return res.data;
      });
    }
  };
};

module.exports = OptionServices;
