'use strict'

Orders.$inject = [
  '$rootScope',
  '$http',
  'API'
];

function Orders( $rootScope, $http, API ) {
  var _loadOrderList = function(page, params) {
    var url = API + '/api/orders/pages/' + page;
    return $http({
      method: 'get',
      url: url,
      params: params
    });
  };

  var _save = function(orderId, params) {
    var url = API + '/api/orders/';
    var method = 'post'
    if ( orderId ) {
      url += orderId + '/';
      method = 'put'
    }
    return $http({
      method: method,
      url: url,
      data: params
    });
  };

  var _getOrder = function(orderId){
    var url = API + '/api/orders/' + orderId;
    return $http.get(url);
  };

  var _deleteOrder = function(orderId){
    var url = API + '/api/orders/' + orderId;
    return $http({
      method: 'delete',
      url: url
    });
  };

  var _cancelOrder = function(orderId, expiredDate){
    var url = API + '/api/orders/' + orderId + '/cancel';
    var param = {
      expiredDate: expiredDate
    };
    return $http({
      method: 'post',
      url: url,
      data: param
    });
  };

  var _sendStartOfUseMail = function(orderId) {
    var url = API + '/api/orders/' + orderId + '/sendStartMail';
    return $http({
      method: 'post',
      url: url
    });
  };

  var _reactivateOrder = function(orderId){
    var url = API + '/api/orders/' + orderId + '/reactivate';
    return $http({
      method: 'post',
      url: url
    });
  };

  var _listOrdersByCustomer = function(customerId) {
    var url = API + '/api/orders/';
    return $http({
      method: 'get',
      url: url,
      params: {
        customerId: customerId
      }
    });
  };

  var _exchangeInstance = function(srcOrderId, destOrderId) {
    var url = API + '/api/admin/orders/' + srcOrderId + '/exchange';
    return $http({
      method: 'post',
      url: url,
      data: {
        destOrderId: destOrderId
      }
    });
  };

  var _exchangeVendor = function(srcOrderId, data) {
    var url = API + '/api/admin/orders/' + srcOrderId + '/exchangeVendor';
    return $http({
      method: 'post',
      url: url,
      data: data
    });
  };

  var _changeToProduction = function(orderId) {
    var url = API + '/api/orders/' + orderId + '/production';
    return $http({
      method: 'post',
      url: url
    });
  };

  return {
    listOrder: function(page, params) {
      return _loadOrderList(page, params).then(function(res) {
        return res.data;
      }, function(res){
        return res.data;
      });
    },
    listOrdersByCustomer: function( customerId ) {
      return _listOrdersByCustomer( customerId ).then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      );
    },
    save: function(orderId, params) {
      return _save(orderId, params).then(function(res){
        return res.data;
      }, function(res){
        return res.data;
      });
    },
    getOrder: function(orderId) {
      return _getOrder(orderId).then(function(res){
        return res.data;
      }, function(res){
        return res.data;
      })
    },
    deleteOrder: function(orderId) {
      return _deleteOrder(orderId).then(function(res){
        return res.data;
      }, function(res){
        return res.data;
      })
    },
    cancelOrder: function(orderId, expiredDate) {
      return _cancelOrder(orderId, expiredDate).then(function(res){
        return res.data;
      }, function(res){
        return res.data;
      })
    },
    sendStartOfUseMail: function(orderId) {
      return _sendStartOfUseMail(orderId).then(function(res){
        return res.data;
      }, function(res){
        return res.data;
      })
    },
    reactivateOrder: function(orderId) {
      return _reactivateOrder(orderId).then(function(res){
        return res.data;
      }, function(res){
        return res.data;
      })
    },
    exchangeInstance: function(src, dest) {
      return _exchangeInstance(src, dest).then(function(res){
        return res.data;
      }, function(res){
        return res.data;
      })
    },
    exchangeVendor: function(src, data) {
      return _exchangeVendor(src, data).then(function(res){
        return res.data;
      }, function(res){
        return res.data;
      })
    },
    changeToProduction: function(orderId) {
      return _changeToProduction(orderId).then(function(res){
        return res.data;
      }, function(res){
        return res.data;
      });
    }
  };
};

module.exports = Orders;
