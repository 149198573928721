'use strict';

excahngeVendorModalController.$inject = [
  '$uibModalInstance',
  '$scope',
  '$uibModal',
  'Orders',
  'params'
];

function excahngeVendorModalController($uibModalInstance, $scope, $uibModal, Orders, params) {

  ////////////////////////////////////////////////////////////
  // Functions
  ////////////////////////////////////////////////////////////
  // OK
  var _save = function() {

    var modal = $uibModal.open({
      templateUrl: "views/modal/progress.html",
      backdrop: "static",
      keyboard: false
    });

    var data = {
      sellerId: $scope.seller.value,
      vendorId: $scope.seller.vendorId
    };
    if ($scope.paymentMethod) {
      data.paymentMethod = $scope.paymentMethod.value
    }

    Orders.exchangeVendor($scope.orderId, data).then(
      function(result){
        modal.close();
        if (result.message == undefined) {
          $uibModalInstance.close(result);
        } else {
          $uibModalInstance.dismiss(result.message);
        }
      },
      function(result){
        $uibModalInstance.dismiss(result.message);
      }
    );

    modal.close();
  };

  // Cancel
  var _cancel = function() {
    $uibModalInstance.dismiss('cancel');
  };

  ////////////////////////////////////////////////////////////
  // Function Mappings
  ////////////////////////////////////////////////////////////
  $scope.save = _save;
  $scope.cancel = _cancel;

  ////////////////////////////////////////////////////////////
  // Methods
  ////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////
  // Initialize
  ////////////////////////////////////////////////////////////
  $scope.orderId = params.orderId;
  $scope.seller = null;
  $scope.paymentMethod = null;

  $scope.messages = {
    status: '',
    message: '',
  };

  $scope.sellers = [
    { label: '直販', value: 1, vendorId: 2 },
    { label: 'SBT', value: 3, vendorId: 14 },
    { label: 'SBCS', value: 4, vendorId: 15 },
  ];

  $scope.paymentMethods = [
    { label: "請求書", value: 3},
    { label: "MF KESSAI", value: 4},
    { label: "STRIPE", value: 5},
    { label: "無償（検証含む）", value: 8},
  ];

};

module.exports = excahngeVendorModalController;
