'use strict';

ProductGroups.$inject = [
  '$http',
  'API'
];

function ProductGroups( $http, API ) {
  var _pagedAll = function(num) {
    var url = API + '/api/productGroups/pages/' + num;
    return $http({
      method: 'get',
      url: url
    });
  };

  var _listAll = function() {
    var url = API + '/api/productGroups/';
    return $http({
      method: 'get',
      url: url
    });
  };

  var _load = function(id) {
    var url = API + '/api/productGroups/' + id;
    return $http({
      method: 'get',
      url: url
    });
  };

  var _save = function(id, data) {
    var url = API + '/api/productGroups/';
    var verb = 'post';
    if (id) {
      url += id;
      verb = 'put';
    }
    return $http({
      method: verb,
      url: url,
      data: data
    });
  };

  var _delete = function(id) {
    var url = API + '/api/productGroups/' + id;
    return $http({
      method: 'delete',
      url: url
    });
  };

  return {
    pagedAll: function(num) {
      return _pagedAll(num).then(function(res) {
        return res.data;
      }, function(res){
        return res.data;
      })
    },
    listAll: function() {
      return _listAll().then(function(res) {
        return res.data;
      }, function(res){
        return res.data;
      })
    },
    load: function(id) {
      return _load(id).then(function(res) {
        return res.data;
      }, function(res){
        return res.data;
      })
    },
    save: function(id, data) {
      return _save(id, data).then(function(res) {
        return res.data;
      }, function(res){
        return res.data;
      })
    },
    delete: function(id) {
      return _delete(id).then(function(res) {
        return res.data;
      }, function(res){
        return res.data;
      })
    }
  };
};

module.exports = ProductGroups;
