'use strict';

News.$inject = [
  '$rootScope',
  '$http',
  'API'
];
function News( $rootScope, $http, API ) {
  var _listAvailableNews = function() {
    var url = API + '/api/news/?via=manage';
    return $http({
      method: 'get',
      url: url
    });
  };

  var _pagedAll = function(page, params) {
    var url = API + '/api/news/pages/' + page;
    return $http({
      method: 'get',
      url: url,
      params: params
    });
  };

  var _getNews = function(id) {
    var url = API + '/api/news/' + id;
    return $http({
      method: 'get',
      url: url
    });
  };

  var _save = function(id, data) {
    var url = API + '/api/news/';
    var verb = 'post';
    if ( id ) {
      url += id;
      verb = 'put';
    }

    return $http({
      method: verb,
      url: url,
      data: data
    });
  };

  var _delete = function(id) {
    var url = API + '/api/news/' + id;

    return $http({
      method: 'delete',
      url: url
    });
  };

  return {
    listAvailableNews: function() {
      return _listAvailableNews().then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      )
    },
    pagedAll: function(page, params) {
      return _pagedAll(page, params).then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      )
    },
    getNews: function(id) {
      return _getNews(id).then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      )
    },
    save: function(id, data) {
      return _save(id, data).then(function(res) {
        return res.data;
      }, function(res){
        return res.data;
      })
    },
    delete: function(id) {
      return _delete(id).then(function(res) {
        return res.data;
      }, function(res){
        return res.data;
      })
    }
  }
};

module.exports = News;
