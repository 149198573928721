'use strict';

customerSelectorModalController.$inject = [
  '$uibModalInstance',
  '$scope',
  '$uibModal',
  'Customers',
  'params'
];

function customerSelectorModalController($uibModalInstance, $scope, $uibModal, Customers, params) {

  var _listCustomersByVendor = function(page, searchFreeWord) {
    var modal = $uibModal.open({
      templateUrl: "views/modal/progress.html",
      backdrop: "static",
      keyboard: false
    });
    var param = {
      vendorId: params.vendor.vendorId,
      search: searchFreeWord
    };
    Customers.listCustomers(page, param).then(
      function(data){
        if (data.totalElements > 0) {
          $scope.hideTable = false;
          $scope.totalItems = data.totalElements;
          $scope.customers = data.content;
          $scope.currentPage = data.number + 1
          $scope.itemsPerPage = data.size;
          $scope.maxSize = 7;
        } else {
          $scope.hideTable = true;
        }
        modal.close();
      },
      function(data){
        $uibModalInstance.dismiss(data.message);
        modal.close();
      }
    );
  };

  $scope.searchFreeWord = params.searchFreeWord;

  _listCustomersByVendor(1, params.searchFreeWord);

  // Search
  $scope.filterCustomerList = function() {
    $scope.selectedRow = null;
    $scope.customerName = null;
    $scope.customerId = null;
    $scope.hideTable = false;
    _listCustomersByVendor(1, $scope.searchFreeWord);
  };

  // Page move
  $scope.pageChanged = function() {
    _listCustomersByVendor($scope.currentPage, $scope.searchFreeWord);
  };

  // Select Row
  $scope.selectRow = function(id, name) {
    if ($scope.selectedRow && $scope.selectedRow == id) {
      $scope.customerId = null;
      $scope.customerName = null;
      $scope.selectedRow = null;
    } else {
      $scope.customerId = id;
      $scope.customerName = name;
      $scope.selectedRow = id;
    }
  };

  // OK
  $scope.choose = function() {
    var data = {
      id: $scope.customerId,
      name: $scope.customerName
    }
    $uibModalInstance.close(data);
  };

  // Cancel
  $scope.cancel = function() {
    $uibModalInstance.dismiss('cancel');
  };
};

module.exports = customerSelectorModalController;
