'use strict';

Products.$inject = [
  '$rootScope',
  '$http',
  'API'
];

function Products( $rootScope, $http, API ) {
  var _loadProducts = function(version, webServer) {
    var url = API + '/api/products/available';
    var opt = new Array();
    if ( version != undefined ) {
      opt.push('version=' + version);
    }
    if ( webServer != undefined ) {
      opt.push('webServer=' + webServer);
    }
    if ( opt.length ) {
      url += '?' + opt.join('&');
    }

    return $http.get(url);
  };

  var _pagedAll = function(num, params) {
    var url = API + '/api/products/pages/' + num;

    return $http({
      method: 'get',
      url: url,
      params: params
    });
  };

  var _load = function(id) {
    var url = API + '/api/products/' + id;

    return $http({
      method: 'get',
      url: url
    });
  };

  var _save = function(id, data) {
    var url = API + '/api/products/';
    var verb = 'post';
    if ( id ) {
      url += id;
      verb = 'put';
    }

    return $http({
      method: verb,
      url: url,
      data: data
    });
  };

  var _delete = function(id) {
    var url = API + '/api/products/' + id;

    return $http({
      method: 'delete',
      url: url
    });
  };

  return {
    listProducts: function(version, webServer) {
      return _loadProducts(version, webServer).then(function(res) {
        return res.data;
      }, function(res){
        return res.data;
      })
    },
    pagedAll: function(num, params) {
      return _pagedAll(num, params).then(function(res) {
        return res.data;
      }, function(res){
        return res.data;
      })
    },
    load: function(id) {
      return _load(id).then(function(res) {
        return res.data;
      }, function(res){
        return res.data;
      })
    },
    save: function(id, data) {
      return _save(id, data).then(function(res) {
        return res.data;
      }, function(res){
        return res.data;
      })
    },
    delete: function(id) {
      return _delete(id).then(function(res) {
        return res.data;
      }, function(res){
        return res.data;
      })
    }
  };
};

module.exports = Products;
