'use strict';

iaasTypeController.$inject = [
  '$scope',
  '$uibModal',
  '$routeParams',
  '$q',
  '$location',
  'IaasTypes'
];

function iaasTypeController($scope, $uibModal, $routeParams, $q, $location, IaasTypes) {

  //////////////////////////////////////////////////////////////////////
  // Functions
  //////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////////
  // Save
  var _save = function() {
    var data = {
      name: $scope.iaasType.iaasTypeName,
      apiType: $scope.iaasType.iaasTypeApiType
    }

    var modal = $uibModal.open({
      templateUrl: "views/modal/progress.html",
      backdrop: "static",
      keyboard: false
    });

    var iaasTypeId = null;
    if ( !$scope.isNew ) {
      iaasTypeId = $scope.iaasType.iaasTypeId;
    }
    IaasTypes.save(iaasTypeId, data).then(function(result) {
      modal.close();
      if (result.message == undefined) {
        $scope.messages.status = 'success';
        $scope.messages.message = 'IaaS タイプレコードを保存しました。';
        $scope.iaasType = result;
        $scope.editMode = false;
        if ( $scope.isNew ) {
          $location.path('/iaasTypes/' + result.iaasTypeId);
        }
      } else {
        $scope.messages.status = 'danger';
        $scope.messages.message = result.message;
      }
    });
  };

  //////////////////////////////////////////////////////////////////////
  // Delete
  var _delete = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';

    var modal = $uibModal.open({
      templateUrl: "views/modal/confirm.html",
      backdrop: "static",
      keyboard: false,
      scope: $scope,
      controller: function($uibModalInstance, $scope) {
        $scope.title = "IaaS タイプレコードの削除";
        $scope.messageBody = "このIaaS タイプレコードを削除します。よろしいですか？";
        $scope.okay = function() {
          $uibModalInstance.close();
        };
        $scope.cancel = function() {
          $uibModalInstance.dismiss('cancel');
        };
      }
    });
    modal.result.then(function() {
      // Do destroy
      var modal = $uibModal.open({
        templateUrl: "views/modal/progress.html",
        backdrop: "static",
        keyboard: false
      });

      IaasTypes.delete($scope.iaasType.iaasTypeId).then(function(result) {
        modal.close();
        if (result.message == undefined) {
          $scope.messages.status = 'success';
          $scope.messages.message = 'IaaS タイプレコードを削除しました。';
          $scope.iaasType = result;
          $scope.editMode = false;
          _resetAction();
        } else {
          $scope.messages.status = 'danger';
          $scope.messages.message = result.message;
        }
      });
    }, function() {});
  };

  //////////////////////////////////////////////////////////////////////
  // Change edit mode
  var _editMode = function() {
    $scope.editMode = true;
  };

  //////////////////////////////////////////////////////////////////////
  // Bind functions
  //////////////////////////////////////////////////////////////////////
  $scope.save = _save;
  $scope.edit = _editMode;
  $scope.delete = _delete;

  //////////////////////////////////////////////////////////////////////
  // Methods
  //////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////////
  // Loading
  var _load = function() {
    if ( $scope.isNew ) {
      _resetAction();
    }
    else {
      var modal = $uibModal.open({
        templateUrl: "views/modal/progress.html",
        backdrop: "static",
        keyboard: false
      });

      $q.all([
        _loadIaasType(),
      ])
      .then(function(){
        _resetAction();
        modal.close();
      });
    }
  };

  //////////////////////////////////////////////////////////////////////
  // Loading
  var _loadIaasType = function() {
    return IaasTypes.load($scope.iaasTypeId).then(function(data) {
      if (data.message == undefined) {
        $scope.iaasType = data;
      } else {
        $scope.messages.status = 'danger';
        $scope.messages.message = result.message;
      }
    });
  };

  //////////////////////////////////////////////////////////////////////
  // Initialization
  var _initialize = function() {
    $scope.messages = {
      status: '',
      message: '',
    };
  
    $scope.action = null;
    $scope.editMode = false;
    if ($routeParams.id) {
      $scope.iaasTypeId = $routeParams.id;
      $scope.isNew = false;
    }
    else {
      $scope.isNew = true;
      $scope.editMode = true;
    }
  };

  //////////////////////////////////////////////////////////////////////
  // Reset action bar
  var _resetAction = function() {
    $scope.action = null;
    if ( $scope.iaasType && !$scope.iaasType.iaasTypeDeletedOn) {
      $scope.action = {};
      $scope.action.canEdit = true;
      $scope.action.canDelete = true;
    }
  };

  _initialize();
  _load();
};

module.exports = iaasTypeController;