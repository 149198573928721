'use strict';

Templates.$inject = [
  '$http',
  'API'
]

function Templates( $http, API ) {
  var _listTemplates = function( pageNum, params ) {
    var url = API + '/api/templates/pages/' + pageNum;
    return $http({
      method: 'get',
      url: url,
      params: params
    });
  };

  var _getTemplate = function(id) {
    var url = API + '/api/templates/' + id;
    return $http({
      method: 'get',
      url: url
    });
  };

  var _save = function(id, params) {
    var url = API + '/api/templates/' + id;
    return $http({
      method: 'put',
      url: url,
      data: params
    });
  };

  var _delete = function(id) {
    var url = API + '/api/templates/' + id;
    return $http({
      method: 'delete',
      url: url
    });
  };

  return {
    save: function(id, params) {
      return _save(id, params).then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      );
    },
    delete: function(id) {
      return _delete(id).then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      );
    },
    listTemplates: function(pageNum, params) {
      return _listTemplates(pageNum, params).then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      );
    },
    getTemplate: function(id) {
      return _getTemplate(id).then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      );
    }
  };
};

module.exports = Templates;
