'use strict';

newAccountController.$inject = [
  '$scope',
  '$uibModal',
  '$location',
  'Users'
];

function newAccountController($scope, $uibModal, $location, Users) {

  //////////////////////////////////////////////////////////////////////
  // Functions
  //////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////////
  // Save accoumt record
  var _save = function() {
    var data = {
      displayName: $scope.accountDisplayName,
      email: $scope.accountEmail,
      username: $scope.accountUsername,
      password: $scope.accountPassword,
      privileges: $scope.roles,
      status: 1
    };

    var modal = $uibModal.open({
      templateUrl: "views/modal/progress.html",
      backdrop: "static",
      keyboard: false
    });

    Users.add(data).then(
      function(result) {
        modal.close();
        if (result.message == undefined) {
          $scope.messages.status = 'success';
          $scope.messages.message = '新しいアカウント情報を登録しました。';
          $location.url('/accounts/' + result.data.accountId);
        } else {
          $scope.messages.status = 'danger';
          $scope.messages.message = result.message;
        }
      },
      function(result){
        $scope.messages.status = 'danger';
        $scope.messages.message = result.message;
      }
    );
  };

  //////////////////////////////////////////////////////////////////////
  // Toggle role values
  var _toggleRoleFilter = function(val) {
    var idx = $scope.roles.indexOf(val + "");
    if (idx == -1) {
      $scope.roles.push(val + "");
    }
    else {
      $scope.roles.splice(idx, 1);
    }
  };

  //////////////////////////////////////////////////////////////////////
  // Utilities
  //////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////////
  // Function Mapping
  //////////////////////////////////////////////////////////////////////
  $scope.save = _save;
  $scope.toggleRoleFilter = _toggleRoleFilter;

  //////////////////////////////////////////////////////////////////////
  // Initialization
  //////////////////////////////////////////////////////////////////////
  $scope.messages = {
    status: '',
    message: '',
  };

  $scope.roles = [];

};

module.exports = newAccountController;