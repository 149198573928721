'use strict';

iaasAccountListController.$inject = [
  '$scope',
  '$uibModal',
  '$routeParams',
  '$location',
  'IaasAccounts'
];

function iaasAccountListController($scope, $uibModal, $routeParams, $location, IaasAccounts) {

  ////////////////////////////////////////////////////////////
  // Methods
  ////////////////////////////////////////////////////////////
  var _load = function() {
    var modal = $uibModal.open({
      templateUrl: "views/modal/progress.html",
      backdrop: "static",
      keyboard: false
    });

    _loadIasAccountList()
    .then(function(){
      angular.forEach($scope.iaasAccounts, function(iaasAccount, i){
        _loadInstanceCount(iaasAccount);
      })      
    })
    .then(function(){
      modal.close();
    });
  };

  ////////////////////////////////////////////////////////////
  // Load Iaas Account
  var _loadIasAccountList = function() {
    return IaasAccounts.pagedAll($routeParams.num).then(function(data){
      $scope.iaasAccounts = data.content;
      if ( data.totalElements == 0 ) {
        $scope.hideTable = true;
      } else {
        $scope.hideTable = false;
      }

      // Pagination
      $scope.totalItems = data.totalElements;
      $scope.currentPage = data.number + 1
      $scope.maxSize = 7;
      $scope.itemsPerPage = data.size;

      $scope.pageChanged = function() {
        $location.path('/iaasAccounts/pages/' + $scope.currentPage);
      };
    });
  };

  ////////////////////////////////////////////////////////////
  // Load Iaas Account
  var _loadInstanceCount = function(iaasAccount) {
    return IaasAccounts.getInstanceCount(iaasAccount.iaasAccountId).then(function(data){
      iaasAccount.instanceCount = data;
      if ( iaasAccount.iaasAccountLimit <= iaasAccount.instanceCount ) {
        iaasAccount.countIndicatorClass = "danger";
      }
      else if ( ( iaasAccount.iaasAccountLimit * 0.8 ) <= iaasAccount.instanceCount ) {
        iaasAccount.countIndicatorClass = "warning";
      }
    });
  };

  ////////////////////////////////////////////////////////////
  // Functions
  ////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////
  // Function Mapping
  ////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////
  // Initialize
  ////////////////////////////////////////////////////////////
  $scope.messages = {
    status: '',
    message: '',
  };

  $scope.hideTable = true;

  // Load initial list
  _load();

};

module.exports = iaasAccountListController;