'use strict';

UserService.$inject = [
  '$http',
  '$location',
  'API'
];

function UserService($http, $location, API) {
  var getUser = function(userId) {
    var url = API + '/api/users/' + userId;

    return $http({
      method: 'get',
      url: url
    });
  };

  var updateUser = function(params) {
    var url = API + '/api/users/' + userId;
  };

  var _passwordResetReuqest = function(email) {
    var url = API + '/forgot';
    var host = $location.protocol() + '://' + $location.host();
    if ( $location.port() != 80 || $location.port() != 433 )
      host += ':' + $location.port();
    var data = {
      email: email,
      host: host
    };

    return $http({
      method: 'post',
      url: url,
      data: data
    });

  };

  var _resetPassword = function(token, password) {
    var url = API + '/reset_password';
    var data = {
      token: token,
      password: password
    };

    return $http({
      method: 'post',
      url: url,
      data: data
    });
  };

  return {
    getUser: function(userId) {
      return getUser(userId);
    },
    updateUser: function(userInfo) {
      var url = API + '/api/users/' + userInfo.accountId;
      var data = {
        displayName: userInfo.accountDisplayName,
        email: userInfo.accountEmail
      };
      if (userInfo.accountPassword != '') {
        data.password = userInfo.accountPassword
      }

      return $http({
        method: 'put',
        url: url,
        data: data
      });
    },
    passwordResetReuqest: function(email) {
      return _passwordResetReuqest( email ).then(function(res) {
        return res.data;
      }, function(res){
        return res.data;
      });
    },
    resetPassword: function(token, password) {
      return _resetPassword( token, password ).then(function(res) {
        return res.data;
      }, function(res){
        return res.data;
      });
    }
  };
};

module.exports = UserService;
