'use strict';

templateController.$inject = [
  '$scope',
  '$uibModal',
  '$routeParams',
  '$q',
  '$location',
  'Templates'
];

function templateController($scope, $uibModal, $routeParams, $q, $location, Templates) {

  //////////////////////////////////////////////////////////////////////
  // Functions
  //////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////////
  // Save
  var _save = function() {
    var data = {
      templateBody: $scope.template.templateBody,
      templateTitle: $scope.template.templateTitle
    }

    var modal = $uibModal.open({
      templateUrl: "views/modal/progress.html",
      backdrop: "static",
      keyboard: false
    });

    Templates.save($scope.templateId, data).then(function(result) {
      modal.close();
      if (result.message == undefined) {
        $scope.messages.status = 'success';
        $scope.messages.message = 'メールテンプレートを保存しました。';
        $scope.template = result;
        $scope.editMode = false;
      } else {
        $scope.messages.status = 'danger';
        $scope.messages.message = result.message;
      }
    });
  };

  //////////////////////////////////////////////////////////////////////
  // Disable
  var _disable = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';

    var modal = $uibModal.open({
      templateUrl: "views/modal/confirm.html",
      backdrop: "static",
      keyboard: false,
      scope: $scope,
      controller: function($uibModalInstance, $scope) {
        $scope.title = "メールテンプレートの無効化";
        $scope.messageBody = "このメールテンプレートを無効にします。よろしいですか？";
        $scope.okay = function() {
          $uibModalInstance.close();
        };
        $scope.cancel = function() {
          $uibModalInstance.dismiss('cancel');
        };
      }
    });
    modal.result.then(function() {
      // Do destroy
      var modal = $uibModal.open({
        templateUrl: "views/modal/progress.html",
        backdrop: "static",
        keyboard: false
      });

      var data = {
        templateStatus: 2
      };

      Templates.save($scope.templateId, data).then(function(result) {
        modal.close();
        if (result.message == undefined) {
          $scope.messages.status = 'success';
          $scope.messages.message = 'メールテンプレートを無効にしました。';
          $scope.template = result;
          $scope.editMode = false;
          _resetAction();
        } else {
          $scope.messages.status = 'danger';
          $scope.messages.message = result.message;
        }
      });
    }, function() {});
  };

  //////////////////////////////////////////////////////////////////////
  // Enable
  var _enable = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';

    var modal = $uibModal.open({
      templateUrl: "views/modal/confirm.html",
      backdrop: "static",
      keyboard: false,
      scope: $scope,
      controller: function($uibModalInstance, $scope) {
        $scope.title = "メールテンプレートの有効化";
        $scope.messageBody = "このメールテンプレートを有効にします。よろしいですか？";
        $scope.okay = function() {
          $uibModalInstance.close();
        };
        $scope.cancel = function() {
          $uibModalInstance.dismiss('cancel');
        };
      }
    });
    modal.result.then(function() {
      // Do destroy
      var modal = $uibModal.open({
        templateUrl: "views/modal/progress.html",
        backdrop: "static",
        keyboard: false
      });

      var data = {
        templateStatus: 1
      };

      Templates.save($scope.templateId, data).then(function(result) {
        modal.close();
        if (result.message == undefined) {
          $scope.messages.status = 'success';
          $scope.messages.message = 'メールテンプレートを有効にしました。';
          $scope.template = result;
          $scope.editMode = false;
          _resetAction();
        } else {
          $scope.messages.status = 'danger';
          $scope.messages.message = result.message;
        }
      });
    }, function() {});
  };

  //////////////////////////////////////////////////////////////////////
  // Delete
  var _delete = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';

    var modal = $uibModal.open({
      templateUrl: "views/modal/confirm.html",
      backdrop: "static",
      keyboard: false,
      scope: $scope,
      controller: function($uibModalInstance, $scope) {
        $scope.title = "メールテンプレートの削除";
        $scope.messageBody = "このメールテンプレートを削除します。よろしいですか？";
        $scope.okay = function() {
          $uibModalInstance.close();
        };
        $scope.cancel = function() {
          $uibModalInstance.dismiss('cancel');
        };
      }
    });
    modal.result.then(function() {
      // Do destroy
      var modal = $uibModal.open({
        templateUrl: "views/modal/progress.html",
        backdrop: "static",
        keyboard: false
      });

      Templates.delete($scope.templateId).then(function(result) {
        modal.close();
        if (result.message == undefined) {
          $scope.messages.status = 'success';
          $scope.messages.message = 'メールテンプレートを削除しました。';
          $scope.template = result;
          $scope.editMode = false;
          _resetAction();
        } else {
          $scope.messages.status = 'danger';
          $scope.messages.message = result.message;
        }
      });
    }, function() {});
  };

  //////////////////////////////////////////////////////////////////////
  // Change edit mode
  var _editMode = function() {
    $scope.editMode = true;
  };

  //////////////////////////////////////////////////////////////////////
  // Bind functions
  //////////////////////////////////////////////////////////////////////
  $scope.save = _save;
  $scope.edit = _editMode;
  $scope.disable = _disable;
  $scope.enable = _enable;
  $scope.delete = _delete;

  //////////////////////////////////////////////////////////////////////
  // Methods
  //////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////////
  // Loading
  var _load = function() {
    var modal = $uibModal.open({
      templateUrl: "views/modal/progress.html",
      backdrop: "static",
      keyboard: false
    });

    $q.all([
      _loadTemplate(),
    ])
    .then(function(){
      _resetAction();
      modal.close();
    });
  };

  //////////////////////////////////////////////////////////////////////
  // Loading
  var _loadTemplate = function() {
    return Templates.getTemplate($scope.templateId).then(function(data) {
      if (data.message == undefined) {
        $scope.template = data;
      } else {
        $scope.messages.status = 'danger';
        $scope.messages.message = result.message;
      }
    });
  };

  //////////////////////////////////////////////////////////////////////
  // Initialization
  var _initialize = function() {
    $scope.messages = {
      status: '',
      message: '',
    };

    $scope.templateId = $routeParams.id;
  
    $scope.action = null;
    $scope.editMode = false;
  };

  //////////////////////////////////////////////////////////////////////
  // Reset action bar
  var _resetAction = function() {
    $scope.action = null;
    if ($scope.template.templateStatus == 1) {
      $scope.action = {};
      $scope.action.canEdit = true;
      $scope.action.canDisable = true;
      $scope.action.canDelete = true;
    } else if ($scope.template.templateStatus == 2) {
      $scope.action = {};
      $scope.action.canEdit = true;
      $scope.action.canEnable = true;
      $scope.action.canDelete = true;
    }
  };

  _initialize();
  _load();
};

module.exports = templateController;