'use strict';

editCustomerPersonController.$inject = [
  '$uibModalInstance',
  '$scope',
  '$uibModal',
  'CustomerPersons',
  'params'
];

function editCustomerPersonController($uibModalInstance, $scope, $uibModal, CustomerPersons, params) {

  ////////////////////////////////////////////////////////////
  // Functions
  ////////////////////////////////////////////////////////////
  var _save = function() {
    var data = {
      customerPersonName: $scope.customerPerson.customerPersonName,
      customerPersonEmail: $scope.customerPerson.customerPersonEmail,
      customerPersonDepartment: $scope.customerPerson.customerPersonDepartment,
      customerPersonTel: $scope.customerPerson.customerPersonTel
    };

    var modal = $uibModal.open({
      templateUrl: "views/modal/progress.html",
      backdrop: "static",
      keyboard: false
    });

    if ( $scope.customerPerson.customerPersonId) {
      // update
      CustomerPersons.update($scope.customerPerson.customerPersonId, data).then(function(data){
        modal.close();
        if (data.message == undefined) {
          $uibModalInstance.close(data);
        } else {
          $uibModalInstance.dismiss(data.message);
        }
      })
    }
    else {
      // create
      data.customerId = $scope.customer.customerId,
      CustomerPersons.save(data).then(function(data){
        modal.close();
        if (data.message == undefined) {
          $uibModalInstance.close(data);
        } else {
          $uibModalInstance.dismiss(data.message);
        }
      })
    }
  };

  var _cancel = function() {
    $uibModalInstance.dismiss('cancel');
  };

  ////////////////////////////////////////////////////////////
  // Function Mapping
  ////////////////////////////////////////////////////////////
  $scope.save = _save;
  $scope.cancel = _cancel;

  ////////////////////////////////////////////////////////////
  // Initialize
  ////////////////////////////////////////////////////////////
  $scope.messages = {
    status: '',
    message: '',
  };

  $scope.customer = params.customer;
  $scope.customerPerson = params.customerPerson;
  $scope.isNew = params.isNew;
};

module.exports = editCustomerPersonController;
