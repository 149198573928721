'use strict';

MailLog.$inject = [
  '$rootScope',
  '$http',
  'API'
];
function MailLog( $rootScope, $http, API ) {
  var _listMailLogByOrder = function(orderId) {
    var url = API + '/api/maillogs/?orderId=' + orderId;
    return $http({
      method: 'get',
      url: url
    });
  };

  return {
    listMailLogByOrder: function(orderId) {
      return _listMailLogByOrder(orderId).then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      )
    }
  }
};

module.exports = MailLog;
