'use strict';

dashboardController.$inject = [
  '$scope',
  '$uibModal',
  '$location',
  'News',
  'Sellers'
];
function dashboardController($scope, $uibModal, $location, News, Sellers) {

  ////////////////////////////////////////////////////////////
  // Functions

  // Loading news
  var _loadNews = function() {
    News.listAvailableNews().then(
      function onSuccess(result){
        if (angular.isArray(result)) {
          $scope.newsList = result;
          if ( result.length > 0 ) {
            $scope.noNews = false;
          }
        } else {
          $scope.messages.status = 'danger';
          $scope.messages.message = result.message;
        }
      },
      function onError(result) {
        $scope.messages.status = 'danger';
        $scope.messages.message = result;
      }
    );
  };

  // Loadig seller list
  var _loadSellerList = function() {
    Sellers.listAvailable().then(
      function(data) {
        if (!data || data.message) {
          $scope.messages.status = 'danger';
          $scope.messages.message = data.message;
        } else {
          $scope.sellerList = data;
        }
      }
    );
  };

  ////////////////////////////////////////////////////////////
  // Event Functions

  // Open news detail
  var _openNews = function(id) {
    News.getNews(id).then(function(data){
      var modal = $uibModal.open({
        size: 'lg',
        templateUrl: "views/modal/news_detail.html",
        backdrop: "static",
        scope: $scope,
        controller : function($uibModalInstance ,$scope){
          $scope.news = data;
          $scope.content = $scope.news.newsContents;
          $scope.close = function() {
            $uibModalInstance.close();
          };
        }
      });
    })
  };

  // Move to order list
  var _filterOrderList = function() {
    var params = "";
    if ($scope.searchFreeWord) {
      params += "searchWord=" + encodeURIComponent($scope.searchFreeWord);
    }
    if ($scope.statusFilter.length > 0) {
      if (params.length > 0) {
        params += '&';
      }
      params += "status=" + encodeURIComponent($scope.statusFilter.join(','));
    }
    if ($scope.sellerFilter.length > 0) {
      if (params.length > 0) {
        params += '&';
      }
      params += "sellerId=" + encodeURIComponent($scope.sellerFilter.join(','));
    }
    if ($scope.paymentFilter.length > 0) {
      if (params.length > 0) {
        params += '&';
      }
      params += "paymentMethod=" + encodeURIComponent($scope.paymentFilter.join(','));
    }
    if ($scope.dateFrom || $scope.dateTo) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'dateField=' + $scope.dateField;
      if ($scope.dateFrom) {
        var yyyy = $scope.dateFrom.getFullYear();
        var mm = ('0' + ($scope.dateFrom.getMonth() + 1)).slice(-2);
        var dd = ('0' + ($scope.dateFrom.getDate())).slice(-2)
        var dt = yyyy + '-' + mm + '-' + dd;
        params += '&dateFrom=' + dt;
      }
      if ($scope.dateTo) {
        var yyyy = $scope.dateTo.getFullYear();
        var mm = ('0' + ($scope.dateTo.getMonth() + 1)).slice(-2);
        var dd = ('0' + ($scope.dateTo.getDate())).slice(-2)
        var dt = yyyy + '-' + mm + '-' + dd;
        params += '&dateTo=' + dt;
      }
    }

    // Move to 1st page
    $location.path('/orders/pages/1');
    $location.search(params);

  };

  // Open date picker
  var _openDatePicker = function(fld) {
    fld.opened = true;
  }
  var _openDateFrom = function() {
    _openDatePicker($scope.dateFromPicker);
  }
  var _openDateTo = function() {
    _openDatePicker($scope.dateToPicker);
  }

  // Toggle status filter item
  var _toggleStatusFilter = function(val) {
    var idx = $scope.statusFilter.indexOf(val + "");
    if (idx == -1) {
      $scope.statusFilter.push(val + "");
    } else {
      $scope.statusFilter.splice(idx, 1);
    }
  }

  // Toggle seller filter item
  var _toggleSellerFilter = function(val) {
    var idx = $scope.sellerFilter.indexOf(val + "");
    if (idx == -1) {
      $scope.sellerFilter.push(val + "");
    } else {
      $scope.sellerFilter.splice(idx, 1);
    }
  }

  //////////////////////////////////////////////////////////////////////
  // Toggle status filter item
  var _togglePaymentFilter = function(val) {
    var idx = $scope.paymentFilter.indexOf(val + "");
    if (idx == -1) {
      $scope.paymentFilter.push(val + "");
    } else {
      $scope.paymentFilter.splice(idx, 1);
    }
  }

  ////////////////////////////////////////////////////////////
  // Event Binding
  $scope.openNews = _openNews;
  $scope.filterOrderList = _filterOrderList;
  $scope.openDateFromPicker = _openDateFrom;
  $scope.openDateToPicker = _openDateTo;
  $scope.toggleStatusFilter = _toggleStatusFilter;
  $scope.toggleSellerFilter = _toggleSellerFilter;
  $scope.togglePaymentFilter = _togglePaymentFilter;

  ////////////////////////////////////////////////////////////
  // Initialize
  $scope.dateFields = [{
    "label": "注文受付日",
    "value": "orderDate"
  },
  {
    "label": "利用開始日",
    "value": "orderActivationDate"
  },
  {
    "label": "解約日",
    "value": "orderExpiredDate"
  }];
  $scope.dateField = $scope.dateFields[0].value;

  var maxDate = new Date();
  maxDate.setFullYear( maxDate.getFullYear() + 1 );

  $scope.dateOptions = {
    datepickerMode: 'day',
    formatYear: 'yyyy',
    maxDate: maxDate,
    startingDay: 0
  };

  $scope.dateFromPicker = {
    opened: false
  };
  $scope.dateToPicker = {
    opened: false
  };

  $scope.altInputFormats = ['/yyyy-MM-dd'];
  $scope.noNews = true;
  $scope.searchFreeWord = '';
  $scope.statusFilter = [];
  $scope.sellerFilter = [];
  $scope.paymentFilter = [];

  _loadNews();
  _loadSellerList();

};

module.exports = dashboardController;
