'use strict';

cloudTypeSelectorModalController.$inject = [
  '$uibModalInstance',
  '$scope',
  '$uibModal',
  'CloudTypes'
];

function cloudTypeSelectorModalController($uibModalInstance, $scope, $uibModal, CloudTypes) {

  ////////////////////////////////////////////////////////////
  // Methods
  ////////////////////////////////////////////////////////////
  var _listCloudType = function(page, search) {
    var modal = $uibModal.open({
      templateUrl: "views/modal/progress.html",
      backdrop: "static",
      keyboard: false
    });

    CloudTypes.pagedCloudTypes(page).then(
      function(data){
        if (data.totalElements > 0) {
          $scope.hideTable = false;
          $scope.totalItems = data.totalElements;
          $scope.cloudTypes = data.content;
          $scope.currentPage = data.number + 1
          $scope.itemsPerPage = data.size;
          $scope.maxSize = 7;
        } else {
          $scope.hideTable = true;
        }
        modal.close();
      },
      function(data){
        $uibModalInstance.dismiss(data.message);
        modal.close();
      }
    );
  };

  ////////////////////////////////////////////////////////////
  // Functions
  ////////////////////////////////////////////////////////////
  $scope.pageChanged = function() {
    _listCloudType($scope.currentPage);
  };

  $scope.selectRow = function(idx) {
    var cloudType = $scope.cloudTypes[idx];
    if ($scope.selectedRow && $scope.selectedRow == cloudType) {
      $scope.selectedRow = null;
    } else {
      $scope.selectedRow = cloudType;
    }
  };

  $scope.choose = function() {
    $uibModalInstance.close($scope.selectedRow);
  };

  $scope.cancel = function() {
    $uibModalInstance.dismiss('cancel');
  };

  ////////////////////////////////////////////////////////////
  // Initialize
  ////////////////////////////////////////////////////////////
  $scope.messages = {
    status: '',
    message: '',
  };

  _listCloudType(1);
};

module.exports = cloudTypeSelectorModalController;
