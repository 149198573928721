'use strict';

var angular = require('angular');

angular.module('app').controller('authController', require('./authController'));
angular.module('app').controller('dashboardController', require('./dashboardController'));
angular.module('app').controller('feedbackController', require('./feedbackController'));
angular.module('app').controller('instanceController', require('./instanceController'));
angular.module('app').controller('navController', require('./navController'));
angular.module('app').controller('userController', require('./userController'));
angular.module('app').controller('orderController', require('./orderController'));
angular.module('app').controller('cancellationController', require('./cancellationController'));
angular.module('app').controller('customerPersonModalController', require('./customerPersonModalController'));
angular.module('app').controller('customerSelectorModalController', require('./customerSelectorModalController'));
angular.module('app').controller('newCustomerController', require('./newCustomerController'));
angular.module('app').controller('newCustomerPersonController', require('./newCustomerPersonController'));
angular.module('app').controller('productSearchController', require('./productSearchController'));
angular.module('app').controller('orderListController', require('./orderListController'));
angular.module('app').controller('exportOrderController', require('./exportOrderController'));
angular.module('app').controller('exportOrderBySalesController', require('./exportOrderBySalesController'));
angular.module('app').controller('accountListController', require('./accountListController'));
angular.module('app').controller('accountController', require('./accountController'));
angular.module('app').controller('instanceListController', require('./instanceListController'));
angular.module('app').controller('licenseController', require('./licenseController'));
angular.module('app').controller('licenseListController', require('./licenseListController'));
angular.module('app').controller('licenseBulkController', require('./licenseBulkController'));
angular.module('app').controller('assignLicenseModalController', require('./assignLicenseModalController'));
angular.module('app').controller('customerListController', require('./customerListController'));
angular.module('app').controller('customerController', require('./customerController'));
angular.module('app').controller('editCustomerPersonController', require('./editCustomerPersonController'));
angular.module('app').controller('exchangeInstanceModalController', require('./exchangeInstanceModalController'));
angular.module('app').controller('addOptionModalController', require('./addOptionModalController'));
angular.module('app').controller('newAccountController', require('./newAccountController'));
angular.module('app').controller('vendorSelectorController', require('./vendorSelectorController'));
angular.module('app').controller('instanceSelectorController', require('./instanceSelectorController'));
angular.module('app').controller('vendorListController', require('./vendorListController'));
angular.module('app').controller('vendorController', require('./vendorController'));
angular.module('app').controller('iaasAccountSelectorController', require('./iaasAccountSelectorController'));
angular.module('app').controller('productGroupSelectorController', require('./productGroupSelectorController'));
angular.module('app').controller('accountSelectorController', require('./accountSelectorController'));
angular.module('app').controller('excahngeVendorModalController', require('./excahngeVendorModalController'));
angular.module('app').controller('sellerListController', require('./sellerListController'));
angular.module('app').controller('sellerController', require('./sellerController'));
angular.module('app').controller('productGroupListController', require('./productGroupListController'));
angular.module('app').controller('productGroupController', require('./productGroupController'));
angular.module('app').controller('productListController', require('./productListController'));
angular.module('app').controller('productController', require('./productController'));
angular.module('app').controller('cloudTypeSelectorModalController', require('./cloudTypeSelectorModalController'));
angular.module('app').controller('logListController', require('./logListController'));
angular.module('app').controller('iaasTypeListController', require('./iaasTypeListController'));
angular.module('app').controller('iaasTypeController', require('./iaasTypeController'));
angular.module('app').controller('iaasAccountListController', require('./iaasAccountListController'));
angular.module('app').controller('iaasAccountController', require('./iaasAccountController'));
angular.module('app').controller('iaasTypeSelectorModalController', require('./iaasTypeSelectorModalController'));
angular.module('app').controller('cloudTypeListController', require('./cloudTypeListController'));
angular.module('app').controller('cloudTypeController', require('./cloudTypeController'));
angular.module('app').controller('cloudOptionListController', require('./cloudOptionListController'));
angular.module('app').controller('cloudOptionController', require('./cloudOptionController'));
angular.module('app').controller('newsListController', require('./newsListController'));
angular.module('app').controller('newsController', require('./newsController'));
angular.module('app').controller('billingController', require('./billingController'));
angular.module('app').controller('customerAndPersonSelectorModalController', require('./customerAndPersonSelectorModalController'));
angular.module('app').controller('templateListController', require('./templateListController'));
angular.module('app').controller('templateController', require('./templateController'));
angular.module('app').controller('ansibleLogController', require('./ansibleLogController'));
