'use strict';

IaasAccounts.$inject = [
  '$http',
  'API'
];

function IaasAccounts( $http, API ) {
  var _pagedAll = function(num) {
    var url = API + '/api/iaasAccounts/pages/' + num;
    return $http({
      method: 'get',
      url: url
    });
  };

  var _listAll = function() {
    var url = API + '/api/iaasAccounts/';
    return $http({
      method: 'get',
      url: url
    });
  };

  var _getOne = function(id) {
    var url = API + '/api/iaasAccounts/' + id;
    return $http({
      method: 'get',
      url: url
    });
  };

  var _save = function(id, data) {
    var url = API + '/api/iaasAccounts/';
    var verb = 'post';
    if (id) {
      url += id;
      verb = 'put';
    }
    return $http({
      method: verb,
      url: url,
      data: data
    });
  };

  var _delete = function(id) {
    var url = API + '/api/iaasAccounts/' + id;
    return $http({
      method: 'delete',
      url: url
    });
  };

  var _getInstanceCount = function(id) {
    var url = API + '/api/iaasAccounts/' + id + '/count';
    return $http({
      method: 'get',
      url: url
    });
  };

  return {
    pagedAll: function(num) {
      return _pagedAll(num).then(function(res) {
        return res.data;
      }, function(res){
        return res.data;
      })
    },
    listAll: function() {
      return _listAll().then(function(res) {
        return res.data;
      }, function(res){
        return res.data;
      })
    },
    getOne: function(id) {
      return _getOne(id).then(function(res) {
        return res.data;
      }, function(res){
        return res.data;
      })
    },
    save: function(id, data) {
      return _save(id, data).then(function(res) {
        return res.data;
      }, function(res){
        return res.data;
      })
    },
    delete: function(id) {
      return _delete(id).then(function(res) {
        return res.data;
      }, function(res){
        return res.data;
      })
    },
    getInstanceCount: function(id) {
      return _getInstanceCount(id).then(function(res) {
        return res.data;
      }, function(res){
        return res.data;
      })
    }
  };
};

module.exports = IaasAccounts;
