'use strict';

accountSelectorController.$inject = [
  '$scope',
  '$uibModal',
  '$uibModalInstance',
  'Users',
  'params'
];

function accountSelectorController($scope, $uibModal, $uibModalInstance, Users, params) {

  //////////////////////////////////////////////////////////////////////
  // Functions
  //////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////////
  // Select Row
  var _selectRow = function(selected) {
    var account = $scope.accounts[selected];
    var idx = $scope.selected.findIndex(function(v){
      return v.accountId == account.accountId;
    });
    if (idx == -1) {
      $scope.selected.push(account);
    } else {
      $scope.selected.splice(idx, 1);
    }
  };

  //////////////////////////////////////////////////////////////////////
  // Return true when account is already selected.
  var _isSelected = function(selected) {
    var idx = -1;
    if ( $scope.accounts ) {
      var account = $scope.accounts[selected];
      idx = $scope.selected.findIndex(function(v){
        return v.accountId == account.accountId;
      });
    }
    return idx == -1 ? false : true;
  };

  //////////////////////////////////////////////////////////////////////
  // Remove selected item
  var _remove = function(selected) {
    $scope.selected.splice(selected, 1);
  };

  //////////////////////////////////////////////////////////////////////
  // OK
  var _choose = function() {
    var ids = [];
    angular.forEach($scope.selected, function(v, i){
      ids.push(v.accountId);
    });
    $uibModalInstance.close(ids);
  };

  //////////////////////////////////////////////////////////////////////
  // Cancel
  var _cancel = function() {
    $uibModalInstance.dismiss('cancel');
  };

  //////////////////////////////////////////////////////////////////////
  // Search
  $scope.filterList = function() {
    _loadAccounts(1, $scope.searchFreeWord);
  };

  //////////////////////////////////////////////////////////////////////
  // Page move
  $scope.pageChanged = function() {
    _loadAccounts($scope.currentPage, $scope.searchFreeWord);
  };

  //////////////////////////////////////////////////////////////////////
  // Function Mapping
  //////////////////////////////////////////////////////////////////////
  $scope.selectRow = _selectRow;
  $scope.isSelected = _isSelected;
  $scope.choose = _choose;
  $scope.cancel = _cancel;
  $scope.remove = _remove;

  //////////////////////////////////////////////////////////////////////
  // Utilities
  //////////////////////////////////////////////////////////////////////
  var _loadAccounts = function(page, searchFreeWord) {
    var modal = $uibModal.open({
      templateUrl: "views/modal/progress.html",
      backdrop: "static",
      keyboard: false
    });

    var data = {
      searchWord: searchFreeWord,
      excludeIds: params.accountIds
    };

    Users.listUser(page, data).then(
      function(data){
        if (data.totalElements > 0) {
          $scope.hideTable = false;
          $scope.accounts = data.content;
          $scope.totalItems = data.totalElements;
          $scope.customers = data.content;
          $scope.currentPage = data.number + 1
          $scope.itemsPerPage = data.size;
          $scope.maxSize = 7;
        } else {
          $scope.hideTable = true;
        }
        modal.close();
      },
      function(data){
        $uibModalInstance.dismiss(data.message);
        modal.close();
      }
    );
  };

  //////////////////////////////////////////////////////////////////////
  // Initialize
  //////////////////////////////////////////////////////////////////////
  $scope.vendorName = params.vendorName;
  $scope.selected = [];
  $scope.hideTable = true;
  $scope.searchFreeWord = '';

  _loadAccounts(1);

}

module.exports = accountSelectorController;
