'use strict';

accountListController.$inject = [
  '$scope',
  '$uibModal',
  '$routeParams',
  '$location',
  '$filter',
  'Users'
];

function accountListController($scope, $uibModal, $routeParams, $location, $filter, Users) {

  //////////////////////////////////////////////////////////////////////
  // Loading order list
  var _loadList = function() {
    var modal = $uibModal.open({
      templateUrl: "views/modal/progress.html",
      backdrop: "static",
      keyboard: false
    });

    Users.listUser($routeParams.num, $location.search()).then(
      function(data) {
        modal.close();

        if ( data.error ) {
          $scope.messages.status = 'danger';
          $scope.messages.message = data.message;
          return;
        }

        $scope.accounts = data.content;

        if (data.totalElements == 0) {
          $scope.hideTable = true;
        } else {
          $scope.hideTable = false;
        }

        // Pagination
        $scope.totalItems = data.totalElements;
        $scope.currentPage = data.number + 1
        $scope.maxSize = 7;
        $scope.itemsPerPage = data.size;

        $scope.pageChanged = function() {
          $location.path('/accounts/pages/' + $scope.currentPage);
        }
      }
    );
  };

  //////////////////////////////////////////////////////////////////////
  // Loading order list with filter
  var _filterAccountList = function() {
    var params = "";
    if ($scope.searchFreeWord) {
      params += "searchWord=" + encodeURIComponent($scope.searchFreeWord);
    }
    if ($scope.limit) {
      if (params.length > 0) {
        params += '&';
      }
      params += "limit=" + $scope.limit.value;
    }

    // Back to 1st page
    $location.path('/accounts/pages/1');
    $location.search(params);

    _loadList();
  }

  //////////////////////////////////////////////////////////////////////
  // Clear list filters
  var _resetFilter = function() {
    $scope.searchFreeWord = '';
    $location.url('/accounts/pages/1');
  }

  //////////////////////////////////////////////////////////////////////
  // Change limit 
  var _changeLimit = function() {
    _filterAccountList();
  };

  //////////////////////////////////////////////////////////////////////
  // Toggle search panel 
  var _togglePanel = function() {
    $scope.panel.visible = !$scope.panel.visible
  }


  ////////////////////////////////////////////////////////////
  // Function mapping
  $scope.filterAccountList = _filterAccountList;
  $scope.resetFilter = _resetFilter;
  $scope.changeLimit = _changeLimit;
  $scope.togglePanel = _togglePanel;

  ////////////////////////////////////////////////////////////
  // Initialize

  $scope.messages = {
    status: '',
    message: '',
  };

  $scope.limits = [{
      label: '10件',
      value: 10
    },
    {
      label: '20件',
      value: 20
    },
    {
      label: '50件',
      value: 50
    },
    {
      label: '100件',
      value: 100
    },
  ]

  $scope.panel = {
    visible: false
  };

  $scope.limit = $scope.limits[0];
  $scope.hideTable = true;

  // Parse QueryString
  if (!angular.isUndefined($location.search()['searchWord'])) {
    $scope.searchFreeWord = decodeURIComponent($location.search()['searchWord'])
  }
  if (!angular.isUndefined($location.search()['limit'])) {
    var limit = parseInt(decodeURIComponent($location.search()['limit']));
    var res = $filter('filter')($scope.limits, {
      value: limit
    }, true);
    if (res.length > 0)
      $scope.limit = res[0];
  }

  _loadList();

}

module.exports = accountListController;