'use strict';

customerListController.$inject = [
  '$scope',
  '$uibModal',
  '$location',
  '$routeParams',
  '$filter',
  'Customers',
  'Vendors'
];
function customerListController($scope, $uibModal, $location, $routeParams, $filter, Customers, Vendors) {

  ////////////////////////////////////////////////////////////
  // Functions
  ////////////////////////////////////////////////////////////
  var _filterList = function() {
    var params = "";
    if ($scope.search) {
      params += "search=" + encodeURIComponent($scope.search);
    }
    if ($scope.limit) {
      if (params.length > 0) {
        params += '&';
      }
      params += "limit=" + $scope.limit.value;
    }
    if ($scope.statusFilter.length > 0) {
      if (params.length > 0) {
        params += '&';
      }
      params += "status=" + encodeURIComponent($scope.statusFilter.join(','));
    }
    if ( $scope.vendorFilter ) {
      if (params.length > 0) {
        params += '&';
      }
      params += "vendorId=" + $scope.vendorFilter.vendorId;
    }

    // Back to 1st page
    $location.path('/customers/pages/1');
    $location.search(params);

    _load();
  }

  var _resetFilter = function() {
    $scope.search = '';
    $location.url('/customers/pages/1');
  }

  //////////////////////////////////////////////////////////////////////
  // Change limit 
  var _changeLimit = function() {
    _filterList();
  };

  //////////////////////////////////////////////////////////////////////
  // Toggle search panel 
  var _togglePanel = function() {
    $scope.panel.visible = !$scope.panel.visible
  }

  //////////////////////////////////////////////////////////////////////
  // Toggle status filter item
  var _toggleStatusFilter = function(val) {
    var idx = $scope.statusFilter.indexOf(val + "");
    if (idx == -1) {
      $scope.statusFilter.push(val + "");
    } else {
      $scope.statusFilter.splice(idx, 1);
    }
  }

  ////////////////////////////////////////////////////////////
  // Function Mapping
  ////////////////////////////////////////////////////////////
  $scope.filterList = _filterList;
  $scope.resetFilter = _resetFilter;
  $scope.togglePanel = _togglePanel;
  $scope.changeLimit = _changeLimit;
  $scope.toggleStatusFilter = _toggleStatusFilter;

  ////////////////////////////////////////////////////////////
  // Methods
  ////////////////////////////////////////////////////////////
  var _load = function() {
    var modal = $uibModal.open({
      templateUrl: "views/modal/progress.html",
      backdrop: "static",
      keyboard: false
    });

    var params = $location.search();
    if ( Object.keys(params).length == 0 ) {
      params = undefined;
    }
      
    Customers.listCustomers($routeParams.num, params).then(function(data){
      $scope.customers = data.content;
      if ( data.totalElements == 0 ) {
        $scope.hideTable = true;
      } else {
        $scope.hideTable = false;
      }

      // Pagination
      $scope.totalItems = data.totalElements;
      $scope.currentPage = data.number + 1
      $scope.maxSize = 7;
      $scope.itemsPerPage = data.size;

      $scope.pageChanged = function() {
        $location.path('/customers/pages/' + $scope.currentPage);
      };

      modal.close();
    });
  };

  var _loadVendors = function() {
    return Vendors.listVendors().then(function(data){
      if (data.message == undefined) {
        if ( data.length > 0)
          $scope.vendors = data;
      } else {
        $scope.messages.status = 'danger';
        $scope.messages.message = data.message;
      }
    });
  };

  ////////////////////////////////////////////////////////////
  // Initialize
  ////////////////////////////////////////////////////////////
  $scope.messages = {
    status: '',
    message: '',
  };

  $scope.limits = [{
    label: '10件',
    value: 10
  },
  {
    label: '20件',
    value: 20
  },
  {
    label: '50件',
    value: 50
  },
  {
    label: '100件',
    value: 100
  }];

  $scope.panel = {
    visible: false
  };

  $scope.hideTable = true;
  $scope.limit = $scope.limits[0];

  $scope.statusFilter = [];
  $scope.vendorFilter = null;

  // Parse QueryString
  if (!angular.isUndefined($location.search()['search'])) {
    $scope.search = decodeURIComponent($location.search()['search'])
  }
  if (!angular.isUndefined($location.search()['limit'])) {
    var limit = parseInt(decodeURIComponent($location.search()['limit']));
    var res = $filter('filter')($scope.limits, {
      value: limit
    }, true);
    if (res.length > 0)
      $scope.limit = res[0];
  }
  if (!angular.isUndefined($location.search()['status'])) {
    $scope.statusFilter = decodeURIComponent($location.search()['status']).split(',');
  }

  // Load initial list
  _load();
  _loadVendors().then(function(){
    if (!angular.isUndefined($location.search()['vendorId'])) {
      var vid = parseInt(decodeURIComponent($location.search()['vendorId']));
      var selected = $scope.vendors.findIndex(vendor => vendor.vendorId === vid)
      if ( selected > 0) {
        $scope.vendorFilter = $scope.vendors[selected];
      }
    }
  });

};

module.exports = customerListController;
