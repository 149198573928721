'use strict';

IaasTypes.$inject = [
  '$rootScope',
  '$http',
  'API'
];
function IaasTypes( $rootScope, $http, API ) {
  var _pagedAll = function(page) {
    var url = API + '/api/iaasTypes/pages/' + page;
    return $http({
      method: 'get',
      url: url
    });
  };

  var _load = function(id) {
    var url = API + '/api/iaasTypes/' + id;
    return $http({
      method: 'get',
      url: url
    });
  };

  var _save = function(id, data) {
    var url = API + '/api/iaasTypes/';
    var verb = 'post';
    if (id) {
      url += id;
      verb = 'put';
    }
    return $http({
      method: verb,
      url: url,
      data: data
    });
  };

  var _delete = function(id) {
    var url = API + '/api/iaasTypes/' + id;
    return $http({
      method: 'delete',
      url: url
    });
  };

  return {
    pagedAll: function(page) {
      return _pagedAll(page).then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      )
    },
    load: function(id) {
      return _load(id).then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      )
    },
    save: function(id, data) {
      return _save(id, data).then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      )
    },
    delete: function(id) {
      return _delete(id).then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      )
    }

  }
};

module.exports = IaasTypes;
