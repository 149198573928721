'use strict';

productListController.$inject = [
  '$scope',
  '$uibModal',
  '$routeParams',
  '$location',
  '$filter',
  'Products'
];

function productListController($scope, $uibModal, $routeParams, $location, $filter, Products) {

  ////////////////////////////////////////////////////////////
  // Methods
  ////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////
  // Functions
  ////////////////////////////////////////////////////////////
  var _load = function() {
    var modal = $uibModal.open({
      templateUrl: "views/modal/progress.html",
      backdrop: "static",
      keyboard: false
    });

    Products.pagedAll($routeParams.num, $location.search()).then(function(data){
      $scope.products = data.content;
      if ( data.totalElements == 0 ) {
        $scope.hideTable = true;
      } else {
        $scope.hideTable = false;
      }

      // Pagination
      $scope.totalItems = data.totalElements;
      $scope.currentPage = data.number + 1
      $scope.maxSize = 7;
      $scope.itemsPerPage = data.size;

      $scope.pageChanged = function() {
        $location.path('/products/pages/' + $scope.currentPage);
      };

      modal.close();
    });
  };

  //////////////////////////////////////////////////////////////////////
  // Loading order list with filter
  var _filterProductList = function() {
    var params = "";
    if ($scope.searchFreeWord) {
      params += "searchWord=" + encodeURIComponent($scope.searchFreeWord);
    }
    if ($scope.statusFilter.length > 0) {
      if (params.length > 0) {
        params += '&';
      }
      params += "status=" + encodeURIComponent($scope.statusFilter.join(','));
    }
    if ($scope.limit) {
      if (params.length > 0) {
        params += '&';
      }
      params += "limit=" + $scope.limit.value;
    }

    // Back to 1st page
    $location.path('/products/pages/1');
    $location.search(params);

    _load();
  }

  //////////////////////////////////////////////////////////////////////
  // Clear list filters
  var _resetFilter = function() {
    $scope.searchFreeWord = '';
    $scope.statusFilter = [];
    $location.url('/products/pages/1');
  }

  //////////////////////////////////////////////////////////////////////
  // Toggle status filter item
  var _toggleStatusFilter = function(val) {
    var idx = $scope.statusFilter.indexOf(val + "");
    if (idx == -1) {
      $scope.statusFilter.push(val + "");
    } else {
      $scope.statusFilter.splice(idx, 1);
    }
  }

  //////////////////////////////////////////////////////////////////////
  // Change limit 
  var _changeLimit = function() {
    _filterProductList();
  };

  //////////////////////////////////////////////////////////////////////
  // Toggle search panel visibility
  var _togglePanel = function() {
    $scope.panel.visible = !$scope.panel.visible
  }

  ////////////////////////////////////////////////////////////
  // Function Mapping
  ////////////////////////////////////////////////////////////
  $scope.filterProductList = _filterProductList;
  $scope.toggleStatusFilter = _toggleStatusFilter;
  $scope.resetFilter = _resetFilter;
  $scope.changeLimit = _changeLimit;
  $scope.togglePanel = _togglePanel;

  ////////////////////////////////////////////////////////////
  // Initialize
  ////////////////////////////////////////////////////////////
  $scope.messages = {
    status: '',
    message: '',
  };

  $scope.limits = [{
    label: '10件',
    value: 10
  },
  {
    label: '20件',
    value: 20
  },
  {
    label: '50件',
    value: 50
  },
  {
    label: '100件',
    value: 100
  }];

  $scope.panel = {
    visible: false
  };

  $scope.hideTable = true;
  $scope.searchFreeWord = '';
  $scope.statusFilter = [];
  $scope.limit = $scope.limits[0];

  // Parse QueryString
  if (!angular.isUndefined($location.search()['searchWord'])) {
    $scope.searchFreeWord = decodeURIComponent($location.search()['searchWord'])
  }
  if (!angular.isUndefined($location.search()['status'])) {
    $scope.statusFilter = decodeURIComponent($location.search()['status']).split(',');
  }
  if (!angular.isUndefined($location.search()['limit'])) {
    var limit = parseInt(decodeURIComponent($location.search()['limit']));
    var res = $filter('filter')($scope.limits, {
      value: limit
    }, true);
    if (res.length > 0)
      $scope.limit = res[0];
  }

  // Load initial list
  _load();

};

module.exports = productListController;