'use strict';

ExportService.$inject = [
  '$http',
  '$location',
  'API'
];

function ExportService($http, $location, API) {

  var _exportOrder = function(params) {
    var url = API + '/api/reports/order';

    return $http({
      method: 'post',
      url: url,
      data: params,
      responseType: 'blob'
    });
  };

  var _exportOrderBySales = function(params) {
    var url = API + '/api/reports/sales';

    return $http({
      method: 'post',
      url: url,
      data: params,
      responseType: 'blob'
    });
  };

  return {
    exportOrder: function(params) {
      return _exportOrder(params).then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      )
    },
    exportOrderBySales: function(params) {
      return _exportOrderBySales(params).then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      )
    }
  };

};

module.exports = ExportService;
