'use strict';

newsListController.$inject = [
  '$scope',
  '$uibModal',
  '$routeParams',
  '$location',
  '$filter',
  'News'
];

function newsListController($scope, $uibModal, $routeParams, $location, $filter, News) {

  ////////////////////////////////////////////////////////////
  // Methods
  ////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////
  // Functions
  ////////////////////////////////////////////////////////////
  var _load = function() {
    var modal = $uibModal.open({
      templateUrl: "views/modal/progress.html",
      backdrop: "static",
      keyboard: false
    });

    News.pagedAll($routeParams.num, $location.search()).then(function(data){
      angular.forEach(data.content, function(v, i){
        var target = new Array();
        if ((v.newsTarget & 1) != 0) target.push('ALL');
        if ((v.newsTarget & 2) != 0) target.push('CONSOLE');
        if ((v.newsTarget & 4) != 0) target.push('MY PAGE');
        if ((v.newsTarget & 8) != 0) target.push('ORDER');
        if ((v.newsTarget & 16) != 0) target.push('MANAGE');
        v.newsTarget = target;
      });
      $scope.newsList = data.content;
      if ( data.totalElements == 0 ) {
        $scope.hideTable = true;
      } else {
        $scope.hideTable = false;
      }

      // Pagination
      $scope.totalItems = data.totalElements;
      $scope.currentPage = data.number + 1
      $scope.maxSize = 7;
      $scope.itemsPerPage = data.size;

      $scope.pageChanged = function() {
        $location.path('/news/pages/' + $scope.currentPage);
      };

      modal.close();
    });
  };

  //////////////////////////////////////////////////////////////////////
  // Loading order list with filter
  var _filterNewsList = function() {
    var params = "";
    if ($scope.searchFreeWord) {
      params += "searchWord=" + encodeURIComponent($scope.searchFreeWord);
    }
    if ($scope.limit) {
      if (params.length > 0) {
        params += '&';
      }
      params += "limit=" + $scope.limit.value;
    }

    // Back to 1st page
    $location.path('/news/pages/1');
    $location.search(params);

    _load();
  }

  //////////////////////////////////////////////////////////////////////
  // Clear list filters
  var _resetFilter = function() {
    $scope.searchFreeWord = '';
    $scope.statusFilter = [];
    $location.url('/news/pages/1');
  }

  //////////////////////////////////////////////////////////////////////
  // Change limit 
  var _changeLimit = function() {
    _filterNewsList();
  };

  //////////////////////////////////////////////////////////////////////
  // Toggle search panel visibility
  var _togglePanel = function() {
    $scope.panel.visible = !$scope.panel.visible
  }

  ////////////////////////////////////////////////////////////
  // Function Mapping
  ////////////////////////////////////////////////////////////
  $scope.filterNewsList = _filterNewsList;
  $scope.resetFilter = _resetFilter;
  $scope.changeLimit = _changeLimit;
  $scope.togglePanel = _togglePanel;

  ////////////////////////////////////////////////////////////
  // Initialize
  ////////////////////////////////////////////////////////////
  $scope.messages = {
    status: '',
    message: '',
  };

  $scope.limits = [{
    label: '10件',
    value: 10
  },
  {
    label: '20件',
    value: 20
  },
  {
    label: '50件',
    value: 50
  },
  {
    label: '100件',
    value: 100
  }];

  $scope.panel = {
    visible: false
  };

  $scope.hideTable = true;
  $scope.searchFreeWord = '';
  $scope.limit = $scope.limits[0];

  // Parse QueryString
  if (!angular.isUndefined($location.search()['searchWord'])) {
    $scope.searchFreeWord = decodeURIComponent($location.search()['searchWord'])
  }
  if (!angular.isUndefined($location.search()['limit'])) {
    var limit = parseInt(decodeURIComponent($location.search()['limit']));
    var res = $filter('filter')($scope.limits, {
      value: limit
    }, true);
    if (res.length > 0)
      $scope.limit = res[0];
  }

  // Load initial list
  _load();

};

module.exports = newsListController;