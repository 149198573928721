'use strict';

billingController.$inject = [
  '$scope',
  '$uibModal',
  'Vendors',
];

function billingController($scope, $uibModal, Vendors) {

  //////////////////////////////////////////////////////////////////////
  // Functions
  //////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////////
  // Open date picker 
  var _openBillingMonthPicker = function() {
    $scope.billingMonthPicker.opened =  true;
  }

  //////////////////////////////////////////////////////////////////////
  // Open date picker 
  var _showBillData  = function() {
    $scope.showBill = false;

    var modal = $uibModal.open({
      templateUrl: "views/modal/progress.html",
      backdrop: "static",
      keyboard: false
    });

    var year = $scope.billingMonth.getFullYear();
    var month = ('0' + ($scope.billingMonth.getMonth() + 1)).slice(-2);
    var date = year + month;

    Vendors.getBillingData($scope.billingVendor, date).then(function(data){
      modal.close();
      if (data.message == undefined) {
        $scope.billingData = data;
        $scope.showBill = true;
      } else {
        $scope.messages.status = 'danger';
        $scope.messages.message = data.message;
      }
      modal.close();
    });

  };

  //////////////////////////////////////////////////////////////////////
  // Bind functions
  //////////////////////////////////////////////////////////////////////
  $scope.openBillingMonthPicker = _openBillingMonthPicker;
  $scope.showBillData = _showBillData;

  //////////////////////////////////////////////////////////////////////
  // Methods
  //////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////////
  // Loading vendor
  var _loadVendor = function() {
    var modal = $uibModal.open({
      templateUrl: "views/modal/progress.html",
      backdrop: "static",
      keyboard: false
    });

    Vendors.listAllPrivate().then(function(data) {
      modal.close();
      if (data.message == undefined) {
        $scope.vendors = data;
      } else {
        $scope.messages.status = 'danger';
        $scope.messages.message = result.message;
      }
    });
  };

  //////////////////////////////////////////////////////////////////////
  // Initialization
  //////////////////////////////////////////////////////////////////////
  $scope.messages = {
    status: '',
    message: '',
  };

  $scope.billingMonthPicker = {
    opened: false
  }

  $scope.showBill = false;

  var initialDate = new Date();
  initialDate.setMonth( initialDate.getMonth() + 1);
  $scope.billingMonth = initialDate;

  $scope.dateOptions = {
    maxMode: 'month',
    minMode: 'month',
    datepickerMode: 'month'
  }

  _loadVendor();
};

module.exports = billingController;