'use strict';

authController.$inject = [
  '$scope',
  '$location',
  '$window',
  '$localStorage',
  '$routeParams',
  'AuthService',
  'UserService'
];

function authController($scope, $location, $window, $localStorage, $routeParams, AuthService, UserService) {
  $scope.message = '';
  $localStorage.$reset();

  if ( $location.path() === '/reset_password') {
    $scope.newPassword = '';
    $scope.retypePassword = '';
  
    $scope.token = $routeParams.token
  
    $scope.canChange = true;
    if ( !$scope.token ) {
      $scope.canChange = false;
      $scope.message = '無効なリクエストです。'
    }
  
    var _resetPassword = function() {
      if ( $scope.newPassword != $scope.retypePassword ) {
        $scope.message = '入力されたパスワードが違います。'
      }
      else {
        UserService.resetPassword($scope.token, $scope.newPassword)
        .then( function(res){
          if (res.message) {
            $scope.message = res.message;
          }
          else {
            $location.search({});
            $location.path('/password_reset_done');
          }
        });
      }
    };
  
    $scope.resetPassword = _resetPassword;
  }
  else {
    var _signIn = function(){
      $scope.message = '';

      AuthService.signIn($scope.username, $scope.password)
        .then( function onSuccess(res){
          var token = res.data.token;
          var base64Url = token.split('.')[1];
          var base64 = base64Url.replace('-', '+').replace('_', '/');
          var parsed = JSON.parse($window.atob(base64));

          var roles = parsed.scopes;
          if ( !roles || ( roles.indexOf('ROLE_ADMIN') == -1 && roles.indexOf('ROLE_SIXAPART') == -1 ) ) {
            _moveTop();
            return;
          }

          $localStorage.$default({
            username: $scope.username,
            password: $scope.password,
            token: token,
            tokenParsed: parsed,
            isAuthed: true
          });

          UserService.getUser('me')
            .then( function onSuccess(res){
              $localStorage.$default({
                user: res.data
              });
              $location.path('/dashboard');
            }, function onError(res) {
              $scope.message = "ユーザー情報の取得に失敗しました。";
            });
        }, function onError(res){
          $scope.message = "認証に失敗しました。";
        });
    };

    var _forgotPassword = function() {
      $location.path('/forgot');
    };

    var _recover = function() {
      UserService.passwordResetReuqest($scope.email)
      .then( function onSuccess(res){
        $location.path('/forgot_request_done');
      }, function onError(res) {
        $location.path('/forgot_request_done');
      });
    };

    var _moveTop = function() {
      $location.path('/signin');
    };

    $scope.signIn = _signIn;
    $scope.forgotPassword = _forgotPassword;
    $scope.recover = _recover;
    $scope.moveTop = _moveTop;
  }
}

module.exports = authController;
